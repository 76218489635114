import enMessages from './locales/en_US';
import frMessages from './locales/fr_FR';
import nlMessages from './locales/nl_NL';

interface LocaleConfig {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

const appLocales: LocaleConfig = {
    en: {
        messages: enMessages,
        locale: 'en-US' 
    },
    fr: {
        messages: frMessages,
        locale: 'fr-FR' 
    },
    nl: {
        messages: nlMessages,
        locale: 'nl-BE'
    }
};

export default appLocales;