import React, { useState, useEffect, useContext }   from 'react';
import                                                   "./YourCampaign.css";
import config                                       from '../../config';
import { AuthContext }                              from '../../contexts/authContext'
import axios                                        from 'axios';
import CommonUtilities                              from "../../util/CommonUtilities";
import { useIsMount }                               from "../../util/useIsMount";
import moment                                       from "moment";
import DateFnsUtils                                 from '@date-io/date-fns';
import frLocale                                     from "date-fns/locale/fr";
import enLocale                                     from "date-fns/locale/en-GB";
import nlLocale                                     from "date-fns/locale/nl";
import { FormattedMessage, injectIntl }             from 'react-intl';
import NavigateNextIcon                             from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon                           from '@mui/icons-material/NavigateBefore';
import                                                   "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel }                                 from 'react-responsive-carousel';
import { useHistory }                               from 'react-router-dom'
import img2                                         from "../../assets/panel.png";
import LegalLinks                                   from '../../components/LegalLinks';
import CircleProgress                               from '../../util/CircleProgress';
import IconButton                                   from '@mui/material/IconButton';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
}                                                   from '@material-ui/pickers';
import { MuiThemeProvider, createMuiTheme}          from "@material-ui/core/styles";
import { Language }                                 from '../../App';
import { NotificationManager }                      from 'react-notifications';
import DialogTitle                                  from '@mui/material/DialogTitle';
import DialogContent                                from '@mui/material/DialogContent';
import DialogActions                                from '@mui/material/DialogActions';
import Dialog                                       from '@mui/material/Dialog';
import WarningAmberIcon                             from '@mui/icons-material/WarningAmber';
import CloseIcon                                    from '@mui/icons-material/Close';
import FullscreenIcon                               from '@mui/icons-material/Fullscreen';
import { ZoomIn32, ZoomOut32, Subtract16, Time16, Calendar16, Link16}   from '@carbon/icons-react';

import { ReactComponent as ViewedPanelSvg } from '../../assets/viewedPanel.svg';
import { ReactComponent as EmptyBasket } from '../../assets/emptyBasket.svg';
//immagini-status dei pannelli
import ChosenPanel          from "../../assets/chosenPanel.png";
import ChosenPanelHover     from "../../assets/chosenPanelHover.png";
import CurrentPanel         from "../../assets/currentPanel.png";
import CurrentPanelChosen   from "../../assets/currentPanelChosen.png";
import ViewedPanel          from "../../assets/viewedPanel.png";
import ViewedPanelHover     from "../../assets/viewedPanelHover.png";
import PanelHover           from "../../assets/panelHover.png";

function creaStoSlider(){
    const slider = document.getElementById('#container-image-toDrag');
    //console.log('slider', slider)
    try{
        
        let mouseDown = false;
        let startX, scrollLeft;

        let startDragging = function (e) {
          mouseDown = true;
          startX = e.pageX - slider.offsetLeft;
          scrollLeft = slider.scrollLeft;
        };
        let stopDragging = function (event) {
          mouseDown = false;
        };

        slider.addEventListener('mousemove', (e) => {
          e.preventDefault();
          if(!mouseDown) { return; }
          const x = e.pageX - slider.offsetLeft;
          const scroll = x - startX;
          slider.scrollLeft = scrollLeft - scroll;
        });

        // Add the event listeners
        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);
    }catch(error){
        console.error('errore dello slider', error)
    }
}

const themeDates = createMuiTheme({
    palette: {
        primary: {
            main: '#276f9c',
        },
    },
    typography: {
        useNextVariants: true,
        fontSize: 20,
        fontFamily: 'Montserrat, sans-serif',
    }    
});

const getStartDate = () => {
    let checkDay = moment();

    for(let i=0; i<3; i++){
        checkDay = checkDay.add(1, 'days');
        if(checkDay.format('ddd') === 'Sat'){
            checkDay = checkDay.add(2, 'days');
        }else if(checkDay.format('ddd') === 'Sun'){
            checkDay = checkDay.add(1, 'days');
        }
    }
    return checkDay;
}

const getEndDate = () => {
    let endDay = moment();
    
    endDay.add(3, 'months');
    //console.log('endDay', endDay)
    //endDay = moment("30/09/2022", "DD/MM/YYYY")
    return endDay;
}

const Map = (props) =>{
    const ref = React.useRef(null);
    const [map, setMap] = React.useState();
    const [markers, setMarkers] = useState([]);
    const [panelsSeen, setPanelsSeen] = useState([]);
    const [panelsChosen, setPanelsChosen] = useState([]);

    //Probabilmente per non far refreshare le icone così malamente si può trovare un modo per refresharne una singola...
    //però come gestire il reset dello stato della mappa?

    //props.currentPanelCarouselIndex
    //console.log('props.kLocation', props.kLocation)
    const translate = (label) =>  props.translate.formatMessage({id: label});
/*     const Marker = (options) => {
        const [marker, setMarker] = React.useState();

        useEffect(() => {
            if (!marker) {
              setMarker(new google.maps.Marker());
            }
 */
/*     useEffect(() => {
        //console.log(JSON.stringify(props))
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {
                zoom: props.zoom,
                center: props.coords,
                heigth:'100%'
            }));
        }
    }, [ref, map]); */

    useEffect(() => {
        //console.log('entro solo al cambio di props')
        //console.log(JSON.stringify(props.coords))
        setMap(new window.google.maps.Map(ref.current, {
            zoom: props.zoom,
            center: props.coords,
            heigth:'100%',
            streetViewControl: false,
        }));
    }, [props.coords]);

    useEffect(()=>{
        //console.log('props', props.panelsList)
        /* hard refresh dei markers  */
        for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }
        let arrayMarkers = [];
        let oggetto = '';
        //console.log('props.basket', props.basket)
        //console.log('lista di panelli', props.panelsList)
        //console.log('lista di panelli selezionati', props.markers)
        props.panelsList.forEach( (panel, index) => {
            //console.log(props.basket.includes(panelToFind => panelToFind===panel))
            oggetto=new window.google.maps.Marker({
                position: {lat: panel.LATITUDE, lng: panel.LONGITUDE},
                map: map,
                title: !panel.DIGITAL_ID ? 'N.A.' : panel.DIGITAL_ID.toString(),
                //leggermente strano: se l'indice del pannello selezionato corrisponde al valore di currentPanelCarouselIndex posso capire su quale pannello mi trovo in questo momento.
                icon: props.markers.map(selectedPanel => selectedPanel.frameId).indexOf(panel.FRAME_ID) === props.currentPanelCarouselIndex ? 'assets/currentPanel.png' : 'assets/panel.png',
                //icon: props.basket.find(panelToFind => panelToFind===panel) ? 'assets/chosenPanel.png' : 'assets/panel.png',
                opacity: props.markers.find( selectedPanel => {
                    if(selectedPanel.frameId === panel.FRAME_ID) return true
                    else return false
                } ) ? 1 : 0.2,
            })

            //let infoWindow = {};
            
            /* `<div><span style='font-weight: bold;'>${translate('campaign.summary.panel.digital')}:</span> ${panel.DIGITAL_ID}</div>
            <div><span style='font-weight: bold;'>${translate('campaign.summary.panel.address')}:</span> ${panel.ADDRESS}</div>
            <div><span style='font-weight: bold;'>${translate('campaign.summary.panel.dimensions')}:</span> ${panel.DIMENSIONS}</div>
            <div><span style='font-weight: bold;'>${translate('campaign.summary.panel.resolution')}:</span> ${panel.RESOLUTION}</div>
            <br/>
            ${panel.PHOTO_ENABLED === 'N' ? `<div><a href=#/${props.countryString}/panel-image/${panel.KFACE} target='_blank'><img id='img1' style='max-width:100px; max-height:100px' src=${config.CONTENT_DISTRIBUTION_URL_ENV}/panel-photos/${panel.KFACE} alt='panel-img'/></a></div>` : ''}` */
            //*************AL MOMENTO LA INFO BOX E' DISABILITATA IN FAVORE DEL CAROUSEL**************************

            //if(props.kLocation!=='33'){
            //    infoWindow = new window.google.maps.InfoWindow({
            //        content:   `<div class='infoWindow-map'>
            //                        <div>
            //                            <div><span class='black-txt' style='font-size: 16px'>${translate('campaign.summary.panel.digital')}: ${panel.DIGITAL_ID}</span></div>
            //                            <div><span class='grey-txt'>${translate('campaign.summary.panel.address')}: ${panel.ADDRESS}</span></div>
            //                            <br/>
            //                            <div style='margin-bottom: 5px'><img style='width:15px; height:15px' src='https://img.icons8.com/material-outlined/2x/resize-diagonal.png' />&nbsp;&nbsp;&nbsp;<span class='black-txt'>${translate('campaign.summary.panel.dimensions')}: ${panel.DIMENSIONS}</span></div>
            //                            <div><img style='width:15px; height:15px' src='https://img.icons8.com/material-outlined/2x/resolution.png' />&nbsp;&nbsp;&nbsp;<span class='black-txt'>${translate('campaign.summary.panel.resolution')}: ${panel.RESOLUTION}</span></div>
            //                            ${panel.PHOTO_ENABLED === 'Y' ? `<div><a href=#/panel-image/${panel.KFACE}.jpg target='_blank'><button class='button--primary-large btn-pad'>${translate('infoWindow.button')} ${panel.DIGITAL_ID}</button></a></div>` : ''}
            //                        </div>
            //                        <div>
            //                            ${panel.PHOTO_ENABLED === 'Y' ? `<div style='margin-left: 12px'><img style='width:150px; height:200px;object-fit: cover;' src=${config.CONTENT_DISTRIBUTION_URL_ENV}/panel-photos/${panel.KFACE}.jpg alt='panel-img'/></div>` : ''}
            //                        </div>
            //                    </div>`
            //    });
            //}else{
            //    //old
            //    /* <div><span style='font-weight: bold;'>${translate('your-campaigns.map.overlap')}</span></div>
            //       <br/>
            //       <div><span style='font-weight: bold;'>${translate('campaign.summary.panel.digital')}:</span> ${props.panelsList[0].DIGITAL_ID}</div>
            //       <div><span style='font-weight: bold;'>${translate('campaign.summary.panel.address')}:</span> ${props.panelsList[0].ADDRESS}</div>
            //       <div><span style='font-weight: bold;'>${translate('campaign.summary.panel.dimensions')}:</span> ${props.panelsList[0].DIMENSIONS}</div>
            //       <div><span style='font-weight: bold;'>${translate('campaign.summary.panel.resolution')}:</span> ${props.panelsList[0].RESOLUTION}</div>
            //       <br/>
            //       <div><span style='font-weight: bold;'>${translate('campaign.summary.panel.digital')}:</span> ${props.panelsList[1].DIGITAL_ID}</div>
            //       <div><span style='font-weight: bold;'>${translate('campaign.summary.panel.address')}:</span> ${props.panelsList[1].ADDRESS}</div>
            //       <div><span style='font-weight: bold;'>${translate('campaign.summary.panel.dimensions')}:</span> ${props.panelsList[1].DIMENSIONS}</div>
            //       <div><span style='font-weight: bold;'>${translate('campaign.summary.panel.resolution')}:</span> ${props.panelsList[1].RESOLUTION}</div> */
            //
            //
            //    //al momento nella stazione di Roeselare i due pannelli condividono la stessa posizione essendo su due lati differenti
            //    //di conseguenza il tooltip di entrambe i pannelli riporterà le informazioni di ambo i pannelli e di questa particolarità
            //    infoWindow = new window.google.maps.InfoWindow({
            //        content:   `<div class='infoWindow-map-roeselare'>
            //                        <div style='text-align:center'><span style='font-weight: bold'>${translate('your-campaigns.map.overlap')}</span></div>
            //                        <br/>
            //                        <div class='infoWindow-map'>
            //                            <div>
            //                                <div><span class='black-txt' style='font-size: 16px'>${translate('campaign.summary.panel.digital')}: ${props.panelsList[0].DIGITAL_ID}</span></div>
            //                                <div><span class='grey-txt'>${translate('campaign.summary.panel.address')}: ${props.panelsList[0].ADDRESS}</span></div>
            //                                <br/>
            //                                <div style='margin-bottom: 5px'><img style='width:15px; height:15px' src='https://img.icons8.com/material-outlined/2x/resize-diagonal.png' />&nbsp;&nbsp;&nbsp;<span class='black-txt'>${translate('campaign.summary.panel.dimensions')}: ${props.panelsList[0].DIMENSIONS}</span></div>
            //                                <div><img style='width:15px; height:15px' src='https://img.icons8.com/material-outlined/2x/resolution.png' />&nbsp;&nbsp;&nbsp;<span class='black-txt'>${translate('campaign.summary.panel.resolution')}: ${props.panelsList[0].RESOLUTION}</span></div>
            //                                ${props.panelsList[0].PHOTO_ENABLED === 'Y' ? `<div><a href=#/panel-image/${props.panelsList[0].KFACE}.jpg target='_blank'><button class='button--primary-large btn-pad'>${translate('infoWindow.button')} ${props.panelsList[0].DIGITAL_ID}</button></a></div>` : ''}
            //                            </div>
            //                            <div>
            //                                ${props.panelsList[0].PHOTO_ENABLED === 'Y' ? `<div style='margin-left: 12px'><img style='width:150px; height:200px;object-fit: cover;' src=${config.CONTENT_DISTRIBUTION_URL_ENV}/panel-photos/${props.panelsList[0].KFACE}.jpg alt='panel-img'/></div>` : ''}
            //                            </div>
            //                        </div>
            //                        <br/>
            //                        <div class='infoWindow-map'>
            //                            <div>
            //                                <div><span class='black-txt' style='font-size: 16px'>${translate('campaign.summary.panel.digital')}: ${props.panelsList[1].DIGITAL_ID}</span></div>
            //                                <div><span class='grey-txt'>${translate('campaign.summary.panel.address')}: ${props.panelsList[1].ADDRESS}</span></div>
            //                                <br/>
            //                                <div style='margin-bottom: 5px'><img style='width:15px; height:15px' src='https://img.icons8.com/material-outlined/2x/resize-diagonal.png' />&nbsp;&nbsp;&nbsp;<span class='black-txt'>${translate('campaign.summary.panel.dimensions')}: ${props.panelsList[1].DIMENSIONS}</span></div>
            //                                <div><img style='width:15px; height:15px' src='https://img.icons8.com/material-outlined/2x/resolution.png' />&nbsp;&nbsp;&nbsp;<span class='black-txt'>${translate('campaign.summary.panel.resolution')}: ${props.panelsList[1].RESOLUTION}</span></div>
            //                                ${props.panelsList[1].PHOTO_ENABLED === 'Y' ? `<div><a href=#/panel-image/${props.panelsList[1].KFACE}.jpg target='_blank'><button class='button--primary-large btn-pad'>${translate('infoWindow.button')} ${props.panelsList[1].DIGITAL_ID}</button></a></div>` : ''}
            //                            </div>
            //                            <div>
            //                                ${props.panelsList[1].PHOTO_ENABLED === 'Y' ? `<div style='margin-left: 12px'><img style='width:150px; height:200px;object-fit: cover;' src=${config.CONTENT_DISTRIBUTION_URL_ENV}/panel-photos/${props.panelsList[1].KFACE}.jpg alt='panel-img'/></div>` : ''}
            //                            </div>
            //                        </div>
            //                    </div>`
            //    });
            //}

            /*dopo aver creato il marker si fa un controllo per assegnare l'immagine corretta*/
            if(props.basket.some(item => item.frameId === panel.FRAME_ID)){
                //per controllare se l'indice del focus corrisponde con l'index del pannello nell'arrayList
                if(props.markers.map(selectedPanel => selectedPanel.frameId).indexOf(panel.FRAME_ID) === props.currentPanelCarouselIndex){
                    oggetto.setIcon('assets/currentPanelChosen.png');
                }else{
                    oggetto.setIcon('assets/chosenPanel.png');
                }
            }else if(panelsSeen.some(pannelloVisto => pannelloVisto === panel.DIGITAL_ID.toString()) && props.markers.map(selectedPanel => selectedPanel.frameId).indexOf(panel.FRAME_ID) !== props.currentPanelCarouselIndex){
                oggetto.setIcon('assets/viewedPanel.png');
            }
            
            if(props.markers.map(selectedPanel => selectedPanel.frameId).indexOf(panel.FRAME_ID) === props.currentPanelCarouselIndex){
                let seenPanels = panelsSeen;
                if(!seenPanels.includes(panel.DIGITAL_ID)){
                    seenPanels.push(panel.DIGITAL_ID)
                    setPanelsSeen([...seenPanels])
                }
            }
            
            arrayMarkers.push(oggetto);

            arrayMarkers[index].addListener( 'mouseover', function() {
                let iconName = arrayMarkers[index].icon;
                if(iconName.includes('current')){
                    return;
                }
                if(!iconName.includes('Hover')){
                    let dotIndex = iconName.indexOf('.');
                    iconName = arrayMarkers[index].icon.substring(0, dotIndex);
                    iconName = iconName + 'Hover.png';
                    arrayMarkers[index].setIcon(iconName)
                }
            });

            arrayMarkers[index].addListener('mouseout', function() {
                let iconName = arrayMarkers[index].icon;
                iconName = iconName.replace('Hover', '');
                arrayMarkers[index].setIcon(iconName)
            });

            /* arrayMarkers[index].addListener('click', () => {
                infoWindow.open({
                    anchor: arrayMarkers[index],
                    map: map,
                    shouldFocus: false,
                });
            }); */

            //click per posizionare l'indice del carousel sul pannello scelto
            arrayMarkers[index].addListener('click', () => {
                props.openCarouselFromClick();
                
                return props.markers.find( selectedPanel => {
                    if(selectedPanel.frameId === panel.FRAME_ID){
                        if(props.markers.map(selectedPanel => selectedPanel.frameId).indexOf(panel.FRAME_ID) === props.currentPanelCarouselIndex){
                            props.addPanelToBasket(selectedPanel);
                        }
                        return props.panelClickFunction(props.markers.map(selectedPanel => selectedPanel.frameId).indexOf(panel.FRAME_ID));
                    }else{
                        return null
                    }
                } 
                )}
            );
        })
        setMarkers(arrayMarkers);
        //console.log('lista di panelli', props.markers)
        //creazione markers dei pannelli sulla mappa.
        /* if(props.markers.length !==0){
            props.markers.forEach( marker => {
                new window.google.maps.Marker({
                    position: {lat: marker.latitude, lng: marker.longitude},
                    map: map,
                    title: marker.frameId.toString(),
                    icon: 'assets/Malls_Screen_Icon@2x.png',
                    opacity: 0.3
                })
            })
        } */
        
        /* new window.google.maps.Circle({
            strokeColor: "#006AE3",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#006AE3",
            fillOpacity: 0.15,
            map: map,
            center: props.coords,
            radius: props.radius,
          }); */
    },[props.markers, props.currentPanelCarouselIndex, props.basket]);

    /* useEffect(() => {
        console.log(panelsSeen);
    },[panelsSeen]) */

    //
    /* eliminabile 
    useEffect(() => {
        let markerIndex = markers.findIndex((marker) => 
            props.basket.frameId===marker.frameId
        )
        console.log('markerIndex', markerIndex)
    },[props.basket]) */

    useEffect(() => {
        //console.log('markers', markers);
        markers.forEach( marker => {
            //console.log('marker', marker)
            marker.setMap(map);
        })
    },[markers]);

        //esempio per un singolo marker
        /* new window.google.maps.Marker({
            position: {lat:50.627109999999995, lng: 3.7729000000000004},
            map: map,
            title: "Hello World!",
            icon: 'assets/Malls_Screen_Icon@2x.png'
          }); */
          
    return (
        <div className='map-size' ref={ref}></div>
    )
}

const YourCampaign = (props) =>{
    //console.log('custom', localStorage.getItem('customCreativeId'))
    const {intl} = props;

    const translate = (label) =>  intl.formatMessage({id: label});

    const defaultCampaignName = `${translate('your-campaigns.newCampaign')} ${moment(new Date()).format('DD/MM/YYYY').toString()}`;

    const isMount = useIsMount();
    const [loading, setLoading] = useState(true);
    const [loadingCalculation, setLoadingCalculation] = useState(true);
    const [framesTotal, setFramesTotal] = useState(0);

    const [country, setCountry] = useState(localStorage.getItem('country'));
    const [listaKLocation, setListaKLocation] = useState(localStorage.getItem('listaKLocation'));
    const [zoom, setZoom] = useState(localStorage.getItem('country')==='1' ? 16 : 17);
    const [locationCoords, setLocationCoords] = useState({});
    const [selectedPanels, setSelectedPanels] = useState([]);
    const [panels, setPanels] = useState([]);
    
    //stati per il carosello
    const [showCarousel, setShowCarousel] = useState(true); //sembra più sensato farlo partire da aperto
    const [positionCurrentCarouselPanel, setPositionCurrentCarouselPanel] = useState(0);
    const [currentCarouselImageToModal, setCurrentCarouselImageToModal] = useState('');

    const [framesGroupFull, setFramesGroupFull] = useState(0);
    const [framesGroupHalf, setFramesGroupHalf] = useState(0);
    const [minDate, setMinDate] = useState(getStartDate);
    const [maxDate, setMaxDate] = useState(getEndDate);
    const [loadCampaignInfo, setLoadCampaignInfo] = useState(null);
    const [oldCampaigns, setOldCampaigns] = useState([]);
    const [errorCampaignName, setErrorCampaignName] = useState(0);
    const [errorDate1, setErrorDate1] = useState(false);
    const [errorDate2, setErrorDate2] = useState(false);
    const [carouselImageIndex, setCarouselImageIndex] = useState(0);
    const [stationPhoto, setStationPhoto] = useState('');

    //state params:
    //vengono inizializzati dal localStorage se !== null (in quanto si potrebbe arrivare da altre pagine con dei parametri) o con un valore di default
    const [kCampaign, setKCampaign] = useState(localStorage.getItem('kCampaign') !== null ? localStorage.getItem('kCampaign') : undefined);
    const [campaignName, setCampaignName] = useState(localStorage.getItem('campaignName') !== null ? localStorage.getItem('campaignName') : defaultCampaignName);
    const [floor, setFloor] = useState(localStorage.getItem('floor') !== null ? CommonUtilities.stringToInt(localStorage.getItem('floor')) : 0);
    const [giant, setGiant] = useState(localStorage.getItem('giant') !== null ? CommonUtilities.stringToBoolean(localStorage.getItem('giant')) : true);
    const [allFrames, setAllFrames] = useState(localStorage.getItem('allFrames') !== null ? CommonUtilities.stringToBoolean(localStorage.getItem('allFrames')) : true);
    const [daypart, setDaypart] = useState(localStorage.getItem('daypart') !== null ? parseInt(localStorage.getItem('daypart')) : 0);
    const [beginDate, setBeginDate] = useState(localStorage.getItem('beginDate') !== null ? moment(localStorage.getItem('beginDate')) : getStartDate);
    const [endDate, setEndDate] = useState(localStorage.getItem('endDate') !== null ? moment(localStorage.getItem('endDate')) : getStartDate);
    const [gloohCreativeId, setGloohCreativeId] = useState(localStorage.getItem('gloohCreativeId') !== null ? localStorage.getItem('gloohCreativeId') : '');
    const [gloohBriefId, setGloohBriefId] = useState(localStorage.getItem('gloohBriefId') !== null ? localStorage.getItem('gloohBriefId') : '');
    const [gloohRendered, setGloohRendered] = useState(localStorage.getItem('gloohRendered') !== null ? CommonUtilities.stringToBoolean(localStorage.getItem('gloohRendered')) : false);
    const [priceMedia, setPriceMedia] = useState(localStorage.getItem('priceMedia') !== null ? parseFloat(localStorage.getItem('priceMedia')) : 0);
    const [priceAdv, setPriceAdv] = useState(localStorage.getItem('priceAdv') !== null ? parseFloat(localStorage.getItem('priceAdv')) : 0);
    const [priceTva, setPriceTva] = useState(localStorage.getItem('priceTva') !== null ? parseFloat(localStorage.getItem('priceTva')) : 0);
    const [priceTtc, setPriceTtc] = useState(localStorage.getItem('priceTtc') !== null ? parseFloat(localStorage.getItem('priceTtc')) : 0);
    const [price, setPrice] = useState(localStorage.getItem('price') !== null ? parseFloat(localStorage.getItem('price')) : 0);
    const [framesSelected, setFramesSelected] = useState(localStorage.getItem('framesSelected') !== null ? parseInt(localStorage.getItem('framesSelected')) : 1);
    const [days, setDays] = useState(localStorage.getItem('days') !== null ? parseInt(localStorage.getItem('days')) : 1);
    const [slotFree, setSlotFree] = useState(localStorage.getItem('slotFree') !== null ? CommonUtilities.stringToBoolean(localStorage.getItem('slotFree')) : false);
    const [slotSelected, setSlotSelected] = useState(localStorage.getItem('slotSelected') !== null ? parseInt(localStorage.getItem('slotSelected')) : 0);
    const [numberOfSpot, setNumberOfSpot] = useState(localStorage.getItem('numberOfSpot') !== null ? parseInt(localStorage.getItem('numberOfSpot')) : 0);
    const [estimatedAvgContacts, setEstimatedAvgContacts] = useState(localStorage.getItem('estimatedAvgContacts') !== null ? parseInt(localStorage.getItem('estimatedAvgContacts')) : 0);
    
    const [showAudience, setShowAudience] = useState(false)
    const [daypartList, setDaypartList] = useState([]);
    const [framesLevels, setFramesLevels] = useState([]);

    const [customCreativeId, setCustomCreativeId] = useState(localStorage.getItem('customCreativeId') !== null ? localStorage.getItem('customCreativeId') : '');

    //campaignRejected se valorizzato ad 'Y' allora si procede al reset forzato della creatività
    const [campaignRejected, setCampaignRejected] = useState(localStorage.getItem('campaignRejected') !== null ? localStorage.getItem('campaignRejected') : 'N');
    const [resetCreativityDialog, setResetCreativityDialog] = useState(false);
    //stati per il cherry picking
    const [basket, setBasket] = useState(localStorage.getItem('panelsSelected') !== null ? JSON.parse(localStorage.getItem('panelsSelected')) : []);
    const [minCost, setMinCost] = useState(0);

    const [openMap, setOpenMap] = useState(true);
    
    const {language, setLanguage} = useContext(Language);

    const history = useHistory();
    const auth = useContext(AuthContext);
    //stato per controllare l'apertura dei dialogs
    const [confirmBackDialog, setConfirmBackDialog] = useState(false);
    const [packageImageDialog, setPackageImageDialog] = useState(false);

    const [zoomPackageImage, setZoomPackageImage] = useState(1);

    useEffect(() => {
        async function init(){
            localStorage.removeItem( 'packageName' );
            localStorage.removeItem( 'floorName' );
            localStorage.removeItem( 'panelsSelected');
            localStorage.removeItem( 'userBasket' );
            localStorage.removeItem('panelsList');
            if(beginDate._isValid === false && endDate._isValid===false){
                setBeginDate(getStartDate);
                setEndDate(getStartDate);
            }else if(beginDate._isValid === false){
                setBeginDate(getStartDate);
                setEndDate(getStartDate);
            }else if(endDate._isValid===false){
                setEndDate(beginDate);
            }
            if(props.match.params.id !== 'new'){
                setKCampaign(props.match.params.id);
                await getCampaign(props.match.params.id);  
            }else{
                setKCampaign('new');
                if(country !== '1'){
                    await getPanelList();
                }else{
                    await getCampaignInfo();
                }
            }

            let url = `${config.API_URL}/dayparts`;

            let params = {
                kCountry: country,
            }

            await axios.get(
                url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    },params  
                }
            ).then((response) => {
                setDaypartList(response.data);
            }, (error) => {
                if (!error.response){
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 0);
                    history.push('/connection-error');
                    return;
                }else if(error.response.status === 401){ //status 401: Unauthorized
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                    auth.signOut();
                    history.push('/signin');
                }else if(error.response.status ===500){
                    history.push('/connection-error');
                }else{
                    history.push('/connection-error');
                }
            });

            //get campaign list
            url = `${config.API_URL}/campaigns`;

            await axios.get(
                url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    }   
                }
            ).then((response) => {
                if(props.match.params.id !== 'new'){
                    const myOldCampaigns = response.data.filter(item => parseInt(item.KCAMPAIGN) !== parseInt(props.match.params.id));
                    setOldCampaigns(myOldCampaigns);
                }else{
                    setOldCampaigns(response.data);
                }
            }, (error) => {
                if (!error.response){
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 0);
                    history.push('/connection-error');
                    return;
                }else if(error.response.status === 401){ //status 401: Unauthorized
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                    auth.signOut();
                    history.push('/signin');
                }else if(error.response.status === 500){
                    history.push('/connection-error');
                }else{
                    history.push('/connection-error');
                }
            });

            url = `${config.API_URL}/locations`

            params = {
                kCountry: country,
                kLocation: listaKLocation
            }

            let response = '';

            try {
                response = await axios.get(
                    url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                        },
                        params  
                    }
                )
                if(response.status === 200){
                    setLocationCoords(
                        {
                            lat: response.data[0].LOCATION_LATITUDE,
                            lng: response.data[0].LOCATION_LONGITUDE
                        }
                    );
                    
                }else if(response.status === 201){
                    NotificationManager.warning(<FormattedMessage id={'notificationManager.stationDisabledError.text'}/>, <FormattedMessage id={'notificationManager.stationDisabledError.title'}/>, 10000);
                    history.push('/my-campaigns');
                    return;
                }else{
                    history.push('/connection-error');
                    return;
                }

                url = `${config.API_URL}/panels`;
                
                params = {
                    kLocation: listaKLocation
                };

                response = await axios.get(
                    url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                        },
                        params  
                    }
                )
                if(response.status === 200){
                    setPanels(response.data);
                }else if(response.status === 201){
                    NotificationManager.warning(<FormattedMessage id={'notificationManager.stationDisabledError.text'}/>, <FormattedMessage id={'notificationManager.stationDisabledError.title'}/>, 10000);
                    history.push('/my-campaigns');
                    return;
                }else{
                history.push('/connection-error');
                return;
                }

            } catch (error) {
                if (!error.response){
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 0);
                    history.push('/connection-error');
                    return;
                }else if(error.response.status === 401){ //status 401: Unauthorized
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                    auth.signOut();
                    history.push('/signin');
                    return;
                }else if(error.response.status ===500){//nelle chiamate in cui bisogna recuperare informazioni critiche per il corretto caricamento della pagina, in caso di errore, l'utente viene indirizzato alla pagina 'connection-error'
                    history.push('/connection-error');
                    return;
                }else{
                    history.push('/connection-error');
                    return;
                }
            }
            setLoading(false);
            if(country==='1'){
                setShowCarousel(false);
            }
        }
        init();
    }, []);

    useEffect(() => {
        if(oldCampaigns.find(item => item.DCAMPAIGN.trim().toUpperCase() === campaignName.trim().toUpperCase()) !== undefined){
            setErrorCampaignName(2);
        }else if (campaignName===''){
            setErrorCampaignName(1);
        }else{
            setErrorCampaignName(0);
        }
    }, [oldCampaigns, campaignName]);

    useEffect(() => {
        if(!isMount) {
            if (country!=='1'){
                getPanelList();
            }else{
                getCampaignInfo();
            }
        }
    }, [loadCampaignInfo]);

    useEffect(() => {
        if(beginDate !== undefined && endDate !== undefined){
            if(beginDate.startOf('day').isAfter(endDate.startOf('day'))){
                setEndDate(beginDate.clone());
            }

            const duration = moment.duration(endDate.diff(beginDate));
            const days = parseInt(duration.asDays()) + 1;
            setDays(days);
            //setLoadCampaignInfo(!loadCampaignInfo)
        }
        
    }, [beginDate, endDate]);

    //useEffect parameters
    //gli stati che servono alle altre pagine sono salvati nel localStorage
    useEffect(() => {
        localStorage.setItem('kCampaign', kCampaign);
    }, [kCampaign]);
    useEffect(() => {
        localStorage.setItem('campaignName', campaignName);
    }, [campaignName]);
    useEffect(() => {
        localStorage.setItem('floor', floor !== null ? floor : '');
    }, [floor]);
    useEffect(() => {
        localStorage.setItem('giant', giant);
    }, [giant]);    
    useEffect(() => {
        localStorage.setItem('allFrames', allFrames);
    }, [allFrames]);
    useEffect(() => {
        localStorage.setItem('daypart', daypart);
    }, [daypart]);
    useEffect(() => {
        localStorage.setItem('beginDate', beginDate);
    }, [beginDate]);
    useEffect(() => {
        localStorage.setItem('endDate', endDate);
    }, [endDate]);
    useEffect(() => {
        localStorage.setItem('gloohCreativeId', gloohCreativeId);
    }, [gloohCreativeId]);
    useEffect(() => {
        localStorage.setItem('gloohBriefId', gloohBriefId);
    }, [gloohBriefId]);
    useEffect(() => {
        localStorage.setItem('gloohRendered', gloohRendered);
    }, [gloohRendered]);    
    useEffect(() => {
        localStorage.setItem('priceMedia', priceMedia);
    }, [priceMedia]);
    useEffect(() => {
        localStorage.setItem('priceAdv', priceAdv);
    }, [priceAdv]);  
    useEffect(() => {
        localStorage.setItem('priceTva', priceTva);
    }, [priceTva]);  
    useEffect(() => {
        localStorage.setItem('priceTtc', priceTtc);
    }, [priceTtc]);             
    useEffect(() => {
        localStorage.setItem('price', price);
    }, [price]);
    useEffect(() => {
        localStorage.setItem('framesSelected', framesSelected);
    }, [framesSelected]);
    useEffect(() => {
        localStorage.setItem('days', days);
    }, [days]);
    useEffect(() => {
        localStorage.setItem('slotFree', slotFree);
    }, [slotFree]);
    useEffect(() => {
        localStorage.setItem('slotSelected', slotSelected);
    }, [slotSelected]);    
    useEffect(() => {
        localStorage.setItem('numberOfSpot', numberOfSpot);
    }, [numberOfSpot]);
    useEffect(() => {
        localStorage.setItem('estimatedAvgContacts', estimatedAvgContacts);
    }, [estimatedAvgContacts]);
    useEffect(() => {
        localStorage.setItem('estimatedAvgContacts', estimatedAvgContacts);
    }, [estimatedAvgContacts]);
    useEffect(() => {
        localStorage.setItem('customCreativeId', customCreativeId);
    }, [customCreativeId]);

    //end useEffect parameters

    useEffect(() => {
        //salto il primo giro per non fare due chiamate
        if(!loadingCalculation){
            getPanelList();
        }
        localStorage.setItem('panelsSelected', JSON.stringify(basket));
        localStorage.setItem('userBasket', basket.map(panel => panel.kFace).toString());
    },[basket])

    const getCampaign = async(pkCampaign) => { 
        setLoadingCalculation(true)
        //si carica una campagna già esistente 
        const params = {
            kCampaign: pkCampaign,
            savedData: 'N'
        };
    
        const url = `${config.API_URL}/campaign`;

        await axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },params   
            }
        ).then((response) => {
            let data = response.data;
            //console.log('edit data', data);
            let parseFramesLevels = JSON.parse(data.FRAMES_LEVELS);
            let chosenFrameLevel = parseFramesLevels.find(frameLevel => frameLevel.kLevel.toString() === data.FRAMES_FLOOR.toString());
            setCampaignName(data.DCAMPAIGN);
            setBeginDate(moment(data.BEGIN_DATE));
            setEndDate(moment(data.END_DATE));
            setFloor(parseInt(data.FRAMES_FLOOR));
            setGiant(data.FRAMES_GIANT === 'Y' ? true : false);
            setAllFrames(data.FRAMES_ALL === 'Y' ? true : false);
            setDaypart(!data.KDAYPART ? 0 : data.KDAYPART);
            setGloohCreativeId(data.GLOOH_CREATIVE_ID);
            setGloohBriefId(data.GLOOH_BRIEF_ID);
            setGloohRendered(data.GLOOH_RENDERED === 'Y' ? true : false);
            setCustomCreativeId(data.CUSTOM_CREATIVE_ID);
            setFramesTotal(data.FRAMES_TOTAL);
            setFramesGroupFull(data.FRAMES_GROUP_FULL);
            setFramesGroupHalf(data.FRAMES_GROUP_HALF);
            setFramesSelected(data.FRAMES_SELECTED);
            setPriceMedia(data.MEDIA_COST);
            setPriceAdv(data.ADV_COST);                
            setPriceTva(data.TVA_COST);
            setPriceTtc(data.TTC_COST);
            setPrice(data.TOTAL_COST);
            setSlotFree(data.SLOT_FREE === 'Y' ? true : false);
            setSlotSelected(data.SLOT_SELECTED);
            setNumberOfSpot(data.NUMBER_OF_SPOT);
            setEstimatedAvgContacts(data.ESTIMATED_AVG_CONTACTS);
            setShowAudience(data.SHOW_AUDIENCE === 'Y' ? true : false);
            setFramesLevels(parseFramesLevels);
            setListaKLocation(data.LISTA_KLOCATION);
            setStationPhoto(data.PHOTO_MAP);
            setSelectedPanels(JSON.parse(data.PANELS_SELECTED) || []);
            if (country !== '1'){
                let reloadBasket = JSON.parse(data.PANELS_SELECTED).filter(panel => data.PANELS_LIST.split(',').includes(panel.kFace+''));
                //console.log('parse', JSON.parse(data.PANELS_SELECTED))
                //console.log('filter', JSON.parse(data.PANELS_SELECTED).filter(panel => data.PANELS_LIST.split(',').includes(panel.kFace+'')))
                //console.log('reloadBasket', reloadBasket);
                setBasket(reloadBasket);
                setMinCost(data.MIN_COST);
            }
            localStorage.setItem( 'listaKLocation', data.LISTA_KLOCATION );
            localStorage.setItem( 'packageName', chosenFrameLevel.dLevel );
            localStorage.setItem( 'floorName', chosenFrameLevel.groupLabel );
            localStorage.setItem( 'panelsSelected', data.PANELS_SELECTED );
            localStorage.setItem( 'groupList', data.GROUP_LIST);
            localStorage.setItem( 'framesLevels', data.FRAMES_LEVELS);
            localStorage.setItem( 'panelsList', data.PANELS_LIST);
            localStorage.setItem('promoCodeDiscount', !data.PROMO_CODE_DISCOUNT ? '' : parseFloat(data.PROMO_CODE_DISCOUNT));
            localStorage.setItem('originalPrice', data.TOTAL_COST_SAVE);
        }, (error) => {
            if (!error.response){
                NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 0);
                history.push('/connection-error');
                return;
            }else if(error.response.status === 401){ //status 401: Unauthorized
                NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                auth.signOut();
                history.push('/signin');
            }else if(error.response.status ===500){
                history.push('/connection-error');
            }else{
                history.push('/connection-error');
            }
        });
        setLoadingCalculation(false);
    }

    const getCampaignInfo = async() => {
        setLoadingCalculation(true)
        //siccome la data fine cambia in modo asincrono settando data inizio maggiore di data fine aggiungo questo controllo
        //in questo momento la data fine potrebbe non essere ancora aggiornata
        //quindi se data inizio maggiore di data fine => data fine = data inizio (come in interfaccia)
        let checkEndDate = endDate.clone();
        if(beginDate.startOf('day').isAfter(checkEndDate.startOf('day'))){
            checkEndDate = beginDate.clone();
        }

        const params = {
            level: floor === null ? '' : floor,
            giant: country==='1' ? (giant === true ? 'Y' : 'N') : 'N',
            allFrames: allFrames === true ? 'Y' : 'N',
            beginDate: CommonUtilities.getStringFromDate(beginDate.toDate()),
            endDate: CommonUtilities.getStringFromDate(checkEndDate.toDate()),
            daypart: daypart===0 ? '' : daypart ,
            listaKLocation,
        };
        //console.log('paramsparams', params)
        const url = `${config.API_URL}/campaign-pkg`;

        await axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params,
            }
        ).then((response) => {
            const data = response.data;
            //console.log('data',data);
            //let parsePanelsSelected = JSON.parse(data.PANELS_SELECTED)
            //console.log('parsePanelsSelected', parsePanelsSelected);
            //console.log('frames levels', JSON.parse(data.FRAMES_LEVELS))
            let parseFramesLevels = JSON.parse(data.FRAMES_LEVELS);
            let chosenFrameLevel = parseFramesLevels.find(frameLevel => frameLevel.kLevel.toString() === floor.toString());
            setFramesLevels(parseFramesLevels);
            setFramesTotal(data.FRAMES_TOTAL);  
            setFramesGroupFull(data.FRAMES_GROUP_FULL);
            setFramesGroupHalf(data.FRAMES_GROUP_HALF);
            setFramesSelected(data.FRAMES_SELECTED); 
            setPriceMedia(data.MEDIA_COST);
            setPriceAdv(data.ADV_COST);  
            setPriceTva(data.TVA_COST);
            setPriceTtc(data.TTC_COST);                        
            setPrice(data.TOTAL_COST);
            setSlotFree(data.SLOT_FREE === 'Y' ? true : false);
            setSlotSelected(data.SLOT_SELECTED);
            setNumberOfSpot(data.NUMBER_OF_SPOT);
            setEstimatedAvgContacts(data.ESTIMATED_AVG_CONTACTS);
            setShowAudience(data.SHOW_AUDIENCE === 'Y' ? true : false);
            setStationPhoto(data.PHOTO_MAP);
            setSelectedPanels(JSON.parse(data.PANELS_SELECTED) || []);
            /* setStationPhoto(data.PHOTO_MAP ? `${config.CONTENT_DISTRIBUTION_URL}/${process.env.REACT_APP_ENV}/map-photos/${data.PHOTO_MAP}` : ''); */
            localStorage.setItem( 'packageName', chosenFrameLevel.dLevel );
            localStorage.setItem( 'floorName', chosenFrameLevel.groupLabel );
            localStorage.setItem( 'panelsSelected', data.PANELS_SELECTED );
            localStorage.setItem( 'groupList', data.GROUP_LIST);
            localStorage.setItem( 'framesLevels', data.FRAMES_LEVELS);
        }, (error) => {
            if (!error.response){
                NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 0);
                history.push('/connection-error');
                return;
            }else if(error.response.status === 401){ //status 401: Unauthorized
                NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                auth.signOut();
                history.push('/signin');
            }else if(error.response.status===500){
                //da capire come gestire un eventuale 500
                //history.push('/connection-error');
            }else{
                history.push('/connection-error');
            }
        });
        setLoadingCalculation(false);
    }

    const getPanelList = async() => {
        //chiamata per ottenere la lista di pannelli del cherry picking
        setLoadingCalculation(true);

        let checkEndDate = endDate.clone();
        if(beginDate.startOf('day').isAfter(checkEndDate.startOf('day'))){
            checkEndDate = beginDate.clone();
        }

        let userPanelList = basket.map(panel => panel.kFace).toString();
        //console.log('userPanelList', userPanelList)

        const params = {
            listaKLocation,
            listaKFace: userPanelList,
            beginDate: CommonUtilities.getStringFromDate(beginDate.toDate()),
            endDate: CommonUtilities.getStringFromDate(checkEndDate.toDate()),
            daypart: daypart===0 ? '' : daypart
        };
        //console.log('paramsparams', params)
        const url = `${config.API_URL}/campaign-pnl`;
        
        await axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params,
            }
        ).then((response) => {
            const data = response.data;
            //console.log('data',data);
            //console.log('panSel', JSON.parse(data.PANELS_LIST))
            //let parsePanelsSelected = JSON.parse(data.PANELS_SELECTED)
            //console.log('parsePanelsSelected', parsePanelsSelected);
            //console.log('frames levels', JSON.parse(data.FRAMES_LEVELS))
            ////let parseFramesLevels = JSON.parse(data.FRAMES_LEVELS);
            ////let chosenFrameLevel = parseFramesLevels.find(frameLevel => frameLevel.kLevel.toString() === floor.toString());
            ////setFramesLevels(parseFramesLevels);
            setFramesTotal(data.FRAMES_TOTAL);  
            ////setFramesGroupFull(data.FRAMES_GROUP_FULL);
            ////setFramesGroupHalf(data.FRAMES_GROUP_HALF);
            setFramesSelected(data.PANELS_LIST.split(',').length);
            ////setPriceMedia(data.MEDIA_COST);
            ////setPriceAdv(data.ADV_COST);  
            setPriceTva(data.TVA_COST);
            setPriceTtc(data.TTC_COST);                        
            setPrice(data.TOTAL_COST);
            setSlotFree(data.SLOT_FREE === 'Y' ? true : false);
            setSlotSelected(data.SLOT_SELECTED);
            setNumberOfSpot(data.NUMBER_OF_SPOT);
            setEstimatedAvgContacts(data.ESTIMATED_AVG_CONTACTS);
            setShowAudience(data.SHOW_AUDIENCE === 'Y' ? true : false);
            setMinCost(data.MIN_COST);
            ////setStationPhoto(data.PHOTO_MAP); 
            //console.log('selectedPanels',selectedPanels)
            if(!selectedPanels.length){
                //situazione particolare per non fare casini col cherry picking
                //si popola questo stato solo al caricamento della pagina ossia quando lo stato è ancora falsy
                setSelectedPanels(JSON.parse(data.PANELS_LIST) || []);
            }
            //forzato per visualizzare sempre mappa siccome gli altri valori perdono di senso
            setStationPhoto( "M" );
            ////localStorage.setItem( 'packageName', chosenFrameLevel.dLevel );
            ////localStorage.setItem( 'floorName', chosenFrameLevel.groupLabel );
            ////localStorage.setItem( 'panelsSelected', data.PANELS_SELECTED );
            ////localStorage.setItem( 'groupList', data.GROUP_LIST);
            ////localStorage.setItem( 'framesLevels', data.FRAMES_LEVELS);
        }, (error) => {
            console.log(error)
            if (!error.response){
                NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 0);
                history.push('/connection-error');
                return;
            }else if(error.response.status === 401){ //status 401: Unauthorized
                NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                auth.signOut();
                history.push('/signin');
            }else if(error.response.status===500){
                //da capire come gestire un eventuale 500
                //history.push('/connection-error');
            }else{
                //history.push('/connection-error');
            }
        });
        setLoadingCalculation(false);
    }
      
    const handleClickFloor = (floor, packageName, floorName) => {
        setCarouselImageIndex(floor !== null ? floor : 0);
        setFloor(floor);
        setLoadCampaignInfo(!loadCampaignInfo);
        //al click del pacchetto bisogna resettare l'indice per puntare
        //in teoria il carosello non deve uscire lato francia
        //setPositionCurrentCarouselPanel(0);
        //setShowCarousel(true);
    };

    const handleClickGiant = (giant) => {
        setGiant(giant);
        setLoadCampaignInfo(!loadCampaignInfo);
    };

    const handleClickAllFrames = (all) => {
        setAllFrames(all);
        setLoadCampaignInfo(!loadCampaignInfo);
    };

    const handleChangeBeginDate = (selectedDay) => {
        //questo controllo viene eseguito in questo modo poichè
        //la libreria di material resistuisce un oggetto InvalidDate
        //che per moment è comunque un date valido
        setBeginDate(moment(selectedDay));
        if(JSON.stringify(selectedDay)!=='null'){
            setLoadCampaignInfo(!loadCampaignInfo);
        };
    };

    const handleChangeEndDate = (selectedDay) => {
        setEndDate(moment(selectedDay));
        if(JSON.stringify(selectedDay)!=='null'){
            setLoadCampaignInfo(!loadCampaignInfo);
        };
    };

    const handleClickDaypart = (daypart) => {
        setDaypart(daypart);
        setLoadCampaignInfo(!loadCampaignInfo);
    };

    const handleChangeCampaignName = event => {
        const myCampaignName = event.target.value;
        setCampaignName(myCampaignName);

        if(myCampaignName === ''){
            setErrorCampaignName(1);
        }else if(oldCampaigns.find(item => item.DCAMPAIGN.toUpperCase().trim() === myCampaignName.toUpperCase().trim()) !== undefined){
            setErrorCampaignName(2);
        }else{
            setErrorCampaignName(0);
        }
    };

    const handleDateError1 = (error) => {
        if(error === "")
        {
            setErrorDate1(false);
        }else{
            setErrorDate1(true)
        }
    }

    const handleDateError2 = (error) => {
        if(error === "")
        {
            setErrorDate2(false);
        }else{
            setErrorDate2(true)
        }
    }

    const handleConfirmCloseBackDialog = () =>{
        setConfirmBackDialog(false);
    };

    const handleCloseResetCreativityDialog = () => {
        setResetCreativityDialog(false);
    }

    const handleClosePackageImageDialog = () =>{
        setPackageImageDialog(false);
        setCurrentCarouselImageToModal("");
        setZoomPackageImage(1);
    };

    const handleCloseCarousel = () => {
        setShowCarousel(false);
    }

    const handleResizePackageImage = (action) => {
        if(action === '+'){
            if(zoomPackageImage===4){
                return
            }else{
                setZoomPackageImage(zoomPackageImage+1);
            }
        }else{
            if(zoomPackageImage===1){
                return
            }else{
                setZoomPackageImage(zoomPackageImage-1);
            }
        }
    }

    const countriesArray = config.COUNTRIES_LABELS;
    let urlCountry = countriesArray[country-1];

    const gotoMyCampaigns = () => {
        /* history.push(`/my-campaigns`); */
        history.goBack();
    }

    const gotoLocationSelection = () => {
        history.push(`/${urlCountry}/location-selection`)
    }

    const gotoResetCreativity = () => {
        localStorage.setItem('customCreativeId', '');
        localStorage.setItem('gloohCreativeId', '');
        localStorage.setItem('gloohBriefId', '');
        gotoCampaignCreative();
    }

    const gotoCampaignCreative = () => {
        localStorage.setItem('showAudience', showAudience);
        //PER ORA VIENE SALTATO LO STEP GLOOH ... IN ATTESA DI GLOOH
        history.push(`/${urlCountry}/campaign-creative`);

        //DIRETTAMENTE ALLA SUMMARY
        //history.push(`/${urlCountry}/campaign-summary`);
    }

    const gotoCampaignSummary = () => {
        //il database non modifica i valori se vengono passati vuoti
        localStorage.setItem('customCreativeId', customCreativeId === 'null' ? '' : customCreativeId ? customCreativeId : '');
        localStorage.setItem('gloohCreativeId', '');
        localStorage.setItem('gloohBriefId', '');
        localStorage.setItem('gloohCreativeIdForPreview', !gloohCreativeId ? '' :gloohCreativeId);
        localStorage.setItem('gloohBriefIdForPreview', !gloohBriefId ? '' : gloohBriefId);
        history.push(`/${urlCountry}/campaign-summary/${kCampaign}`);
    }


    const proceedCheck = () => {
        if(kCampaign === 'new'){
            gotoCampaignCreative();
        }else if(campaignRejected==='Y'){
            //gotoResetCreativity();
            setResetCreativityDialog(true);
        }else{
            setResetCreativityDialog(true);
        }
    }

    const daypartButtons = () => {
        return( 
        <>
            {daypartList.map(fasciaOraria => { return(
            <div key={fasciaOraria.KDAYPART} className={"col-desk-6 col-mob-4 " + (country==='2'? "col-grid-4" : "")}>
                <div className={fasciaOraria.KDAYPART === daypart ? "card selected" : "card card--clickable"} onClick={() => handleClickDaypart(fasciaOraria.KDAYPART)}>
                    <div className="card__container">
                        <div className="card__container--content">
                            <Time16 />
                            <h4 className="h4"><FormattedMessage id={"your-campaigns."+fasciaOraria.DDAYPART}/></h4>
                        </div>
                    </div>
                </div>
            </div>)})}
        
            <div className={"col-desk-6 col-mob-4 " + (country==='2'? "col-grid-4" : "")}>
                <div className={daypart === 0 ? "card selected" : "card card--clickable"} onClick={() => handleClickDaypart(0)}>
                    <div className="card__container">
                        <div className="card__container--content">
                            <Calendar16 />
                            <h4 className="h4"><FormattedMessage id={"your-campaigns.daypart.full"}/></h4>
                        </div>
                    </div>        
                </div>
            </div>
        </>)
    }

    const perimeterButtons = () => {
        //vecchia visualizzazione dei pacchetti
        return(
            <>
                {framesLevels.length===2 ? 
                
                    <div className="col-desk-12 col-mob-4 firstPackage-pad-left perimeter-card">
                        <div className={floor === 0 ? "card selected" : "card card--clickable"} onClick={() => handleClickFloor(0, framesLevels[0].dLevel, framesLevels[0].groupLabel)}>
                            <div className="card__container no-margin">
                                <div className="card__container--content">
                                    {country==='2'&&framesLevels.length>1&&(<h4 className="extraMargin"></h4>)}
                                     <p className="paragraph"><FormattedMessage id={country==='1'?"your-campaigns.perimeter1":"your-campaigns.level.be.p-all"}/></p>
                                    <span className="tag secondary">{`${framesLevels[0].nScreens} ${translate("your-campaigns.perimeter.screens")} / ${days} ${days > 1 ? translate("your-campaigns.perimeter.days") : translate("your-campaigns.perimeter.day")}`}</span>
                                    <span className="block margin-top--03 bold"><FormattedMessage id={"your-campaigns.perimeter.label"}/> {CommonUtilities.formatNumber(framesLevels[0].minCost, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})} </span></div>
                            </div>
                        </div>
                    </div>
                :
                    framesLevels.map((pacchetto, index) => { return(
                    <div key={pacchetto.kLevel} className={`col-desk-12 col-mob-4 ${index===0 ? 'firstPackage-pad-left' : ''} perimeter-card`}>
                        <div className={floor === pacchetto.kLevel ? "card selected" : "card card--clickable"} onClick={() => handleClickFloor(pacchetto.kLevel, pacchetto.dLevel, pacchetto.groupLabel)}>
                            <div className="card__container no-margin">
                                <div className="card__container--content">
                                    {index > 0 ? <span className="caption"><FormattedMessage id={`your-campaigns.${pacchetto.dLevel}`}/></span> : (country==='2' ? <span className="caption">&nbsp;</span> : <span className="caption"><FormattedMessage id={`your-campaigns.${pacchetto.dLevel}`}/></span>)}
                                    {country==='2' ? (<p className="paragraph"><FormattedMessage id={`your-campaigns.${pacchetto.groupLabel}`}/></p>) : <>&nbsp;</>}
                                    <span className="tag secondary">{`${pacchetto.nScreens} ${translate("your-campaigns.perimeter.screens")} / ${days} ${days > 1 ? translate("your-campaigns.perimeter.days") : translate("your-campaigns.perimeter.day")}`}</span>
                                    <span className="block margin-top--03 bold"><FormattedMessage id={"your-campaigns.perimeter.label"}/> <span className="primary">{CommonUtilities.formatNumber( pacchetto.minCost, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                )})}
            </>
        )
    }

    const createBasketList = () => {
        return (basket.map((panel, index) => {
            let pnlsSync = []
            if(panel.pnlSync!==null){
                pnlsSync = panel.pnlSync.split(',');
            }
            let digitalIdsLabelslist = pnlsSync.map(faccia => ( selectedPanels.find(pannello => (pannello.kFace||'')+'' === faccia) || {}).digitalId).join(', ')
            return (
                <div className='basket-row pointer-curs' onClick={() => setPositionCurrentCarouselPanelFromRow(panel)} key={panel.frameId}>
                    <div>
                        <h4>{panel.digitalId}</h4>
                        {panel.pnlSync!==null && (
                            <div className='sync-list-detail' title={`${translate("your-campaigns.sync.start")} ${panel.digitalId} ${translate("your-campaigns.sync.mid")} ${digitalIdsLabelslist}`}>
                                <Link16/>&nbsp;{`${digitalIdsLabelslist}`}
                            </div>
                        )}
                        <div className='row-detail'>
                            <FormattedMessage id={`your-campaigns.${panel.groupLabel}`}/>
                            &nbsp;
                            <span style={{fontWeight: 'bold'}}>•</span>
                            &nbsp;
                            <FormattedMessage id={`my-campaigns.table.price`}/>
                            &nbsp;
                            <span className='blue-text'>{CommonUtilities.formatNumber( minCost, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}</span>
                        </div>
                    </div>
                    <div className="pointer-curs arrow-carousel no-margin fit-content" aria-label="delete" onClick={() => removeFromBasket(panel)}>
                        <Subtract16/>
                    </div>
                </div>        
            )
        }))
    }

    const basketColumn = () => {
        return(
            <div className='card__container basket-col no-border' style={{margin: '0px'}}>
                <div className='basket-padding'>
                    <h4 className='margin-bottom-00'>
                        <FormattedMessage id={"your-campaigns.basket.head"}/>
                    </h4>
                </div>
                <div className='basket-internal-border'>
                    {basket.length === 0 
                        ?
                        <div className='empty-basket-view basket-col'>
                            <EmptyBasket className='svg-dims'/>
                            <FormattedMessage id={"your-campaigns.basket.empty"}/>
                        </div>
                        :
                        <>{createBasketList()}</>
                    }
                </div>
                <div className='basket-padding basket-foot-info'>
                    <div className='flexed'>
                        {basket.length === 1 
                            ?
                                <>
                                    <h4>
                                        {basket.length}&nbsp;<FormattedMessage id={'your-campaigns.basket.foot.singlePanel.panel'}/>
                                    </h4>
                                    &nbsp;
                                    <FormattedMessage id={'your-campaigns.basket.foot.singlePanel.select'}/>
                                </>
                            : 
                                <>
                                    <h4>
                                        {basket.length}&nbsp;<FormattedMessage id={'your-campaigns.basket.foot.multiPanel.panel'}/>
                                    </h4>
                                    &nbsp;
                                    <FormattedMessage id={'your-campaigns.basket.foot.multiPanel.select'}/>
                                </>
                        }
                    </div>
                    <div className='highlight'>{CommonUtilities.formatNumber(price, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}</div>
                </div>
            </div>
        )
    }

    const addToBasket = (panel) => {
        //controllo per non aggiungere un panel già selezionato nel basket
        let duplicateCheck = false;
        basket.forEach(element => {
            if (element.frameId === panel.frameId){
                return duplicateCheck = true;
            }
        });
        if (duplicateCheck){
            return;
        }
        
        let newBasket = basket;
        //console.log('panel aggiunto nel basket',panel)
        newBasket.push(panel);
        //controllo se il pannello ha pannelli sincronizzati
        if (panel.pnlSync!==null){
            let syncList = panel.pnlSync.split(',');
            syncList.forEach(ele => {
                let kface = ele;
                let panelToAdd = selectedPanels.find(faccia => faccia.kFace.toString() === kface)
                newBasket.push(panelToAdd);
            })   
        }
        //console.log(newBasket)
        setBasket([...newBasket]);
    }

    const removeFromBasket = (panel) => {
        //tutti i pannelli che non corrispondono al pannello passato alla funzione vengono mantenuti
        let newBasket = basket;
        let panelsToRemove = [panel];
        //controllo se il pannello ha pannelli sincronizzati
        if (panel.pnlSync!==null){
            let syncList = panel.pnlSync.split(',');
            syncList.forEach(ele => {
                let kface = ele;
                let panelToRemove = selectedPanels.find(faccia => faccia.kFace.toString() === kface)
                panelsToRemove.push(panelToRemove);
            })   
        }
        //ciclo sui pannelli scelti per la rimozione e ciclo sul carrello per rimuoverli.
        //mantengo tutti i pannelli che hanno un kFace diverso da quello del pannello che sto passando per la rimozione.
        panelsToRemove.forEach(panel => 
            newBasket = [...newBasket.filter(panelToFind => panelToFind.kFace+''!==panel.kFace+'')]
        );
        setBasket(newBasket);
    }

    const setPositionCurrentCarouselPanelFromRow = (panel) => {
        let positionFromRowClick = selectedPanels.findIndex(selectedPanel => selectedPanel.frameId === panel.frameId);
        setPositionCurrentCarouselPanel(positionFromRowClick);
    }

    const carouselImages = () => {
        return(
            <Carousel showThumbs={false} showArrows={false} showIndicators={false} infiniteLoop={true} emulateTouch={true} showStatus={false} selectedItem={carouselImageIndex} transitionTime={0} swipeable={false}>
                {stationPhoto ?
                <div key={'photo'} className="img-carousel" style={{backgroundImage: `url('${config.CONTENT_DISTRIBUTION_URL_ENV}/map-photos/${stationPhoto}')`}} onClick={() => setPackageImageDialog(true)}/>
                :
                framesLevels.slice(1).map((stazioni) => { return(
                    <div key={stazioni.kLevel} className="img-carousel" style={{backgroundImage: `url('${config.CONTENT_DISTRIBUTION_URL_ENV}/map-default/${country}/${listaKLocation}-${stazioni.kLevel}.jpg')`}} onClick={() => setPackageImageDialog(true)}/>
                )})}
            </Carousel>
        )
    }

    const getCalendarLanguage = () => {
        if(language ==='fr'){
            return frLocale;
        }else if(language==='nl'){
            return nlLocale;
        }else{
            return enLocale;
        }
    };

    const handleNavigatePanelCarousel = (input) => {
        if(input === '+'){
            if(positionCurrentCarouselPanel !== selectedPanels.length-1){
                setPositionCurrentCarouselPanel(positionCurrentCarouselPanel+1);
            }else if(positionCurrentCarouselPanel === selectedPanels.length-1){
                setPositionCurrentCarouselPanel(0);
            }else{
                return false;
            }
        }else if (input === '-'){
            if(positionCurrentCarouselPanel !== 0){
                setPositionCurrentCarouselPanel(positionCurrentCarouselPanel-1);
            }else if(positionCurrentCarouselPanel === 0){
                setPositionCurrentCarouselPanel(selectedPanels.length-1);
            }else{
                return false;
            }
        }
    }
    
    const retrieveMissingPanelInfo = () => {
        //nello stato panels ci sono delle informazioni che non si trovano nel panelsSelected che sono necessarie per riempire il carosello
        return panels.find(panel => panel.FRAME_ID === selectedPanels[positionCurrentCarouselPanel].frameId);
    }

    const createSyncPanelLabelList = () => {
        let pnlsSync = selectedPanels[positionCurrentCarouselPanel].pnlSync.split(',');
        let digitalIdsLabelslist = pnlsSync.map(faccia => ( selectedPanels.find(pannello => (pannello.kFace||'')+'' === faccia) || {}).digitalId)

        return digitalIdsLabelslist.join(', ');
    }

    const [transition, setTransition] = useState(false);

    useEffect(() => {
        if(packageImageDialog && stationPhoto){
           setTransition(true)
    }
    }, [packageImageDialog])
    
    useEffect(() => {
      if(transition){
        creaStoSlider()
      }
    }, [transition])
    
    const setCarouselIndexFromPanelClick = (index) => {
        setPositionCurrentCarouselPanel(index);
    }

    const openCarouselFromClick = () =>{
        setShowCarousel(true);
    }

    const disableNextButton = country !=='1' ? basket.length===0 || errorCampaignName > 0 || errorDate1 || errorDate2 || !slotFree : errorCampaignName > 0 || errorDate1 || errorDate2 || !slotFree; 

    if(loading){
        return (<CircleProgress/>);
    }else{
        return(<div className={loadingCalculation ?'hide-overX':''}>
            {loadingCalculation &&(
                <div>
                    <CircleProgress/>
                </div>
            )}
            <div className={!loading ? ("your-campaign") : "none"}>
                <header className="heading">
                <div className="heading-content">
                    <h1 className="h1">{campaignName}</h1>
                    {country==='1' ? (<div className="breadcrumb margin-top--05">

                        <button className="breadcrumb--item active" type="button">
                            <span className='step-number'>1</span>
                            <FormattedMessage id={"breadcrumb.step1"}/>
                        </button>

                        <span className="next-step">
                            <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                        </span>

                        <button className="breadcrumb--item disabled default-curs" type="button">
                            <span className='step-number'>2</span>
                            <FormattedMessage id={"breadcrumb.step2"}/>
                        </button>

                        <span className="next-step">
                            <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                        </span>

                        <button className="breadcrumb--item disabled default-curs" type="button">
                            <span className='step-number'>3</span>
                            <FormattedMessage id={"breadcrumb.step3"}/>
                        </button>

                        <span className="next-step">
                            <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                        </span>

                        <button className="breadcrumb--item disabled default-curs" type="button">
                            <span className='step-number'>4</span>
                            <FormattedMessage id={"breadcrumb.step4"}/>
                        </button>
                    </div>)
                    :
                    (<div className="breadcrumb margin-top--05">

                        <button className={`breadcrumb--item completed`} type="button" onClick={() => {setConfirmBackDialog(true)}}>
                            <span className='step-number'>1</span>
                            <FormattedMessage id={"breadcrumb.stepLocation"}/>
                        </button>

                        <span className="next-step">
                            <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                        </span>

                        <button className="breadcrumb--item active default-curs" type="button">
                            <span className='step-number'>2</span>
                            <FormattedMessage id={"breadcrumb.step1"}/>
                        </button>

                        <span className="next-step">
                            <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                        </span>

                        <button className="breadcrumb--item disabled default-curs" type="button">
                            <span className='step-number'>3</span>
                            <FormattedMessage id={"breadcrumb.step2"}/>
                        </button>

                        <span className="next-step">
                            <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                        </span>

                        <button className="breadcrumb--item disabled default-curs" type="button">
                            <span className='step-number'>4</span>
                            <FormattedMessage id={"breadcrumb.step3"}/>
                        </button>

                        <span className="next-step">
                            <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                        </span>

                        <button className="breadcrumb--item disabled default-curs" type="button">
                            <span className='step-number'>5</span>
                            <FormattedMessage id={"breadcrumb.step4"}/>
                        </button>
                    </div>)}
                    </div>
                </header>            

                <section className="section-layout">
                    <div className="wrapper">
                        <h4 className="h4 margin">
                            <FormattedMessage id={"your-campaigns.campaignName"}/>
                        </h4>
                        <div className="input-container">
                            {/* <label className="label" htmlFor="email">{translate('campaignName')} *</label> */}
                            <div className="row">
                                <input 
                                    type="text" 
                                    className="input text-curs" 
                                    value={campaignName} 
                                    autoFocus={true} 
                                    onChange={handleChangeCampaignName} 
                                    maxLength={50}/>
                                </div>
                            {errorCampaignName !== 0 && (
                                <span className="message--error"><FormattedMessage id={`campaigns.error-title-${errorCampaignName}`} /></span>
                            )}
                        </div>
                    </div>
                </section>

                <section className="section-layout contrast-bg">
                    <div className="wrapper">
                    <h4 className="h4 margin"><FormattedMessage id={"your-campaigns.perimeter"}/></h4>

                    <div className="grid border-map-block" style={country==='1'?{justifyContent: 'space-between'}:{}}>
                        <div className="col-desk-12 col-mob-4 smaller-col map-height map-align" style={stationPhoto!=="N" ? {paddingRight:'0px'} : {width:"100%", paddingRight:'0px'}}>
                            {/* basket-col deve essere rimosso se si vuole passare alla visualizzazione
                                per packages corretta altrimenti si verifica un errore grafico */}
                            <div className="grid col-direction basket-col">
                                { /*perimeterButtons()*/ }
                                { country === '1'? perimeterButtons() : basketColumn() }
                            </div>
                        </div>
                        {stationPhoto==="M"
                            ?
                            <div className={`col-desk-12 col-mob-4 ${showCarousel ? 'temp-full-map-width' : 'full-map-width'}`}>
                                <span className='caption negative-margin-info-map block'><FormattedMessage id={"your-campaigns.map.info"}/></span>
                                <Map 
                                    zoom                        ={zoom} 
                                    coords                      ={locationCoords} 
                                    markers                     ={selectedPanels} 
                                    panelsList                  ={panels} 
                                    translate                   ={props.intl} 
                                    kLocation                   ={listaKLocation} 
                                    countryString               ={urlCountry} 
                                    currentPanelCarouselIndex   ={positionCurrentCarouselPanel}
                                    panelClickFunction          ={setCarouselIndexFromPanelClick}
                                    openCarouselFromClick       ={openCarouselFromClick}
                                    basket                      ={basket}
                                    addPanelToBasket            ={addToBasket}
                                ></Map>
                            </div>
                            :
                        stationPhoto==="N" 
                            ?
                            null
                            : 
                            <div className="col-desk-12 col-mob-4 larger">
                                {carouselImages()}
                            </div>
                        }
                        {showCarousel && country!=='1' && (
                            <div className="col-desk-12 col-mob-4 smaller-col map-height" style={stationPhoto!=="N" ? { backgroundColor:'#ffffff'} : {width:"100%", backgroundColor:'#ffffff'}}>
                            <div className="grid col-direction">
                                {/* {perimeterButtons()} */}
                                <div className="col-desk-12 col-mob-4 firstPackage-pad-left perimeter-card">
                                    <div className='card__container no-border no-margin panel-carousel space-between'>
                                        {/* <div className='head-foot-height centered-sections header-box'>
                                            <h4 >{selectedPanels[positionCurrentCarouselPanel].digitalId}</h4>
                                            <IconButton onClick={handleCloseCarousel}>
                                                <CloseIcon/>
                                            </IconButton>
                                        </div> */}
                                        <div>
                                        <div className='internal-border col-direction'>
                                            <div>
                                                <div className='full-height centered-sections pos-rel'>
                                                    {retrieveMissingPanelInfo().PHOTO_ENABLED === 'Y' ?
                                                        (<>
                                                            <img style={{width:'100%', height:'200px', objectFit: 'cover'}} src={`${config.CONTENT_DISTRIBUTION_URL_ENV}/panel-photos/${panels.find(panel => panel.FRAME_ID === selectedPanels[positionCurrentCarouselPanel].frameId).KFACE}.jpg`} alt='panel-img'/>
                                                            <IconButton className='carousel-image-fullscreen-btn' onClick={() => {setPackageImageDialog(true); setCurrentCarouselImageToModal(`${config.CONTENT_DISTRIBUTION_URL_ENV}/panel-photos/${panels.find(panel => panel.FRAME_ID === selectedPanels[positionCurrentCarouselPanel].frameId).KFACE}.jpg`)}}>
                                                                <FullscreenIcon/>
                                                            </IconButton>
                                                        </>)
                                                        :
                                                        null
                                                    }
                                                </div>{/* 'No image Found' */}
                                                <div className='panel-main-info'>
                                                    <div>
                                                        {selectedPanels[positionCurrentCarouselPanel].digitalId}
                                                    </div>
                                                    <div className='grey-txt'>
                                                        {selectedPanels[positionCurrentCarouselPanel].address}
                                                    </div>
                                                </div>
                                                <div className="separator"></div>
                                                <div className='panel-other-info'>
                                                    <div>
                                                        <img style={{width:'15px', height:'15px'}} src='https://img.icons8.com/material-outlined/2x/resize-diagonal.png' />
                                                        &nbsp;&nbsp;
                                                        {selectedPanels[positionCurrentCarouselPanel].dimensions}
                                                    </div>
                                                    <div>
                                                        <img style={{width:'15px', height:'15px'}} src='https://img.icons8.com/material-outlined/2x/resolution.png' />
                                                        &nbsp;&nbsp;
                                                        {selectedPanels[positionCurrentCarouselPanel].resolution}
                                                    </div>
                                                </div>
                                                <div className="separator"></div>
                                                {country!=='1'&&(<div className='panel-button-space'>
                                                    {![...basket].map(panel => panel.kFace.toString()).includes(selectedPanels[positionCurrentCarouselPanel].kFace.toString())
                                                        ?
                                                        <button className="button--primary-large panel-button" type="button" onClick={() => addToBasket(selectedPanels[positionCurrentCarouselPanel])}><FormattedMessage id="your-campaigns.carousel.panel.add"/></button>
                                                        :
                                                        <button className="button remove-from-basket panel-button" type="button" onClick={() => removeFromBasket(selectedPanels[positionCurrentCarouselPanel])}><FormattedMessage id="your-campaigns.carousel.panel.remove"/></button>
                                                    }
                                                </div>)}
                                                {listaKLocation === '33' && (
                                                        <div className='extra-info' style={{textAlign:'center'}}>
                                                            <FormattedMessage id={"your-campaigns.map.overlap"}/>
                                                        </div>
                                                    )}
                                                    {selectedPanels[positionCurrentCarouselPanel].pnlSync !== null && (
                                                        <div className='extra-info' style={{textAlign:'center'}}>
                                                            <FormattedMessage id={"your-campaigns.sync.start"}/>
                                                            &nbsp;
                                                            {selectedPanels[positionCurrentCarouselPanel].digitalId}
                                                            &nbsp;
                                                            <FormattedMessage id={"your-campaigns.sync.mid"}/>
                                                            &nbsp;
                                                            {createSyncPanelLabelList()}
                                                            &nbsp;
                                                            <FormattedMessage id={"your-campaigns.sync.end"}/>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                        {/* flexed-end */}
                                        </div>
                                        <div className='head-foot-height centered-sections header-box'>
                                            <div>
                                                {positionCurrentCarouselPanel+1} <FormattedMessage id={"of"}/> {selectedPanels.length}
                                            </div>
                                            <div className='flexed'>
                                                <div className='pointer-curs arrow-carousel' onClick={ () => handleNavigatePanelCarousel('-')}><NavigateBeforeIcon/></div>
                                                <div className='pointer-curs arrow-carousel' onClick={ () => handleNavigatePanelCarousel('+')}><NavigateNextIcon/></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                    </div>
                    </div>
                </section>
                {/* temporaneo solo in francia */}
                {country === '1' && framesLevels[1].refinement === 'Y' && (<>
                <section className="section-layout">
                    <div className="wrapper">
                    <h4 className="h4 margin"><FormattedMessage id={"your-campaigns.refine"}/></h4>

                    <div className="grid">
                    <div className="col-desk-6 col-mob-4">
                        <div className={allFrames === true ? "card selected" : "card"} onClick={() => handleClickAllFrames(true)}>
                            <div className="card__container">
                            <div className="card__container--content">
                                <p className="paragraph"><FormattedMessage id={"your-campaigns.refine.label.begin"}/>&nbsp;<FormattedMessage id={"your-campaigns.refine.full"}/>&nbsp;<FormattedMessage id={"your-campaigns.refine.label.end"}/></p>
                                <p className="paragraph bold primary margin-top--02"><img src={img2} className="panel-img" alt='panelImage'></img>{framesGroupFull} <FormattedMessage id={"your-campaigns.perimeter.screens"}/></p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-desk-6 col-mob-4">
                        <div className={allFrames === false ? "card selected row-adapt" : "card card--clickable noBorder row-adapt"} onClick={() => handleClickAllFrames(false)}>
                            <div className="card__container">
                            <div className="card__container--content">
                                <p className="paragraph"><FormattedMessage id={"your-campaigns.refine.label.begin"}/>&nbsp;<FormattedMessage id={"your-campaigns.refine.half"}/>&nbsp;<FormattedMessage id={"your-campaigns.refine.label.end"}/></p>
                                <p className="paragraph bold primary margin-top--02"><img src={img2} className="panel-img" alt='panelImage'></img>{framesGroupHalf} <FormattedMessage id={"your-campaigns.perimeter.screens"}/></p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-desk-6 col-mob-4">
                    </div>
                    </div>
                    <div>
                        {country==='1' && (
                        <>
                            <p className="paragraph margin-bottom--03"><FormattedMessage id={"your-campaigns.giants"}/></p>
                            <div className="radio-group margin-bottom--04">
                                <label className="container-radio"><FormattedMessage id={"yes"}/>
                                    <input name="Radios" type="radio" checked={giant === true} onClick={() => handleClickGiant(true)}></input>
                                    <span className="checkmark"></span>
                                </label>

                                <label className="container-radio"><FormattedMessage id={"no"}/>
                                    <input name="Radios" type="radio" checked={giant === false} onClick={() => handleClickGiant(false)}></input>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </>)}
                    </div>
                    </div>
                </section></>)}

                <section className="section-layout">
                <div className="wrapper">
                    <h4 className="h4 margin"><FormattedMessage id={"your-campaigns.dates"}/></h4>
                    <div className="grid">
                    <div className="col-desk-12 col-mob-4">

                        <div className="grid">
                            <MuiThemeProvider theme={themeDates}>
                                <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils} locale={getCalendarLanguage()}>
                                    <div className="col-desk-12 col-mob-4">
                                        <div className="input-container">
                                            <label className="label">
                                                <FormattedMessage id={'your-campaigns.dates.begin'}/>
                                            </label>
                                                <div className="row margin-bottom--04 calendars">
                                                    <KeyboardDatePicker
                                                        variant="inline"
                                                        disablePast={true}
                                                        autoOk={true}
                                                        format="dd/MM/yyyy"
                                                        value={beginDate}
                                                        minDate={minDate}
                                                        maxDate={maxDate}
                                                        onChange={handleChangeBeginDate}
                                                        className="date-picker input"
                                                        invalidDateMessage={<FormattedMessage id={'your-campaigns.dates.error.format'}/>}
                                                        minDateMessage={`${translate('your-campaigns.dates.error.minBeginDate')} ${minDate.format('DD/MM/YYYY').toString()}`}
                                                        maxDateMessage={`${translate('your-campaigns.dates.error.maxDate')} ${maxDate.format('DD/MM/YYYY').toString()}`}
                                                        onError={(error) => handleDateError1(error)}
                                                    />
                                                </div>
                                        </div>
                                    </div>
                                    <div className="col-desk-12 col-mob-4">
                                        <div className="input-container">
                                            <label className="label">
                                                <FormattedMessage id={'your-campaigns.dates.end'}/>
                                            </label>
                                                <div className="row margin-bottom--04 calendars">
                                                    <KeyboardDatePicker
                                                        variant="inline"
                                                        disablePast={true}
                                                        autoOk={true}
                                                        format="dd/MM/yyyy"
                                                        value={endDate}
                                                        minDate={beginDate}
                                                        maxDate={maxDate}
                                                        onChange={handleChangeEndDate}
                                                        className="date-picker input"
                                                        invalidDateMessage={<FormattedMessage id={'your-campaigns.dates.error.format'}/>}
                                                        minDateMessage={<FormattedMessage id={'your-campaigns.dates.error.minEndDate'}/>}
                                                        maxDateMessage={`${translate('your-campaigns.dates.error.maxDate')} ${maxDate.format('DD/MM/YYYY').toString()}`}
                                                        onError={(error) => handleDateError2(error)}
                                                    />
                                                </div>
                                        </div>
                                    </div>
                                    <div className="input-container no-margin">
                                        <label className="label">
                                            <FormattedMessage id={'your-campaigns.daypart.title'}/>
                                        </label>
                                    </div>
                                </MuiPickersUtilsProvider>
                            </MuiThemeProvider>
                        </div>
                        <div className="grid">
                            {daypartButtons()}
                        </div>
                        </div>
                    <div className="col-desk-12 col-mob-4">
                    <div className={`${showAudience ? 'col-desk-24 col-mob-4' : 'col-desk-24 col-mob-4' }`}>
                        <article className="card">
                            <div className="card__container">
                                <div className="card__container--header">
                                    <h4 className='h4'><FormattedMessage id={'your-campaigns.resume.title'}/></h4>
                                </div>
                                <div className="card__container--content">
                                    <div className="layout-spacebetween margin-bottom--00">
                                        <p className="paragraph margin-bottom--00"><FormattedMessage id={"your-campaigns.resume.screens"}/></p>
                                        {country !== '1' ? basket.length : framesSelected}
                                    </div>

                                    <div className="layout-spacebetween margin-bottom--00">
                                        <p className="paragraph margin-bottom--00"><FormattedMessage id={"your-campaigns.resume.spot"}/></p>
                                        {CommonUtilities.formatPositiveIntNum(numberOfSpot)}
                                    </div>    

                                    <div className={`layout-spacebetween margin-bottom--${showAudience ? '00' : '04'}`}>
                                        <p className="paragraph margin-bottom--00">
                                            <FormattedMessage id={"your-campaigns.resume.spot.frequence1"}/> </p>
                                            {country==='1'?
                                            <FormattedMessage id={"your-campaigns.resume.spot.frequence2.fr"}/>
                                            :
                                            <FormattedMessage id={"your-campaigns.resume.spot.frequence2.be"}/>
                                            }
                                    </div>

                                    {showAudience&&(<div className="layout-spacebetween margin-bottom--04">
                                        <p className="paragraph margin-bottom--00"><FormattedMessage id={"your-campaigns.resume.contacts"}/></p>
                                        {CommonUtilities.formatPositiveIntNum(estimatedAvgContacts)}
                                    </div>)}

                                    <span className="separator layout-spacebetween margin-bottom--04"></span>

                                    <div className="layout-spacebetween">
                                        <p className="paragraph margin-bottom--00"><FormattedMessage id={"your-campaigns.resume.price"}/></p>
                                        <span className="highlight">{CommonUtilities.formatNumber(price, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}</span>
                                    </div>
                                </div>
                        </div>
                        </article>
                    </div>
                    </div>
                    <div className="button-line--wrapper">
                        <button className="button--secondary-large" type="button" onClick={gotoMyCampaigns}><FormattedMessage id="campaigns.go-back"/></button>
                        <button className="button--primary-large" type="button" onClick={proceedCheck} disabled={disableNextButton}><FormattedMessage id="campaigns.next-step"/></button>
                    </div>
                    </div>
                    {!slotFree && (
                        <div className="msg-already-book"><FormattedMessage id="campaigns.slot-booked"/></div>
                    )}
                    </div>
                </section>

                <footer className="section-layout text-center">
                        <p className="paragraph"><FormattedMessage id="assistance.message"/><a href={`mailto:${translate(`assistance.email.${country}`)}`}><FormattedMessage id={`assistance.email.${country}`}/></a></p>
                       <LegalLinks country={country}/>
                </footer>
        
            </div>
            <Dialog onClose={handleConfirmCloseBackDialog} open={confirmBackDialog}>
                <DialogTitle>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <span className='item tag' style={{backgroundColor:'#FFEAAB', color:'#EEB302', borderRadius:'50%', width:'4rem', height:'4rem'}}>
                            <WarningAmberIcon/>
                        </span>&nbsp;
                        <h4 className="h4" style={{fontWeight: '400'}}>
                            <FormattedMessage id="modal.confirm.back.text"/>
                        </h4>
                    </div>
                </DialogTitle>
                <DialogActions className='pointto box-pad-minus'>
                    <button className="button--secondary-large" style={{padding:'0.6rem 1.4rem'}} onClick={gotoLocationSelection}>
                        <FormattedMessage id="modal.confirm.back.confirm"/>
                    </button>
                    <button className="button--quaternary-large" style={{padding:'0.6rem 1.4rem', color:'black'}} onClick={handleConfirmCloseBackDialog}>
                        <FormattedMessage id="modal.confirm.back.cancel"/>
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog className='reset-creativity-dialog' onClose={handleCloseResetCreativityDialog} open={resetCreativityDialog}>
                <DialogTitle className='title-dialog'>
                    <FormattedMessage id="your-campaigns.resetCreativityDialog.title"/>
                    <IconButton onClick={handleCloseResetCreativityDialog}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent className='content-dialog'>
                    <FormattedMessage id="your-campaigns.resetCreativityDialog"/>
                </DialogContent>
                <DialogActions className='footer-dialog'>
                    <button className="button--secondary-large" onClick={gotoCampaignSummary}>
                        <FormattedMessage id="your-campaigns.keep"/>
                    </button>
                    <button className="button--primary-large" onClick={gotoResetCreativity}>
                        <FormattedMessage id="your-campaigns.reset"/>
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog className='dialog-full' onClose={handleClosePackageImageDialog}  open={packageImageDialog}>
                <DialogContent className='pointto box-pad-minus-noBottom'>
                {
                    currentCarouselImageToModal !== "" ?
                    <div className='parent' id={'container-image-toDrag'}>
                        <img className={`full-image-dialog zoom-${zoomPackageImage}`} alt={'panel'} key={'photo'} src={currentCarouselImageToModal}/>
                    </div>
                    :
                    stationPhoto ?
                    <div className='parent' id={'container-image-toDrag'}>
                        <img className={`full-image-dialog zoom-${zoomPackageImage}`} alt={'package'} key={'photo'} src={`${config.CONTENT_DISTRIBUTION_URL_ENV}/map-photos/${stationPhoto}`}/>
                    </div>
                    :
                    framesLevels.slice(1,2).map((stazioni) => { return(
                        <img className={`full-image-dialog zoom-${zoomPackageImage}`}  key={stazioni.kLevel} src={`${config.CONTENT_DISTRIBUTION_URL_ENV}/map-default/${country}/${listaKLocation}-${stazioni.kLevel}.jpg`}/>
                    )})
                }
                </DialogContent>
                <DialogActions className='pointto box-pad-minus space-between'>
                    <div className='flexed wide'>
                        <button className="button--secondary-large zoom-in" style={{padding:'0.6rem 1.4rem'}} onClick={() => handleResizePackageImage('+')} disabled={zoomPackageImage===4}>
                            <ZoomIn32/>
                        </button>
                        <button className="button--secondary-large margin-left--04 zoom-out" style={{padding:'0.6rem 1.4rem'}} onClick={() => handleResizePackageImage('-')} disabled={zoomPackageImage===1}>
                            <ZoomOut32/>
                        </button>
                    </div>
                    <button className="button--quaternary-large button-absolute-right" style={{padding:'0.6rem 1.4rem', color:'black'}} onClick={handleClosePackageImageDialog}>
                        <FormattedMessage id="modal.confirm.back.cancel"/>
                    </button>
                </DialogActions>
            </Dialog>
        </div>
        );}
    }


export default injectIntl(YourCampaign);