const environment = {
    dev: {
        API_URL: 'https://backend.mediaone.systrategy.com/cinv',
        //GLOOH_URL: 'https://ecooh.staging.glooh.app',
        //GLOOH_MEDIA_URL: 'https://ecooh.staging.glooh.media',
        //GLOOH_VALUE: false,
        //STRIPE_PUBLIC_KEY_FR: 'pk_test_51HPVUJLLXovtWSpHumOVRDTrI0GtTXasy3UoydYEo8fGU1IhAtcLo2MoP6HZJu9ZgB5kD3eZ6Jso0sQeyE9rM9XD00TTy5RlNn',
        //STRIPE_PUBLIC_KEY_BE: 'pk_test_51KlsYIIjvxr1IFqYvYZz41xNiolBA0MjWAyxFCiMrIXFSuO7VMdnDp7Ddu7Bh1TlpIbIo5IMYHaMhUDavQ2hZyDX008FgePdZo',
        LANGUAGES: ['en', 'fr', 'nl'],
        COUNTRIES_LABELS: ['fr', 'be', 'it'],
        CONTENT_DISTRIBUTION_URL: 'https://clearchannel-contents.golive.systrategy.com',
        //CONTENT_DISTRIBUTION_URL_ENV: 'https://clearchannel-contents.golive.systrategy.com/dev',
        CONTENT_DISTRIBUTION_URL_ENV: 'https://attachments.cinv.systrategy.com',
        REACT_APP_USERPOOL_ID: 'eu-west-3_fgjBxlUiE',
        REACT_APP_CLIENT_ID: '2ut61s2segh9gls6satj87t5ub',
        //RECAPTCHA_PUBLIC_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
        AMBIENTE: 'dev',
        REFRESH_TOKEN_INTERVAL: 1800000,
        DAY_IN_SECONDS: 86400,
        FORCE_MFA_TOTP: true
    },
    test: {
        API_URL: 'https://clearchannel.api.systrategy.com/test-golive',
        //GLOOH_URL: 'https://ecooh.staging.glooh.app',
        //GLOOH_MEDIA_URL: 'https://ecooh.staging.glooh.media',
        //GLOOH_VALUE: false,
        //STRIPE_PUBLIC_KEY_FR: 'pk_test_51HPVUJLLXovtWSpHumOVRDTrI0GtTXasy3UoydYEo8fGU1IhAtcLo2MoP6HZJu9ZgB5kD3eZ6Jso0sQeyE9rM9XD00TTy5RlNn',
        //STRIPE_PUBLIC_KEY_BE: 'pk_test_51KlsYIIjvxr1IFqYvYZz41xNiolBA0MjWAyxFCiMrIXFSuO7VMdnDp7Ddu7Bh1TlpIbIo5IMYHaMhUDavQ2hZyDX008FgePdZo',
        LANGUAGES: ['en', 'fr', 'nl'],
        COUNTRIES_LABELS: ['fr', 'be'],
        CONTENT_DISTRIBUTION_URL: 'https://clearchannel-contents.golive.systrategy.com',
        CONTENT_DISTRIBUTION_URL_ENV: 'https://clearchannel-contents.golive.systrategy.com/test',
        REACT_APP_USERPOOL_ID: 'eu-west-3_Jf4MxmyMS',
        REACT_APP_CLIENT_ID: '2mhkkdhqs6fa948vt7td7jqofs',
        RECAPTCHA_PUBLIC_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
        AMBIENTE: 'test',
        REFRESH_TOKEN_INTERVAL: 1800000,
        DAY_IN_SECONDS: 86400,
        FORCE_MFA_TOTP: true
    },
    prod: {
        API_URL: 'https://clearchannel.api.systrategy.com/golive',
        //GLOOH_URL: 'https://api.ecooh.glooh.app',
        //GLOOH_MEDIA_URL: 'https://ecooh.glooh.app',
        //GLOOH_VALUE: true,
        //STRIPE_PUBLIC_KEY_FR: 'pk_live_51JBEltGImBhYsiVESE3Wj2apvfqqgh19AeGdQq5o4OWss171eX56jgD5SVQIGe0zKJFCKZ13bIw76g7BUyjvq0LZ00dbFjXF7h',
        //STRIPE_PUBLIC_KEY_BE: 'pk_live_51KlsYIIjvxr1IFqYz5ySQIhFvhIqMX4YGTkP7tLFqkLLpvVLuQCdqRuAAQ3JN9oV5iGIHKqYLvj4p0cI8lO6TWhJ00lyDvroHd',
        LANGUAGES: ['en', 'fr', 'nl'],
        COUNTRIES_LABELS: ['fr', 'be'],
        CONTENT_DISTRIBUTION_URL: 'https://clearchannel-contents.golive.systrategy.com',
        CONTENT_DISTRIBUTION_URL_ENV: 'https://clearchannel-contents.golive.systrategy.com/prod',
        REACT_APP_USERPOOL_ID: 'eu-west-3_TlX43IPDV',
        REACT_APP_CLIENT_ID: '7b449p6vd625rdij7hn4lrjh8c',
        RECAPTCHA_PUBLIC_KEY: '6LcXGdYfAAAAAPEtJZyi-UkR11LkJL54lKO7lHLl',
        AMBIENTE: 'prod',
        REFRESH_TOKEN_INTERVAL: 1800000,
        DAY_IN_SECONDS: 86400,
        FORCE_MFA_TOTP: true
    }
};

export default environment[ process.env.REACT_APP_ENV || 'dev' ];
