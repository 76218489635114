const messages = {
  "error.global.generic": "Une erreur est survenue",
  "extraPages.404Msg": "Oups, une erreur est survenue. Page non trouvée !",
  "extraPages.500Msg": "Il y a eu une erreur de connexion, revenez en arrière et réessayez",
  "error.global.no-privileges": "Privilèges insuffisants pour effectuer l'opération",    
  "message.global.success": "Operation complétée",
  "assistance.message":"Besoin d'aide? Contactez-nous à: ",
  /* 1 = Francia, 2 = Belgio */
  "assistance.email.1": " developers@systrategy.it",
  "assistance.email.2": " developers@systrategy.it",
  "assistance.email.3": " developers@systrategy.it",
  "connect": "Se connecter",
  "disconnect": "Déconnexion",
  "email": "Adresse email",
  "error": "Erreur",
  "yes": "Oui",
  "no": "Non",
  "of": "of",
  "france": "France",
  "belgium": "Belgique",
  "italy": "Italie",
  "mod.success": "Vos changements ont été enregistrés",
  "cookies.disclaimer": "En poursuivant votre navigation sur ce site vous acceptez l’utilisation de cookies essentiels permettant d'utiliser les principales fonctionnalités de notre site. Sans ces cookies, vous ne pourrez pas utiliser notre site normalement.",
  "cookies.info": "En savoir plus",
  "first.link":"Mes campagnes",
  "first.title": "Soyez visible partout",
  "first.subtitle": "Etendez la visibilité de votre vitrine dans les",
  "first.description.1": "Lancez une campagne d'affichage sur les panneaux digitaux",
  "first.description.2": "Lancer une campagne d'affichage sur les panneaux digitaux",
  "first.description.3": "Lancer une campagne d'affichage sur les panneaux digitaux",
  "first.sub.description.1": "Touchez des millions d'acheteurs, chaque jour dans les malls, en quelques clics.",
  "first.sub.description.2": "Touchez des millions de passagers, chaque jour dans les gares et en quelques clics.",
  "first.sub.description.3": "Touchez des millions de passagers, chaque jour dans les gares et en quelques clics.",
  "first.description1":"Lancez une campagne d'affichage sur les panneaux digitaux du centre",
  "first.description2":"C'est rapide, simple et en 3 étapes ! Besoin d'aide? Nos équipes sont disponibles à tout moment via notre chatbot !",
  "first.description3":"C'est rapide, simple et en 3 étapes ! Besoin d'aide? Nos équipes sont disponibles à tout moment via notre chatbot !",
  "first.boxTitle1.1":"Sélectionnez vos écrans...",
  "first.boxTitle2.1":"Créez votre visuel publicitaire...",
  "first.boxTitle3.1":"Planifiez et payez en ligne...",
  "first.boxTitle4.1": "Gérez facilement votre publicité...",
  "first.boxTitle1.2":"Sélectionnez vos écrans...",
  "first.boxTitle2.2":"Réalisez votre visuel publicitaire...",
  "first.boxTitle3.2":"Planifiez et payez en ligne...",
  "first.boxTitle4.2": "Gérez simplement votre publicité...",
  "first.boxTitle1.3":"Sélectionnez vos écrans...",
  "first.boxTitle2.3":"Réalisez votre visuel publicitaire...",
  "first.boxTitle3.3":"Planifiez et payez en ligne...",
  "first.boxTitle4.3": "Gérez simplement votre publicité...",
  "first.boxText1.1":"dans les centres commerciaux, en ciblant simplement votre communication.",
  "first.boxText2.1":"avec nos modèles professionnels et nos outils de conception.",
  "first.boxText3.1":"sur une plateforme 100% en ligne, efficace et sécurisée.",
  "first.boxText4.1": "avec des indicateurs et l'historique des campagnes.",
  "first.boxText1.2":"dans les gares SNCB, tout en ciblant simplement votre communication.",
  "first.boxText2.2":"grâce à nos modèles professionnels et nos outils de création.",
  "first.boxText3.2":"sur une plateforme 100% online, performante et sécurisée.",
  "first.boxText4.2": "avec des indicateurs et l'historique de vos campagnes.",
  "first.boxText1.3":"dans les gares SNCB, tout en ciblant simplement votre communication.",
  "first.boxText2.3":"grâce à nos modèles professionnels et nos outils de création.",
  "first.boxText3.3":"sur une plateforme 100% online, performante et sécurisée.",
  "first.boxText4.3": "avec des indicateurs et l'historique de vos campagnes.",
  "first.buttonText":"Créer ma première campagne",
  "etape1": "Étape 1",
  "etape2": "Étape 2",
  "etape3": "Étape 3",
  "etape4": "Étape 4",
  "breadcrumb2": "Créer une campagne",
  "breadcrumb.stepLocation": "Sélection de l'emplacement",
  "breadcrumb.step1": "Votre campagne",
  "breadcrumb.step2": "Visuel",
  "breadcrumb.step3": "Résumé",
  "breadcrumb.step4": "Paiement",
  "sidebar.dashboard": "Tableau de bord",
  "my-campaigns.title.malls": "centres commerciaux",
  "my-campaigns.title.stations": "gares",
  "my-campaigns.created": "Créée",
  "my-campaigns.wait-payment": "Paiement en attente",
  "my-campaigns.wait-approval": "En attente de traitement",
  "my-campaigns.approved": "Confirmée",
  "my-campaigns.newCampaignButton": "Nouvelle Campagne",
  "my-campaigns.table.name": "Nom",
  "my-campaigns.table.location": "Emplacement",
  "my-campaigns.table.period": "Dates",
  "my-campaigns.table.status": "Status",
  "my-campaigns.table.price": "Prix",
  "my-campaigns.table.panels": "Panneaux",
  "my-campaigns.table.action": "Actions",
  "my-campaigns.table.actionBtn": "Procéder au paiement",
  "my-campaigns.table.fromDate": "Du",
  "my-campaigns.table.toDate": "Au",
  "my-campaigns.box.title.empty": "Vous n'avez aucune campagne correspondant à l'état de la colonne",
  "my-campaigns.box.title": "Actions en attente",
  "my-campaigns.actions.empty": "Vous n'avez aucune action en attente!",
  "my-campaigns.actions.create": "Créer une campagne",
  "my-campaigns.box.situation.alert": "Alerte",
  "my-campaigns.box.situation.info": "Info",
  "my-campaigns.box.situation.await.planning": "EN ATTENTE DE PLANIFICATION",
  "my-campaigns.box.situation.await.planning.lowercase": "En attente de planification",
  "my-campaigns.box.situation.await.payment": "EN ATTENTE DE PAIEMENT",
  "my-campaigns.box.situation.await.payment.lowercase": "En attente de paiement",
  "my-campaigns.box.situation.approved": "APPROUVÉ",
  "my-campaigns.box.situation.approved.lowercase": "Approuvé",
  "my-campaigns.box.situation.confirmed": "CONFIRMÉ",
  "my-campaigns.box.situation.confirmed.lowercase": "Confirmé",
  "my-campaigns.box.situation.await.approval": "EN ATTENTE D'APPROBATION",
  "my-campaigns.box.situation.await.approval.lowercase": "En attente d'approbation",
  "my-campaigns.box.situation.live": "En cours",
  "my-campaigns.box.situation.live.left": "JOURS RESTANTS!",
  "my-campaigns.box.situation.live.left.1": "JOUR RESTANT!",
  "my-campaigns.box.situation.completed": "Terminées",
  "my-campaigns.box.situation.completed.uppercase": "TERMINÉES",
  "my-campaigns.box.situation.deleted": "Supprimé",
  "my-campaigns.box.situation.deleted.uppercase": "SUPPRIMÉ",
  "my-campaigns.box.situation.ongoing": "A venir",
  "my-campaigns.box.situation.running": "En cours",
  "my-campaigns.box.situation.ended": "Terminée / Supprimée",
  "my-campaigns.box.situation.ongoing.noRow": "Aucune campagne à venir pour le moment",
  "my-campaigns.box.situation.running.noRow": "Aucune campagne en cours pour le moment",
  "my-campaigns.box.situation.ended.noRow": "Pas de campagne terminée/supprimée pour le moment",
  "my-campaigns.box.footer.button.mod": "Modifier",
  "my-campaigns.box.footer.button.del": "Supprimer",
  "my-campaigns.box.footer.button.pay": "Paiement",
  "my-campaigns.box.footer.button.final": "Détails",
  "my-campaigns.box.more": "Montrer plus...",
  "my-campaigns.box.less": "Montrer moins...",
  "my-campaigns.box.created": "Créé il y a",
  "my-campaigns.box.created.day": " jour",
  "my-campaigns.box.created.days": " jours",
  "my-campaigns.box.created.today": "Créé aujourd'hui",
  "my-campaigns.box.purchased": "Payé le ",
  "my-campaigns.box.resume": "Résumer",
  "my-campaigns.modal.delete.warn": "Vous ne pouvez pas supprimer une campagne avec des disponibilités réservées. Réessayez plus tard.",
  "my-campaigns.modal.delete.title": "Voulez-vous supprimer la campagne sélectionnée?",
  "my-campaigns.warning.configuration": "La configuration de cette campagne n'est pas terminée",
  "my-campaigns.warning.payment": "Cette campagne est en attente de paiement",
  "my-campaigns.warning.rejected": "Cette campagne a été rejetée par Clear Channel",
  "location-selection.table.panel": "Panneau",
  "location-selection.table.panels": "Panneaux",
  "location-selection.table.noRows": "Aucun Élément Trouvé",
  "your-campaigns.newCampaign": "Nouvelle Campagne",
  "your-campaigns.campaignName": "Je choisis le nom de ma campagne",
  "your-campaigns.perimeter": "Je choisis mon périmètre de diffusion",
  "your-campaigns.perimeter1": "Tout le mall",
  "your-campaigns.perimeter2": "Niveau 0",
  "your-campaigns.perimeter3": "Niveau 1",
  "your-campaigns.perimeter.screens": "écrans",
  "your-campaigns.perimeter.days": "jours",
  "your-campaigns.perimeter.day": "jour",
  "your-campaigns.perimeter.label": "À partir de",
  "your-campaigns.giants": "Je souhaite intégrer les écrans géants dans ma diffusion",
  "your-campaigns.refine": "J'affine ma sélection",
  "your-campaigns.refine.label.begin": "Sélectionner",
  "your-campaigns.refine.full": "tous les écrans",
  "your-campaigns.refine.half": "la moitié des écrans",
  "your-campaigns.refine.label.end": "de mon périmètre",
  "your-campaigns.dates": "Je choisis les dates de ma campagne",
  "your-campaigns.dates.begin": "Date de début",
  "your-campaigns.dates.end": "Date de fin",
  "your-campaigns.dates.error.format": "Format de date invalide : DD/MM/YYYY",
  "your-campaigns.dates.error.minBeginDate": "La date ne doit pas être inférieure au",
  "your-campaigns.dates.error.minEndDate": "La date ne doit pas être inférieure à la date de début",
  "your-campaigns.dates.error.maxDate": "La date de fin de la campagne ne peut excéder le",
  "your-campaigns.level.fr.floor-all": "Tout le mall",
  "your-campaigns.level.fr.floor-0": "Niveau 0",
  "your-campaigns.level.fr.floor-1": "Niveau 1",
  "your-campaigns.level.be.p-all": "Toute la station",
  "your-campaigns.level.be.p1": "Paquet 1",
  "your-campaigns.level.be.p2": "Paquet 2",
  "your-campaigns.level.be.p3": "Paquet 3",
  "your-campaigns.level.be.p4": "Paquet 4",
  "your-campaigns.level.be.p5": "Paquet 5",
  "your-campaigns.group.be.level-1": "Niveau -1",
  "your-campaigns.group.be.level0": "Niveau 0",
  "your-campaigns.group.be.level1": "Niveau 1",
  "your-campaigns.group.be.level3": "Niveau 3",
  "your-campaigns.group.be.eurostar": "Thalys-Eurostar area",
  "your-campaigns.group.be.entry": "Entrée",
  "your-campaigns.group.be.level0.entry": "Niveau 0 Entrée",
  "your-campaigns.group.be.level0.exit": "Niveau 0 Sortie",
  "your-campaigns.group.be.level0.hall": "Niveau 0 Tout",
  "your-campaigns.group.be.international-trains": "Trains Internationaux",
  "your-campaigns.group.be.platform": "Quais",
  "your-campaigns.group-be-panel": "Panel",
  "your-campaigns.group-it-panel": "Panel",
  "your-campaigns.daypart.title": "Créneau horaire",
  "your-campaigns.daypart": "Je choisis les tranches horaires",
  "your-campaigns.daypart.fr.morning": "Jusqu’à 15h",
  "your-campaigns.daypart.fr.afternoon": "A partir de 15h",
  "your-campaigns.daypart.full": "Journée complète",
  "your-campaigns.daypart.be.morning": "6.00am - 12.00pm",
  "your-campaigns.daypart.be.afternoon": "12.00pm - 6.00pm",
  "your-campaigns.daypart.be.evening": "6.00pm - 12.00am",
  "your-campaigns.daypart.it.morning": "6.00am - 12.00pm",
  "your-campaigns.daypart.it.afternoon": "12.00pm - 6.00pm",
  "your-campaigns.daypart.it.evening": "6.00pm - 12.00am",
  "your-campaigns.resume.title": "Montant de la campagne",
  "your-campaigns.resume.screens": "Nombre d'écrans:",
  "your-campaigns.resume.spot": "Nombre de spots:",
  "your-campaigns.resume.spot.frequence1": "Fréquence moyenne:",
  "your-campaigns.resume.spot.frequence2.fr": "10 secondes/minute",
  "your-campaigns.resume.spot.frequence2.be": "6 secondes/minute",
  "your-campaigns.resume.contacts": "Contacts moyens estimés:",
  "your-campaigns.resume.price": "Prix net H.T. :",
  "your-campaigns.map.info": "Veuillez cliquer les icônes d'écran sur la carte pour afficher plus d'informations",
  "your-campaigns.map.overlap": "*Ces panneaux partagent la même position*",
  "your-campaigns.basket.head": "Votre sélection",
  "your-campaigns.basket.empty": "Sélectionnez les écrans souhaités depuis la carte",
  "your-campaigns.basket.foot.singlePanel.panel": "écran",
  "your-campaigns.basket.foot.singlePanel.select": "sélectionné",
  "your-campaigns.basket.foot.multiPanel.panel": "écran(s)",
  "your-campaigns.basket.foot.multiPanel.select": "sélectionné(s)",
  "your-campaigns.carousel.panel.add": "Ajouter à ma sélection",
  "your-campaigns.carousel.panel.remove": "Supprimer de ma sélection",
  "your-campaigns.resetCreativityDialog.title": "Réinitialiser votre visuel?",
  "your-campaigns.resetCreativityDialog": "Souhaitez-vous réinitialiser votre visuel?",
  "your-campaigns.keep": "Garder",
  "your-campaigns.reset": "Réinitialiser",
  "your-campaigns.sync.start": "Cet écran",
  "your-campaigns.sync.mid": "est synchronisé avec l'écran",
  "your-campaigns.sync.end": "et doivent être sélectionnés ensemble",
  "campaign.creative.title": "Je personnalise le visuel de ma campagne",
  "campaign.creative.template": "Choisir un template",
  "campaign.creative.custom": "Utilisez votre propre vidéo",
  "campaign.creative.specs": "Veuillez vous assurer que votre vidéo respecte les spécifications requises",
  "campaign.creative.dimension.label": "Dimensions",
  "campaign.creative.dimension.value": "1080 x 1920px",
  "campaign.creative.duration.label": "Durée",
  "campaign.creative.duration.value": "secondes",
  "campaign.creative.format.label": "Format",
  "campaign.creative.format.value": "mp4",
  "campaign.creative.size.label": "Taille max",
  "campaign.creative.size.value": "15mb",
  "campaign.creative.addFile": "Ajouter le fichier",
  "campaign.creative.upload.success": "Le téléchargement du fichier est terminé",
  "campaign.creative.upload.invalid": "Invalide",
  "campaign.creative.upload.criteria": "Le fichier ne correspond pas aux critères demandés",
  "campaign.creative.dropBox.first": "Cliquez ici pour choisir un file",
  "campaign.creative.dropBox.second": "Ou déposez-le ici",
  "campaign.summary.title": "Vos infos clés",
  "campaign.summary.name": "Nom de la campagne",
  "campaign.summary.date.begin": "Date début de la campagne",
  "campaign.summary.date.end": "Date de fin de la campagne",
  "campaign.summary.days": "Nombre de jours",
  "campaign.summary.details": "Offre sélectionnée",
  "campaign.summary.details.perimeter": "Périmètre",
  "campaign.summary.details.perimeter.giants.yes": "Ecrans géants inclus",
  "campaign.summary.details.perimeter.giants.no": "Ecrans géants exclus",
  "campaign.summary.details.perimeter.daypart.full": "Journée complète",
  "campaign.summary.details.perimeter.daypart.morning": "Jusqu’à 15h",
  "campaign.summary.details.perimeter.daypart.afternoon": "A partir de 15h",
  "campaign.summary.details.creativity": "Contenu",
  "campaign.summary.details.creativityStatus": "Contenu en cours de création",
  "campaign.summary.distribution": "Modalités de diffusion",
  "campaign.summary.distribution.screens": "Nombre d'écrans",
  "campaign.summary.distribution.frequence": "Fréquence moyenne",
  "campaign.summary.distribution.frequence/time.fr": "10 secondes/minute",
  "campaign.summary.distribution.frequence/time.be": "6 secondes/minute",
  "campaign.summary.distribution.spot": "Nombre de spots",
  "campaign.summary.distribution.contacts": "Contacts moyens estimés",
  "campaign.summary.distribution.campaignCost": "Coût campagne",
  "campaign.summary.distribution.advertiseCost": "Frais Adserving",
  "campaign.summary.distribution.creationCost": "Frais de creation",
  "campaign.summary.distribution.creationCost.free": "Offert",
  "campaign.summary.distribution.priceNet": "PRIX NET H.T.",
  "campaign.summary.distribution.tax": "Montant TVA",
  "campaign.summary.distribution.totalPrice": "PRIX TTC",
  "campaign.summary.disclaimer": "J'accepte les conditions générales de vente",
  "campaign.summary.panel.address": "Addresse",
  "campaign.summary.panel.digital": "Digital ID",
  "campaign.summary.panel.dimensions": "Dimensions",
  "campaign.summary.panel.resolution": "Résolution",
  "campaign.summary.panel.info.hover": "Veuillez survoler l'identifiant pour obtenir plus d'informations sur l'écran",
  "campaign.summary.promoCode.title": "Code promotion",
  "campaign.summary.promoCode.button": "Appliquer",
  "campaign.summary.promoCode.error.first": "Le code promotion",
  "campaign.summary.promoCode.error.second": "n’est pas valide",
  "campaign.summary.promoCode.error.used": "Le code promo a déjà été utilisé",
  "campaign.summary.promoCode.promotion": "Promotion",
  "campaign.summary.promoCode.saved": "Épargné",
  "campaign.summary.promoCode.saved.first": "Vous avez économisé ",
  "campaign.summary.promoCode.saved.second": "!",
  "campaign.summary.promoCode.saved.applied": "Code promo appliqué",
  "campaign.summary.promoCode.first": "Code Promo",
  "campaign.summary.promoCode.second": "utilisé",
  "campaign.summaryPay.dialogTitle": "Choix de paiement",
  "campaign.summaryPay.dialogTransfer": "Virement",
  "campaign.summaryPay.card": "Carte de crédit",
  "campaign.summaryPay.bancontact": "Bancontact",
  "campaign.summaryPay.dialogWarning": "Vous avez atteint la limite autorisée pour le paiement en ligne",
  "campaign.summaryPay.be.title.confirm": "Confirmer la campagne",
  "campaign.summaryPay.be.text": "En choisissant le mode de paiement, les espaces publicitaires demandés seront réservés. Vous serez redirigé vers la page de paiement.",
  "campaign.summaryPay.confirm": "Confirmer",
  "campaign.summaryPay.dialog.transfer.title": "Choix de paiement par virement",
  "campaign.summaryPay.dialog.transfer.confirm": "Je valide",
  "campaign.summaryPay.dialog.transfer.abort": "Annuler",
  "campaign.summaryPay.failedPayment": "Le paiement n'a pas été conclu. Veuillez contacter l'équipe d'assistance avec le chatbot ou l'adresse e-mail",
  "campaign.stripePay.title": "Payer par carte",
  "campaign.stripePay.infos": "Informations sur la carte",
  "campaign.stripePay.card.name": "Nom figurant sur la carte",
  "campaign.stripePay.error.text": "Une erreur est survenue dans la phase de paiement. Vérifiez votre connexion ou votre disponibilité",
  "campaign.stripePay.success.text": "Paiement effectué avec succès",
  "campaigns.menu.account": "Mon compte",
  "campaigns.menu.personal-info": "Infos personelles",
  "campaigns.menu.my-company": "Mon entreprise",
  "campaigns.menu.my-campaigns": "Mes campagnes",
  "campaigns.next-step": "Étape suivante",
  "campaigns.go-back": "Retour",
  "campaigns.slot-booked": "Aucune disponibilité pour cette campagne. Veuillez modifer vos critères de campagne",
  "campaigns.pay-close": "L'équipe commerciale vous contactera pour finaliser votre campagne",  
  "campaigns.error-title-1": "Le nom de la campagne est obligatoire",
  "campaigns.error-title-2": "Le nom de la campagne doit être unique",
  "label-adv-campaign.feedback-1": "Thanks for your booking!",
  "label-adv-campaign.feedback-2": "Your campaign will be validated and authorized by Clear Channel",
  "campaign-stripe.buy-now": "Payer",
  "campaign-stripe.name": "Nom",
  "campaign-stripe.name-placeholder": "Nom figurant sur la carte",
  "signin.title": "Créez votre compte et lancez facilement vos campagnes.",
  "signin.errMail": "Merci de renseigner un email valide",
  "signin.errPassword": "Merci de renseigner un mot de passe non vide",
  "signin.footerText": "Vous n'avez pas encore de compte et souhaitez promouvoir votre boutique?",
  "signin.goToSignup": "Créez votre compte",
  "signin.Error": "Veuillez fournir un nom d'utilisateur et un mot de passe corrects",
  "signin.Error.title": "Impossible de se connecter",
  "signin.Error.attempts": "Vous avez eu trop de tentatives infructueuses de connexion. Vous avez été temporairement verrouillé",
  "signin.Error.attempts.preMin": "",
  "signin.Error.attempts.postMin": "minutes restantes ",
  "signin.UserNotConfirmed.title": "Activation de compte",
  "signin.UserNotConfirmed.text": "Votre compte n'a pas encore été activé, procédez à l'activation",
  "signin.account.created": "Votre compte est actif et vous pouvez vous connecter pour lancer vos campagnes",
  "signup.title-1": "Création de compte",
  "signup.title-2": "Créer votre compte sur LaunchPAD Direct vous permet d'agrandir votre vitrine et de trouver de nouveaux clients!",
  "signup.contactinfo": "Infos personelles",
  "signup.name": "Prénom",
  "signup.surname": "Nom ",
  "signup.app_language": "Langue par défaut",
  "signup.email": "Adresse email",
  "signup.phone.prefix": "Pays",
  "signup.phone": "Numéro de téléphone",
  "signup.password1": "Mot de passe",
  "signup.password2": "Confirmation du Mot de passe",
  "signup.company-info": "Infos entreprise",
  "signup.companyName": "Nom d'entreprise",
  "signup.address": "Rue",
  "signup.codePostal": "Code Postal",
  "signup.city": "Ville",
  "signup.company_type": "Type d'entreprise",
  "signup.form_juridique": "Forme Juridique",
  "signup.siret_number": "Numéro de SIRET",
  "signup.company_number": "Numéro d'entreprise",
  "signup.code_naf": "Code NAF / APE",
  "signup.industry_type": "Secteur d'activité",
  "signup.tva_number": "Numéro de TVA intracommunautaire",
  "signup.mall":  "Shopping Mall",
  "signup.code": "Code",
  "signup.errMail": "Veuillez entrer un email valide",
  "signup.errPassword1":  "Votre mot de passe doit contenir au moins 12 caractères, des caractères alphanumériques, des majuscules et des minuscules etdes caractères spéciaux.",
  "signup.requiredError": "Le champ est obligatoire",
  "signup.errEmail": "Le format de l'email est incorrect",
  "signup.errPhone": "Le format du numéro de téléphone est incorrect",
  "signup.errZipCode": "Le code postal doit comporter à 5 chiffres",
  "signup.errZipCodeBe": "Le code postal doit comporter 4 chiffres",
  "signup.errTva": "Le numéro de TVA est incorrect",
  "signup.errSiret": "Le numéro de SIRET est incorrect",
  "signup.errCompanyNumber": "Le numéro d'entreprise doit comporter au moins 10 chiffres",
  "signup.errPassword": "Le format de le mot de passe est incorrect",
  "signup.errPassword2": "Les mots de passe ne correspondent pas",
  "signup.popperInfo":  "Votre mot de passe doit contenir au moins 12 caractères, des caractères alphanumériques, des majuscules et des minuscules etdes caractères spéciaux.",
  "signup.emptySelectValue": "Veuillez sélectionner...",
  "signup.submitButton": "Créer votre compte",
  "signup.registration-success":  "Inscription réussie",
  "signup.registration-error":  "Une erreur est survenue.",
  "signup.success": "Le compte est créé avec succès. Veuillez vérifier dans votre boîte, l'email de confirmation.",
  "signup.error": "Une erreur s'est produite lors de la phase d'inscription, veuillez réessayer",
  "signup.usernameExistsException": "L'adresse mail existe déjà",
  "signup.disc1Text": "J’ai lu et j’accepte les ",
  "signup.disc1Link": "Conditions d'utilisation",
  "signup.disc1Text2": "",
  "signup.disc2Text": "J’ai lu et j’accepte la ",
  "signup.disc2Link": "Politique en matière de Confidentialité et de Cookies",
  "signup.disc2Text2": "",
  "confirm.title": "Activation de compte",
  "confirm.success": "Merci pour votre inscription, veuillez vous rendre sur la page de connexion et saisir vos identifiants pour accéder à l'application.",
  "confirm.error": "Désolé, votre code d'activation n'est pas valide. Réessayer",
  "confirm.firstLoad": "Merci pour votre inscription, entrer le code envoyé par email",
  "confirm.verify": "Vérifier",
  "confirm.error.code": "Code invalide",
  "confirm.success.title": "Félicitations!",
  "confirm.success.text": "Compte confirmé",
  "confirm.gotologin": "Accéder à la page de connexion",
  "forgot-password.title-1": "Mot de passe oublié?",
  "forgot-password.title-2": "Entrez votre adresse e-mail et nous vous enverrons un lien de réinitialisation du mot de passe par e-mail.",
  "forgot-password.send": "Envoyer",
  "forgot-password.code-sent.title": "Code envoyé",
  "forgot-password.code-sent.text": "Vérifier votre boîte de réception",
  "forgot-password.code-sent.error": "Une erreur est survenue, veuillez réessayer",
  "forgot-password.code-sent.code.success": "Votre mot de passe a été changé",
  "forgot-password.code-sent.code.error": "Une erreur est survenue, vérifiez le code ou la connexion et réessayez",
  "msg-reset-account-ok": "Votre demande a été traitée. Un email a été envoyé à ",
  "msg-reset-account-mail-ko": "L'adresse email renseignée n'est pas valide",
  "msg-reset-account-user-not-found": "Adresse email non enregistrée",
  "msg-reset-account-user-email-body": "Votre nouveau mot de passe a été généré. N'oubliez pas de le changer lors de votre prochaine connexion",
  "error-change-password-3": "La longueur du mot de passe doit être comprise entre 12 et 50 caractères",
  "error-change-password-4": "L'ancien mot de passe spécifié est incorrect",
  "personal.info.title": "Mes infos personnelles",
  "personal.info.titlePassword": "Modifier votre mot de passe",
  "personal.info.passwordLabel1": "Mot de passe actuel",
  "personal.info.passwordLabel2": "Nouveau mot de passe",
  "personal.info.passwordLabel3": "Confirmer le mot de passe",
  "personal.info.popperInfo": "Votre mot de passe doit comporter au moins 12 caractères alphanumériques, une majuscule, une minuscule et au moins un caractère spécial.",
  "personal.info.btnSaveInfos": "Sauvegarder les modifications",
  "personal.info.btnPassword": "Modifier mon mot de passe",
  "personal.info.btnSavePassword": "Changer mon mot de passe",
  "personal.info.errEmail": "Le format de l'email est incorrect",
  "personal.info.errPassword": "Le format de le mot de passe est incorrect",
  "personal.info.success": "Vos informations personnelles ont bien été mises à jour",
  "personal.editPassword.success": "Votre mot de passe a bien été mis à jour",
  "company.data.title": "Les données de mon entreprise",
  "company.data.success": "Les informations de votre entreprises ont bien été mises à jour",
  "error-campaign-slot-booked": "Les dates de la campagnes ne sont plus disponibles. Merci de modifier les dates de votre campagnes",
  "legalLinks.legals": "Mentions Légales",
  "legalLinks.generals": "Conditions générales d’utilisation",
  "legalLinks.cookies": "Politique de Confidentialité et de Cookies",
  "legalLinks.report": "Signaler des problèmes graves",
  "cookies.text": "En cliquant sur \"J’accepte\", vous acceptez que des cookies soient stockés sur votre appareil afin d'améliorer la navigation sur le site, d'analyser son utilisation et de contribuer à nos efforts de marketing.",
  "cookies.more": "Politique de Confidentialité & Cookies",
  "cookies.accept": "J'accepte",
  "cookies.refuse": "Je refuse",
  "notificationManager.connectionError.text.postLogin": "Erreur de connexion, veuillez vous reconnecter",
  "notificationManager.connectionError.text": "Erreur de connexion",
  "notificationManager.connectionError.text.wait": "Erreur de connexion, attente de la connexion",
  "notificationManager.connectionError.401.title": "Session expirée",
  "notificationManager.connectionError.401.text": "Session expirée, reconnectez-vous",
  "notificationManager.availabilitiesError.text": "Les dates sélectionnées pour la campagne ne sont pas disponibles, veuillez sélectionner de nouvelles dates",
  "notificationManager.availabilitiesError.title": "Pas de disponibilité",
  "modal.confirm.back-summary.text": "If you proceed, a new campaign will be initialized",
  "modal.confirm.back.text": "Si vous continuez, une nouvelle campagne sera initialisée",
  "modal.confirm.back.confirm": "Confirmer",
  "modal.confirm.back.cancel": "Annuler",
  "modal.confirm.back.warning": "Avertissement",
  "notificationManager.configurationError.text": "Cette campagne n'est plus disponible pour le moment, veuillez modifier les critères de campagne",
  "notificationManager.configurationError.title": "Erreur",
  "notificationManager.stationDisabledError.text": "Cette gare n'est plus disponible pour le moment. Veuillez nous excuser pour le dérangement",
  "notificationManager.stationDisabledError.title": "Pardon",
  "notificationManager.GloohError.text": "Une erreur avec Glooh s'est produite",
  "notificationManager.GloohError.title": "Erreur Glooh",
  "infoWindow.button": "Voir",
  "MFA.next": "Suivant",
  "MFA.submit": "Soumettre",
  "MFA.firstStep.title": "Configuration de l'authentification multi-facteurs",
  "MFA.firstStep.label": "Cher utilisateur, pour renforcer la sécurité de votre compte, veuillez compléter cette configuration MFA.",
  "MFA.firstStep.secondLabel": "Si vous n'avez pas d'application d'authentification sur votre téléphone",
  "MFA.firstStep.thirdLabel": "(Google Authenticator, Authy, Microsft Authenticator, etc...)",
  "MFA.firstStep.fourthLabel": "Veuillez en installer un à partir de l'App Store ou du Play Store avant de poursuivre.",
  "MFA.secondStep.title": "Scannez le code QR avec votre application d'authentification.",
  "MFA.secondStep.secretCodeInfo": "Si vous ne pouvez pas scanner le code QR, cliquez ici pour afficher le code secret à saisir dans votre application d'authentification.",
  "MFA.secondStep.label": "Si vous avez enregistré correctement le code, veuillez continuer.",
  "MFA.thirdStep.title": "Saisissez un code MFA valide provenant de votre application pour terminer la configuration.",
  "MFA.showQR": "Cliquer pour afficher",
  "MFA.invalidSubmitCode.text": "Le code saisi n'est pas valide. Veuillez réessayer.",
  "MFA.invalidSubmitCode.title": "Code incorrect",
  "MFA.errorActivation.text": "Échec de l'activation de l'AMF. Veuillez réessayer.",
  "MFA.successActivation.title": "MFA Activée",
  "MFA.successActivation.text": "La configuration de l'AMF a été effectuée avec succès !",
  "MFA.loginDialog.title": "Authentification multi-facteurs",
  "MFA.loginDialog.text": "Ouvrez votre application d'authentification et entrez un code MFA valide pour terminer la connexion."
}
export default messages; 