import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/authContext'
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import Nprogress from 'nprogress';
import config from '../../config';
import backgroundImage from '../../assets/Infopages.png'
import CircleProgress from '../../util/CircleProgress';
import { useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

//regex e funzioni di controllo per conformità dati
const regexTvaNumber = /^[Ff][Rr](?=\d{11}$)[-_ 0-9]*(?:[a-zA-Z][-_ 0-9]*){0,2}$/;
const regexVatNumberBe = /^[Bb][Ee][0](?=\d{9}$)[-_ 0-9]*(?:[a-zA-Z][-_ 0-9]*){0,2}$/;
const regexPhoneValidator = /^[+ 0-9]*$/;
const regexNumbers = /^[0-9]*$/;
//const regexZipCodeBeSuffix = /^[0-9]{0,4}[Bb]*$/;

const checkCodePostal = (value, country) => {
    if(country==='1'){
        return (value.length!==5)
    }else{
        return (value.length!==4)
    }
}    

const checkSiret = (value) => {
    return (value.length !== 14)
}

const checkTva = (value, siret) => {
    let siren = siret.substring(0, 9);  
    if (siren === (value.toString().substring(4))){
        return (regexTvaNumber.test(value))
    }else {
        return false;
    }
};

//estraggo dai json i dati per le datalists
let autoCompleteLists = {
    company_type: [...require('../auth/SignUp/companytypeFR.json')],
    form_juridique: [...require('../auth/SignUp/formejuridique.json')],
    code_naf: [...require('../auth/SignUp/codesnaf.json')],
    industry_type_en: [...require('../auth/SignUp/industrytypeEN.json')].sort((a, b) => a.Code < b.Code ? -1 : (a.Code > b.Code ? 1 : 0 )),
    industry_type_fr: [...require('../auth/SignUp/industrytypeFR.json')].sort((a, b) => a.Code < b.Code ? -1 : (a.Code > b.Code ? 1 : 0 )),
    industry_type_nl: [...require('../auth/SignUp/industrytypeNL.json')].sort((a, b) => a.Code < b.Code ? -1 : (a.Code > b.Code ? 1 : 0 )),
    legal_status_BE_en: [...require('../auth/SignUp/legalstatusbeEN.json')].sort((a, b) => a.Code < b.Code ? -1 : (a.Code > b.Code ? 1 : 0 )),
    legal_status_BE_fr: [...require('../auth/SignUp/legalstatusbeFR.json')].sort((a, b) => a.Code < b.Code ? -1 : (a.Code > b.Code ? 1 : 0 )),
    legal_status_BE_nl: [...require('../auth/SignUp/legalstatusbeNL.json')].sort((a, b) => a.Code < b.Code ? -1 : (a.Code > b.Code ? 1 : 0 )),
  };

const CompanyData = (props) =>{

    const [loading, setLoading] = useState(true);
    const [country, setCountry] = useState("");
    //stati da settare con valori della chiamata al db
    const [nomEntrprise,    setNomEntrprise     ] = useState("");
    const [rue,             setRue              ] = useState("");
    const [codePostal,      setCodePostal       ] = useState("");
    const [ville,           setVille            ] = useState("");
    const [typeDentreprise, setTypeDentreprise  ] = useState("");
    const [formJuridique,   setFormJuridique    ] = useState("");
    const [numeroSiret,     setNumeroSiret      ] = useState("");
    const [codeNafApe,      setCodeNafApe       ] = useState("");
    const [numeroTva,       setNumeroTva        ] = useState("");

    //dati inseriti negli input / controlli conformità
    const [codePostalCheck, setCodePostalCheck] = useState(true);
    const [numeroSiretCheck, setNumeroSiretCheck] = useState(true);
    const [numeroTvaCheck, setNumeroTvaCheck] = useState(true);

    //stati contenenti gli altri campi ricevuti da db da ripassare nella post
    const [defaultLanguage , setDefaultLanguage ] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const auth = useContext(AuthContext)
    const history = useHistory();

    const {intl} = props;

    const translate = (label) => intl.formatMessage({id: label});

    useEffect(() => {
        async function init(){
            const url = `${config.API_URL}/profile`;
            
            await axios.get(
                url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    }   
                }
            ).then((response) => {
                const data = response.data;
                if(!data.COMPANY_TVA){
                    data.COMPANY_TVA = '';
                }
                setNomEntrprise   (data.COMPANY_NAME);
                setRue            (data.ADDRESS);
                setCodePostal     (data.ZIPCODE);
                setVille          (data.CITY);
                setTypeDentreprise(data.COMPANY_TYPE);
                setFormJuridique  (data.COMPANY_LEGAL_FORM);
                setNumeroSiret    (data.COMPANY_SIRET || '');
                setCodeNafApe     (data.COMPANY_NAF);
                setNumeroTva      (data.COMPANY_TVA);
                setName           (data.NOME);
                setSurname        (data.COGNOME);
                setEmail          (data.EMAIL_ADDRESS);
                setPhone          (data.PHONE_NUMBER);
                setDefaultLanguage(data.PREFERRED_LANGUAGE_COD);
                setCountry        (data.LISTA_KCOUNTRY);
            }, (error) => {
                if(error.response.status === 401){ //status 401: Unauthorized
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                    auth.signOut();
                    history.push('/signin');
                }else if(error.response.status ===500){
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 5000);
                }else{
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 5000);
                }
            });
            setLoading(false)
        }
        init();
    }, [auth, history]);

    const checkTvaBe = (tva) => {
        if(regexVatNumberBe.test(tva)){
            return true;
        }else{
            return false;
        }
    }
    
    //mentre effettuo il cambio del valore, controllo la conformità di quello che inserisce l'utente ed eventualmente blocco il bottone di submit
    const handleChange = async(event) => {

        if(country === '2'){

            if((event.target.name === 'codePostal' || event.target.name === 'siret') && !regexNumbers.test(event.target.value)){
                event.preventDefault();
                return false;
            }

            /* eliminabile if((event.target.name === 'codePostal') && !regexZipCodeBeSuffix.test(event.target.value)){
                event.preventDefault();
                return false;

            }else if((event.target.name === 'siret') && !regexNumbers.test(event.target.value)){
                event.preventDefault();
                return false;
            } */

        }else{

            if((event.target.name === 'codePostal' || event.target.name === 'siret') && !regexNumbers.test(event.target.value)){
                event.preventDefault();
                return false;
            }
        
        }

        if(event.target.name === 'phone' && !regexPhoneValidator.test(event.target.value)){
            event.preventDefault();
            return false;
        }
        
        switch(event.target.name){
            case 'nomEntreprise':
                setNomEntrprise(event.target.value)
                break;
            case 'rue':
                setRue(event.target.value)
                break;
            case 'codePostal':
                setCodePostal(event.target.value)
                !checkCodePostal(event.target.value, country) ? setCodePostalCheck(true) : setCodePostalCheck(false)
                break;
            case 'ville':
                setVille(event.target.value)
                break;
            case 'typeDentreprise':
                setTypeDentreprise(event.target.value)
                break;
            case 'formeJuridique':
                setFormJuridique(event.target.value)
                break;
            case 'siret':
                setNumeroSiret(event.target.value)
                !checkSiret(event.target.value) ? setNumeroSiretCheck(true) : setNumeroSiretCheck(false);
                break;
            case 'codeNafApe':
                setCodeNafApe(event.target.value)
                break;
            case 'tva':
                setNumeroTva(event.target.value)
                if(country === '1'){
                    checkTva(event.target.value, numeroSiret) ? setNumeroTvaCheck(true) : setNumeroTvaCheck(false)
                }else if(country === '2'){
                    checkTvaBe(event.target.value) ? setNumeroTvaCheck(true) : setNumeroTvaCheck(false)
                }
                break;
            default:
            break;
        }
        //Se il numero tva è vuoto lo posso passare lo stesso non essendo obbligatorio ma solo in francia
        if(country === '1'){
            if(event.target.name === 'tva' && event.target.value === ''){
                setNumeroTvaCheck(true)
            }
        }
    }

    //submit
    const handleSubmit = (event) => {
        event.preventDefault();

        Nprogress.start();

        const params = {
            companyName: nomEntrprise,
            city: ville,
            zipCode: codePostal,
            address: rue,
            companyType: typeDentreprise,
            companyLegalForm: formJuridique,
            siretNumber: numeroSiret,
            nafCode: codeNafApe,
            tvaNumber: numeroTva,
            surname: surname,
            name: name,
            phone: phone,
            eMailAddress: email,
            defaultLanguage,
            country,
            action: '2'
        };

        const url = `${config.API_URL}/profile-mod`;

        axios.post(
            url,
            null,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params
            }
            ).then((response) => {
                Nprogress.done();
                NotificationManager.success(<FormattedMessage id={"mod.success"}/>, <FormattedMessage id={"confirm.success.title"}/>, 5000 );
            }, (error) => {
                if(error.response.status === 401){ //status 401: Unauthorized
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                    setLoading(true);
                    auth.signOut();
                    history.push('/signin');
                }else if(error.response.status ===500){
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 5000);
                }else{
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 5000);
                }
                Nprogress.done();
            });
    }

    // funzione per generare le options per i campi a tendina (i vecchi autocomplete con datalists)
    const createSelectOptions = (campo, nomeCampo) => {
        return Object.values(campo).map( (json) => {
            //essendo i company type solo 4 filtro l'array in modo che si formino le option con il formato corretto per quel campo
            if(campo.length < 5){
                return( <option key={[ json.Code ]} value={[ json.Code ]}>{[ json.Description ]}</option>)
            }else{
                return( <option key={[ json.Code ]} value={nomeCampo==='legal_status_BE' ? [json.Cod] : [ json.Code ]}>{[ json.Code ] + ' - ' + [ json.Description]}</option>) 
            }
        });
    }

    const frDisable=( !nomEntrprise || !rue || !codePostalCheck || !ville || !typeDentreprise || !formJuridique || !numeroSiretCheck || !codeNafApe || !numeroTvaCheck) ? true : false;

    const beDisable=( !nomEntrprise || !rue || !codePostalCheck || !ville || !formJuridique || ((numeroSiret.length!==(10))&&numeroSiret.length!==(0)) || !codeNafApe || !numeroTvaCheck) ? true : false;

    if(loading){
        return(<CircleProgress/>);
    }else{
    return(
        <div>
            <form noValidate className="margin-form" onSubmit={handleSubmit}>
                    <div className="grid main-content">
                        <div className="col-desk-12 col-tab-8 col-mob-4 no-gutter layout-align-self-center">
                        <div className="section-layout--divide-view margin-bottom--08">
                            <h1>
                                <FormattedMessage id='company.data.title'/>
                            </h1>
                            <div className="input-container margin-top--05">
                                <label className="label" htmlFor="nomEntreprise"><FormattedMessage id='signup.companyName'/> *</label>
                                <div className="row">
                                    <input className="input" data-cy="nomEntreprise" data-testid="nomEntreprise" type="text" name="nomEntreprise" required="" maxLength={50} disabled="" value={nomEntrprise} onChange={handleChange}/>
                                </div>
                            </div>

                            <div className="input-container">
                                <label className="label" htmlFor="rue"><FormattedMessage id='signup.address'/> *</label>
                                <div className="row">
                                    <input className="input" data-cy="rue" data-testid="rue" type="text" name="rue" required="" maxLength={50} disabled="" value={rue} onChange={handleChange}/>
                                </div>
                            </div>

                            <div className="input-container">
                                <label className="label" htmlFor="codePostal"><FormattedMessage id='signup.codePostal'/> *</label>
                                <div className="row">
                                    <input className="input" data-cy="codePostal" data-testid="codePostal" type="text" name="codePostal" required="" maxLength={country==='1' ? 5 : 4} disabled="" value={codePostal} onChange={handleChange}/>
                                </div>
                                {!codePostalCheck && country==='1' && (<span className="message--error"><FormattedMessage id='signup.errZipCode'/></span>)}
                                {!codePostalCheck && country==='2' && (<span className="message--error"><FormattedMessage id='signup.errZipCodeBe'/></span>)}
                            </div>

                            <div className="input-container">
                                <label className="label" htmlFor="ville"><FormattedMessage id='signup.city' /> *</label>
                                <div className="row">
                                    <input className="input" data-cy="ville" data-testid="ville" type="text" name="ville" required="" maxLength={50} disabled="" value={ville} onChange={handleChange}/>
                                </div>
                            </div>

                            {country==='1'&&(<div className="input-container">
                                <label className="label" htmlFor="typeDentreprise"><FormattedMessage id='signup.company_type'/> *</label>
                                <span className="inputIcon dropdown">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"></path>
                                        </g>
                                    </svg>
                                    <select className="select" id="typeDentreprise" name="typeDentreprise" required="" value={typeDentreprise} onChange={handleChange}>
                                        {createSelectOptions(autoCompleteLists.company_type, 'companytype')}
                                    </select> 
                                </span>
                            </div>)}
                                
                            {country==='1'&&(<div className="input-container">
                                <label className="label" htmlFor="formeJuridique"><FormattedMessage id='signup.form_juridique'/> *</label>
                                <span className="inputIcon dropdown">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"></path>
                                        </g>
                                    </svg>
                                    <select className="select" id="formeJuridique" name="formeJuridique" required="" value={formJuridique} onChange={handleChange}>
                                        {createSelectOptions(autoCompleteLists.form_juridique, 'formejuridique')}
                                    </select>  
                                </span>
                            </div>)}

                            {country==='2'&&(<div className="input-container">
                                <label className="label" htmlFor="legalStatus"><FormattedMessage id='signup.form_juridique'/> *</label>
                                <span className="inputIcon dropdown">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"></path>
                                        </g>
                                    </svg>
                                    <select className="select" id="legalStatus" name="formeJuridique" required="" value={formJuridique} onChange={handleChange}>
                                        {createSelectOptions(autoCompleteLists['legal_status_BE_'+defaultLanguage], 'legal_status_BE')}
                                    </select>  
                                </span>
                            </div>)}

                            <div className="input-container">
                                <label className="label" htmlFor="siret">{country==='1'? translate('signup.siret_number') + ' *':<FormattedMessage id='signup.company_number'/>}</label>
                                <div className="row">
                                    <input className="input" data-cy="siret" data-testid="siret" type="text" name="siret" required="" maxLength={country==='1'?14:10} disabled="" value={numeroSiret} onChange={handleChange}/>
                                </div>
                                {!numeroSiretCheck && country==='1' && (<span className="message--error"><FormattedMessage id='signup.errSiret'/></span>)}
                                {(numeroSiret.length!==0 && numeroSiret.length!==10) && country==='2' && (<span className="message--error"><FormattedMessage id='signup.errCompanyNumber'/></span>)}
                            </div>


                            {country==='1'&&(<div className="input-container">
                                <label className="label" htmlFor="codeNafApe"><FormattedMessage id='signup.code_naf'/> *</label>
                                <span className="inputIcon dropdown">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"></path>
                                        </g>
                                    </svg>
                                    <select className="select" id="codeNafApe" name="codeNafApe" required="" value={codeNafApe} onChange={handleChange}>
                                        {createSelectOptions(autoCompleteLists.code_naf, 'code_naf')}
                                    </select> 
                                </span>
                            </div>)}

                            {country==='2'&&(<div className="input-container">
                                <label className="label" htmlFor="codeNafApe"><FormattedMessage id='signup.industry_type'/> *</label>
                                <span className="inputIcon dropdown">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"></path>
                                        </g>
                                    </svg>
                                    <select className="select" id="codeNafApe" name="codeNafApe" required="" value={codeNafApe} onChange={handleChange}>
                                        {createSelectOptions(autoCompleteLists['industry_type_'+defaultLanguage], 'industry_type')}
                                    </select> 
                                </span>
                            </div>)}
                            <div className="input-container">
                                <label htmlFor="tva"><FormattedMessage id='signup.tva_number'/> {country==='1'? '':'*'}</label>
                                <div className='row'>
                                    <input className="input" data-cy="tva" data-testid="tva" type="text" name="tva" required="" maxLength="" disabled="" value={numeroTva} onChange={handleChange}/>
                                </div>
                                {country==='1' && ((numeroTva !== '') ? (numeroTva.substring(4) !== numeroSiret.substring(0,9)) && (<span className="message--error"><FormattedMessage id='signup.errTva'/></span>) : '')}
                                {country==='2' && ((numeroTva !== '') ? (numeroTvaCheck ? '': (<span className="message--error"><FormattedMessage id='signup.errTva'/></span>)) : (<span className="message--error"><FormattedMessage id='signup.errTva'/></span>))}
                            </div>
                            <div className="buttonline">
                                <button className="button--primary-large" id="saveChanges" data-cy="saveChanges" type="submit" disabled={country==='1'?frDisable:beDisable} ><FormattedMessage id='personal.info.btnSaveInfos'/></button>
                            </div>

                        </div>
                        </div>
                        <div
                            className="col-desk-12 col-tab-4 featured-image"
                            style={{ backgroundImage: `url(${backgroundImage})` }}
                        ></div>
                 </div>
            </form>
        </div>
    )}

}
export default injectIntl(CompanyData);