import {useState, useEffect} from 'react';

function WindowResizeHook() {
  const [screenSize, setScreenSize]:any = useState(window.innerWidth);

  useEffect(() => {
      window.addEventListener("resize", () => {
          setScreenSize(window.innerWidth);
      });
      return () => {
          window.removeEventListener("resize", () => {
            setScreenSize(window.innerWidth);
          })
      }
  }, []);

  return (screenSize <= 768);
}
export default WindowResizeHook;