import React, { useState, useEffect, useContext }   from 'react';
import axios                                                        from 'axios';
import { FormattedMessage, injectIntl }                             from 'react-intl';
import NavigateNextIcon                                             from '@mui/icons-material/NavigateNext';
import { useHistory }                                               from 'react-router-dom';
import config                                                       from '../../config';
import { AuthContext }                                              from '../../contexts/authContext';
import { NotificationManager }                                      from 'react-notifications';
import './LocationSelection.css'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Search32 }   from '@carbon/icons-react';

const Map = (props) =>{
    const ref = React.useRef(null);
    const [map, setMap] = React.useState(null);
    const [onOpenCoords, setOnOpenCoords] = useState(props.coords)
    const [locations, setLocations] = useState(props.locationList);
    const [mapZoom, setMapZoom] = useState(null);
    const [arrayMarkers, setArrayMarkers] = useState([]);
    const [arrayCircles, setArrayCircles] = useState([]);
    const [selectedLocationCoords, setSelectedLocationCoords] = useState({});
    const [selectedLocationZoom, setSelectedLocationZoom] = useState(null);

    useEffect(() => {
        //triggherata di default ad ogni selezione di una stazione dalle rows
        setMap(new window.google.maps.Map(ref.current, {
            zoom: props.zoom,
            center: props.coords,
            heigth:'100%',
            streetViewControl: false,
        }));
    }, [props.coords]);

    useEffect(() => {
        //triggherato solo se viene cliccato su un marker poichè varia selectedLocationCoords
        //controllo per evitare che questo setMap venga lanciato al caricamento del componente
        if(Object.keys(selectedLocationCoords).length!==0){
            setMap(new window.google.maps.Map(ref.current, {
                zoom: selectedLocationCoords.zoom ,
                center:  selectedLocationCoords.coords,
                heigth:'100%',
                streetViewControl: false,
            }));
        }
    }, [selectedLocationCoords]);

    useEffect(() => {
        if(map!==null){
            //controllo del livello di zoom
            window.google.maps.event.addListener(map, 'zoom_changed', function() {
                let zoomLevel = map.getZoom();
                setMapZoom(zoomLevel);
            });
            //richiesta della geolocalizzazione appena si entra nella pagina poichè map vale null
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                            new window.google.maps.Marker({
                                position: pos,
                                map: map,
                                title: 'Your position',
                                icon: ''//'http://www.robotwoods.com/dev/misc/bluecircle.png'
                            });
                            new window.google.maps.Circle({
                            strokeColor: "#006AE3",
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: "#006AE3",
                            fillOpacity: 0.15,
                            map: map,
                            center: pos,
                            radius: 300,
                          });
                        
                    },
                    () => {
                    //console.log('errore geo')
                    }
                );
            } else {
              // Browser doesn't support Geolocation
              //console.log('errore geolocation')
            }
            let marchi = [];
            let cerchi = [];
            let marker = '';
            let circle = '';
            let zoomLevel = map.getZoom();
            locations.forEach(location => {  
                marker = new window.google.maps.Marker({
                    position: {lat: location.LOCATION_LATITUDE, lng: location.LOCATION_LONGITUDE},
                    map: map,
                    title: location.LOCATION_DESC,
                    opacity: zoomLevel>=17 ? 0 : 1,
                    locationId: location.KLOCATION,
                    radius: location.LOCATION_RADIUS
                })
                circle = new window.google.maps.Circle({
                    strokeColor: zoomLevel>=17 ? '#006AE3' : '#FFFFFF00',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: zoomLevel>=17 ? '#006AE3' : '#FFFFFF00',
                    fillOpacity: 0.15,
                    map: map,
                    center: {lat: location.LOCATION_LATITUDE, lng: location.LOCATION_LONGITUDE},
                    radius: location.LOCATION_RADIUS,
                  })
                marchi.push(marker);
                cerchi.push(circle);
                }
            );
            
            marchi.forEach(marker => {
                //console.log('marker', marker)
                marker.addListener('click', (currentMarker) => {
                    //console.log('marker', currentMarker.latLng.lat());
                    //console.log('orcozio', marker);
                    //console.log('orcozio', currentMarker);
                    setSelectedLocationCoords({coords:{lat: currentMarker.latLng.lat(), lng: currentMarker.latLng.lng()}, zoom: 17});
                    /* setSelectedLocationZoom(17); */
                    /* id, lat, lng, zoom, radius, locationDesc */
                    props.setSelectedLocation(marker.locationId.toString(), currentMarker.latLng.lat(), currentMarker.latLng.lng(), 17, marker.radius, marker.title);
                })
            });

            setArrayMarkers(marchi);
            setArrayCircles(cerchi);
        }
    }, [map]);
    //006AE3
    useEffect(()=>{
        if (mapZoom >= 17){
            arrayMarkers.forEach( marker => {
                marker.opacity = 0;
            })
            arrayCircles.forEach( circle => {
                circle.setOptions({strokeColor: '#006AE3', fillColor: '#006AE3'});
            })
        }else if (mapZoom < 17){
            arrayMarkers.forEach( marker => {
                marker.opacity = 1;
            })
            arrayCircles.forEach( circle => {
                circle.setOptions({strokeColor: '#FFFFFF00', fillColor: '#FFFFFF00'});
            })
        }
    },[mapZoom])

    return (
        <div className='map-size' ref={ref}></div>
    )
}

const LocationSelection = () => {
    const [loading, setLoading] = useState(true);
    const [country, setCountry] = useState( localStorage.getItem('country') );
    const [selectedLocation, setSelectedLocation] = useState(undefined);
    const [zoom, setZoom] = useState(8);
    const [radius, setRadius] = useState(0);
    //coordinate di default che puntano su bruxelles
    const [coords, setCoords] = useState({lat: 50.687818398570055, lng: 4.34245660004223});
    const [page, setPage] = useState(0);
    const [valueToIncrement, setValueToIncrement] = useState(5)
    const [minRow, setMinRow] = useState(5);
    const [latestMinRow, setLatestMinRow] = useState(0);
    const [maxRow, setMaxRow] = useState(0);
    const [locations, setLocations] = useState([]);
    const auth = useContext(AuthContext);
    const history = useHistory();
    const [panels, setPanels] = useState([]);
    const [searchedLocation, setSearchedLocation] = useState('')
    const [filteredLocations, setFilteredLocations] = useState([]); 

    useEffect(() => {

        async function init(){
            //ogni volta che si arriva su questa pagina VIENE effettuato un reset
            //di tutte le impostazioni di una campagna
            localStorage.removeItem('kCampaign');
            localStorage.removeItem('campaignName');
            //nel caso in cui l'utente usi il back del browser per cambiare la scelta della stazione
            //si resetta il valore del floor per caricare sempre di default il package 0
            localStorage.removeItem('floor');
            localStorage.removeItem('giant');
            localStorage.removeItem('allFrames');
            localStorage.removeItem('daypart');
            localStorage.removeItem('beginDate');
            localStorage.removeItem('endDate');
            localStorage.removeItem('gloohCreativeId');
            localStorage.removeItem('gloohBriefId');
            localStorage.removeItem('gloohRendered');
            localStorage.removeItem('priceMedia');
            localStorage.removeItem('priceAdv');
            localStorage.removeItem('priceTva');
            localStorage.removeItem('priceTtc');        
            localStorage.removeItem('price');
            localStorage.removeItem('framesSelected');
            localStorage.removeItem('days');
            localStorage.removeItem('numberOfSpot');
            localStorage.removeItem('slotFree');
            localStorage.removeItem('slotSelected');
            localStorage.removeItem('estimatedAvgContacts');
            localStorage.removeItem('showAudience');
            localStorage.removeItem('listaKLocation');
            localStorage.removeItem('locationName');
            localStorage.removeItem( 'packageName' );
            localStorage.removeItem( 'floorName' );
            localStorage.removeItem( 'panelsSelected');
            localStorage.removeItem( 'groupList' );
            localStorage.removeItem( 'framesLevels');
            localStorage.removeItem( 'userBasket' );
            localStorage.removeItem('campaignRejected');
            localStorage.removeItem('panelsList');
            localStorage.removeItem('customCreativeId');
            localStorage.removeItem('gloohBriefIdForPreview');
            localStorage.removeItem('gloohCreativeIdForPreview');
            localStorage.removeItem('promoCodeDiscount');
            localStorage.removeItem('originalPrice');
            localStorage.removeItem('originalPriceTva');
            localStorage.removeItem('originalPriceTtc');
            
            let params = {
                kCountry: country
            }
        
            let url = `${config.API_URL}/locations`;

            let response = '';

            try {
                response = await axios.get(
                    url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                        },
                        params  
                    }
                )
                if(response.status === 200){
                    //console.log('locations',response.data)
                    setLocations(response.data);
                    setMaxRow(response.data.length);
                }else{
                    history.push('/connection-error');
                    return;
                }
            } catch (error) {
                //console.log(error)
                if (!error.response){
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 0);
                    history.push('/connection-error');
                    return;
                }else if(error.response.status === 401){ //status 401: Unauthorized
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                    auth.signOut();
                    history.push('/signin');
                    return;
                }else if(error.response.status ===500){//nelle chiamate in cui bisogna recuperare informazioni critiche per il corretto caricamento della pagina, in caso di errore, l'utente viene indirizzato alla pagina 'connection-error'
                    history.push('/connection-error');
                    return;
                }else{
                    history.push('/connection-error');
                    return;
                }
            }
            setLoading(false);
        }
        init();
    }, []);

    const chosenLocation = async(id, lat, lng, zoom, radius, locationDesc) =>{
        //prevenzione del click continuo sulla stessa location per evitare un bug con la creazione del cerchio
        if(id!==selectedLocation){
            //console.log('zoom', zoom)
            setSelectedLocation(id);
            setZoom(zoom);
            setCoords({
                lat: lat,
                lng: lng
            });
            setRadius(radius);         
            localStorage.setItem('listaKLocation', id);
            localStorage.setItem('locationName', locationDesc);
        }
    }

    const changePage = (direction) => {
        
        if(direction==='forward'){
            //console.log('direction', direction);
            setPage(page+1)
        }else{
            //console.log('direction', direction);
            setPage(page-1)
        }

    };

    const createLocationsRows = () => {
        /* se l'input per la ricerca è valorizzato allora prendo in considerazione l'array filtrato
        e, se questo è vuoto allora ritorno una cella con "no matches". Invece se nessun valore 
        è presente nell'input allora mostro l'array originale */ 
        let list = !!searchedLocation ?
            filteredLocations.length > 0 ?
                filteredLocations : null
            : locations;

        //console.log('list', list)
        if(!list){
            return <div className='no-results'> <FormattedMessage id={"location-selection.table.noRows"}/> </div>
        }else{
            /* if(list.length < 5){
                for(let i=0; i<valueToIncrement-list.length; i++){
                    list.push({KLOCATION: '', LOCATION_LATITUDE:'', LOCATION_LONGITUDE:'', LOCATION_ZOOM:'', LOCATION_RADIUS:'', LOCATION_DESC:'', LOCATION_CC_PANELS:''})
                }
            } */
            return list.map((location => {
                return(
                    <div className={`loc-row location ${selectedLocation===location.KLOCATION.toString() ? 'selected' : ''} ${location.KLOCATION.toString()!=='' ? '' : 'empty'}`}
                        id={location.KLOCATION.toString()}
                        key={location.KLOCATION}
                        onClick={() =>{if(location.KLOCATION!==''){chosenLocation(location.KLOCATION.toString(), location.LOCATION_LATITUDE, location.LOCATION_LONGITUDE, location.LOCATION_ZOOM, location.LOCATION_RADIUS, location.LOCATION_DESC)}}}>
                        {location.KLOCATION!==''&&(
                        <>
                        <h4 className="h4">{location.LOCATION_DESC}</h4>
                        <p className="paragraph margin-bottom--00">{location.LOCATION_CC_PANELS || 'Panels'} <FormattedMessage id={"location-selection.table.panels"}/></p>
                        </>)}
                    </div>)
                })
            ).slice((page*valueToIncrement), ((page+1)*valueToIncrement))
        }
    };

    const searchLocation = (event) => {
        let slocation = event.target.value.toLowerCase().replaceAll(' ', '');
        //console.log('stringa di ricerca', slocation);
        setSearchedLocation(slocation);
    };

    useEffect(() => {
        if(searchedLocation){
            //resetto la paginazione ad ogni valore digitato
            let newFilteredArray = locations.filter(location => location.LOCATION_DESC.toLowerCase().replaceAll(' ', '').includes(searchedLocation)).map(a => {return {...a}});
            //console.log([...newFilteredArray])
            //locations.forEach(lll => console.log(lll))

            setFilteredLocations([...newFilteredArray]);
            setPage(0);
        }else{
            setPage(0);
            setFilteredLocations([]);
        }
    },[searchedLocation]);

    const countriesArray = config.COUNTRIES_LABELS;
    let urlCountry = countriesArray[country-1];
    const gotoYourCampaign = () => {
        history.push(`/${urlCountry}/your-campaign/new`);
    }

    const gotoMyCampaigns = () => {
        history.push(`/my-campaigns`);
    };

    if(loading){
        return (<div></div>)
    }else{
        return (
            <div>
                <header className="heading">
                    <div className="heading-content">
                        <h1 className="h1"><FormattedMessage id={'your-campaigns.newCampaign'}/></h1>
                        <div className="breadcrumb margin-top--05">

                            <button className="breadcrumb--item active default-curs" type='button'>
                                <span className='step-number'>1</span>
                                <FormattedMessage id={"breadcrumb.stepLocation"}/>
                            </button>

                            <span className="next-step">
                                <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                            </span>

                            <button className="breadcrumb--item disabled default-curs" type="button">
                                <span className='step-number'>2</span>
                                <FormattedMessage id={"breadcrumb.step1"}/>
                            </button>

                            <span className="next-step">
                                <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                            </span>

                            <button className="breadcrumb--item disabled default-curs" type="button">
                                <span className='step-number'>3</span>
                                <FormattedMessage id={"breadcrumb.step2"}/>
                            </button>

                            <span className="next-step">
                                <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                            </span>

                            <button className="breadcrumb--item disabled default-curs" type="button">
                                <span className='step-number'>4</span>
                                <FormattedMessage id={"breadcrumb.step3"}/>
                            </button>

                            <span className="next-step">
                                <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                            </span>

                            <button className="breadcrumb--item disabled default-curs" type="button">
                                <span className='step-number'>5</span>
                                <FormattedMessage id={"breadcrumb.step4"}/>
                            </button>
                        </div>
                    </div>
                </header>
                <section className="section-layout main-content">
                    <div className="wrapper">
                        <div className="location-table card__container flex-row">
                            <div className='table-fixed max-wide'>
                                <div className='search-bar'>
                                    <input className='search-input' onChange={searchLocation} placeholder="Search "/>
                                    <Search32 aria-label="Search" className="icon margin-right--03" />
                                </div>
                                <div className='location-rows'>
                                    {createLocationsRows()}
                                </div>
                                <div className='location-rows-foot'>
                                    <ArrowBackIosNewIcon className='pointer-curs' onClick={page===0 ? ()=>{} : () =>{ changePage('back') }}/>
                                        &nbsp;{filteredLocations.length===0 && searchedLocation ? 0 : page+1}&nbsp;<FormattedMessage id="of"/>&nbsp;{filteredLocations.length===0 && searchedLocation ? 0 : filteredLocations.length>0 ? Math.ceil(filteredLocations.length/minRow) : Math.ceil(maxRow/minRow)}&nbsp;
                                    <ArrowForwardIosIcon className='pointer-curs' onClick={page===Math.ceil(maxRow/minRow)-1 ? ()=>{} : page===Math.ceil(filteredLocations.length/minRow)-1 ? () => {} : () =>{ changePage('forward')}}/>
                                </div>
                            </div>
                            <Map zoom={zoom} coords={coords} radius={radius} locationList={locations} setSelectedLocation={chosenLocation}></Map>
                        </div>
                    
                        <div className="button-line--wrapper">
                            <button className="button--secondary-large" type="button" onClick={gotoMyCampaigns}><FormattedMessage id="campaigns.go-back"/></button>
                            <button className="button--primary-large" type="button" onClick={gotoYourCampaign} disabled={!selectedLocation}><FormattedMessage id="campaigns.next-step"/></button>
                        </div>
                    </div>
                </section>
                
            </div>
        )
    }
}
export default injectIntl(LocationSelection)