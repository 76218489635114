import moment from "moment";

Number.prototype.numberFormat = function(decimals, dec_point, thousands_sep) {
    dec_point = typeof dec_point !== 'undefined' ? dec_point : '.';
    thousands_sep = typeof thousands_sep !== 'undefined' ? thousands_sep : ',';

    var parts = this.toFixed(decimals).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

    return parts.join(dec_point);
};

export default class CommonUtilities {

    static stringToInt(string){
        if(string === ''){
            return null;
        }else{
            return parseInt(string);
        }
    }


    static stringToBoolean(string){
        switch(string.toLowerCase().trim()){
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(string);
        } 
    }

    static getFormatDate(inputDate) {
        //torna una stringa in formato dd/mm/yyyy a partire da una stringa formato "YYYYMMDD..."

        let retVal = "";

        try {
            const yyyy = inputDate.substring(0, 4);
            const mm = inputDate.substring(4, 6);
            const dd = inputDate.substring(6, 8);

            retVal = `${dd}/${mm}/${yyyy}`;

        }catch (err) {
            console.error("utils - getFormatDate: " + err.message);
        }

        return retVal;
    }

    static getFormatDateTime(inputDate) {
        //torna una stringa in formato dd/mm/yyyy hh24:mi:ss a partire da una stringa formato "YYYYMMDDHH24MISS"

        let retVal = "";

        try {
            const yyyy = inputDate.substring(0, 4);
            const mm = inputDate.substring(4, 6);
            const dd = inputDate.substring(6, 8);

            let hh= "";
            let mi= "";
            let ss= "";
            hh = inputDate.substring(8, 10);
            mi = inputDate.substring(10, 12);
            ss = inputDate.substring(12, 14);

            retVal = `${dd}/${mm}/${yyyy} ${hh}:${mi}:${ss}`;

        }catch (err) {
            console.error("CommonUtilities - getFormatDateTime: " + err.message);
        }

        return retVal;
    }

    static getStringFromDateString(inputDate) {
        //torna una stringa in formato "YYYYMMDD" a partire da una stringa formato "DD/MM/YYYY"

        let retVal = "";

        try {
            const yyyy = inputDate.substring(6, 10);
            const mm = inputDate.substring(3, 5);
            const dd = inputDate.substring(0, 2);

            retVal = `${yyyy}${mm}${dd}`;

        }catch (err) {
            console.error("CommonUtilities - getStringFromDateString: " + err.message);
        }

        return retVal;
    }

    static getStringFromDate(inputDate) {
        //torna una stringa formato "YYYYMMDD"  a partire da un Date

        let retVal = "";

        if(!(inputDate instanceof Date && !isNaN(inputDate))){
            return retVal;
        }

        try {
            const d = inputDate.getDate().toString().padStart(2, '0');
            const m = (inputDate.getMonth() + 1).toString().padStart(2, '0');
            const y = inputDate.getFullYear().toString().padStart(4, '0');
            retVal = `${y}${m}${d}`;
        }catch (err) {
            console.error("CommonUtilities - getStringFromDate: " + err.message);
        }

        return retVal;
    }

    static getDateFromString(inputDate) {
        //torna un Date a partire da una stringa formato "YYYYMMDD"

        let retVal = null;

        try {
            retVal = new Date(parseInt(inputDate.substring(0, 4)), parseInt(inputDate.substring(4, 6)) - 1, parseInt(inputDate.substring(6, 8)));
        }catch (err) {
            console.error("CommonUtilities - getDateFromString: " + err.message);
        }

        return retVal;
    }

    static getStringFromTime(inputTime) {
        //torna una stringa formato "HH24MI" a partire da un DateTime

        let retVal = "";

        try {
            const hh = inputTime.getHours().toString().padStart(2, '0');
            const mi = inputTime.getMinutes().toString().padStart(2, '0');
            retVal = `${hh}${mi}`;

        }catch (err) {
            console.error("CommonUtilities - getStringFromTime: " + err.message);
        }

        return retVal;
    }

    static getTimeFromString(inputTime) {
        //torna un Date per la parte time (hh:mi) a partire da una stringa formato "HH24MI"

        let retVal = null;

        try {
            let today = new Date();
            today.setHours(inputTime.substring(0, 2));
            today.setMinutes(inputTime.substring(2, 4));
            retVal = today;
        }catch (err) {
            console.error("CommonUtilities - getTimeFromString: " + err.message);
        }

        return retVal;
    }


    static isFloatKey(evt) {
        //controlla l'inserimento di caratteri validi per numeri con decimali (","). Ok anche negativi ("-")
        const key = evt.key;
        //passano solo i seguenti caratteri di controllo
        if (key === "Tab" || key === "Backspace" || key === "Delete" || key === "ArrowLeft" || key === "ArrowRight" || key === "Home" || key === "End") {
            return true;
        }else{
            //passano solo caratteri dei numeri (0..9), il meno ("-") e la virgola (",")
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if(charCode == 188 ||charCode == 189 || (charCode >= 48 && charCode <= 57)|| (charCode >= 96 && charCode <= 105)){
                return true;
            }else{
                return false;
            }
        }
    }

    static  formatInt(n) {
        if(n == null){
            return '';
        }
        const pin = parseInt(n);
        const pinf = pin.numberFormat(0, ',', '.');
        return (pinf === null ? '' : pinf);
    }

/*  vecchia funzione   
    static  formatNumber(n) {
        if(n == null){
            return '';
        }
        const pin = parseFloat(n);
        const pinf = pin.numberFormat(0, ',', '.');
        return (pinf === null ? '' : pinf);
    } */

    static formatNumber = (num, locale, options={}) => {
        let retVal = '';
        if(locale ==='nl'){
            locale = 'en';
        }

        if(!num.toString().includes('.')){
            options.minimumFractionDigits = 0;
        }

        if(num !== undefined && !isNaN(num)) {
            retVal = num.toLocaleString(locale, options);
        }
        
        return retVal;
    }

    static formatFloat(num, withZero = false) {
         //visualizza una stringa numerica mettendo la virgola come separatore dei decimali
        let str = '';

        if(num != null && num.toString().trim() != ""){

            if(withZero && num == 0){
                return '0';
            }


            const f =  +(Math.round(num + "e+2")  + "e-2");
            str = f.toString();
            str = str.replace(/\./g, ',');
        }

        return str;
    }

    static formatFloatToDB(strIn) {
        //formatta una stringa numerica mettendo il punto come separatore dei decimali
        var str = '';
        if(strIn != null){
            str = strIn.toString().trim();
            if(str != ''){
                str = str.replace(/,/g, '.')
            }
        }
        return str;
    }

    static getISOWeekNumber(dt)
    {
        var tdt = new Date(dt.valueOf());
        var dayn = (dt.getDay() + 6) % 7;
        tdt.setDate(tdt.getDate() - dayn + 3);
        var firstThursday = tdt.valueOf();
        tdt.setMonth(0, 1);
        if (tdt.getDay() !== 4)
        {
            tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
        }
        return 1 + Math.ceil((firstThursday - tdt) / 604800000);
    }

    static truncateString(str, limit) {
        if (str == null) {
            return '';
        }

        if(str.length < limit){
            return str;
        }else{
            return str.substr(0, limit) + "...";
        }
    };

    static formatDateTime = (

            stringDate,

            {   // defaults
                input   = 'YYYYMMDDHHmmss',
                output  = 'D MMMM YYYY HH:mm',
                fromNow = false
            } = { input: 'YYYYMMDDHHmmss', output: 'D MMMM YYYY HH:mm', fromNow: false }

        ) => {

        let outputDate = '';
        const cellDate   = moment(stringDate, input);

        if ( cellDate.isValid() ) {

            outputDate = cellDate.format( output );

            const last5chars = outputDate.slice(-5);
            if ( last5chars === '00:00' ) {
                outputDate = outputDate.slice( 0, -5 );
            }

            if ( fromNow ) {
                outputDate += ' (' + cellDate.fromNow() + ')'
            }

        }

        return outputDate;

    };

    static formatEuroZeroBlank = (numero) => {
        let retVal = '', stringNum = '', numParsed = '' ;
        if ( numero != null && numero > 0) {
            // Elimino i caratteri che non sono numeri (lascio il segno meno e il punto)
            stringNum = numero.toString().replace(',', '.');
            stringNum = stringNum.replace(/[^0-9\.\-]?/gi, '');
            numParsed = parseFloat(stringNum);

            if ( !isNaN(numParsed) && numParsed >= 0 ) {
                retVal += numParsed.toFixed(2);
                retVal =  retVal.replace('.', ',');
                let [ strInt, strDec ]  = retVal.split(',');

                if ( strInt.length > 3 ) { // Aggiungo il separatore delle migliaia - ogni 3 numeri sulla parte intera
                    strInt = strInt.replace(/\B(?=(?:\d{3})+(?!\d))/g, '.'); // oppure '˙'
                }
                return '€ ' + strInt + ',' + strDec;
            }
        }
        return '';
    }

    static formatPositiveNumZeroBlank = (numero) => {
        let stringNum = '', numParsed = '' ;
        if ( numero != null && numero > 0) {

            stringNum       = numero.toString();            // converto in stringa per usare split()
            let [ strInt ]  = stringNum.split('.');         // prendo solo il primo elemento dell'array (la parte intera)
            numParsed       = parseInt(strInt);             // eseguo il parseInt()

            if ( !isNaN(numParsed) && numParsed >= 0 ) {    // se il risultato è NaN oppure un numero negativo esco
                let numAsString = numParsed.toString();     // altrimenti riconverto in stringa il numero, per controllare la lunqhezza e usare replace()
                if ( numAsString.length > 3 ) {             // aggiungo il separatore delle migliaia - ogni 3 numeri sulla parte intera
                    numAsString = numAsString.replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // oppure '˙'
                }
                return numAsString;
            }

        }
        return '';
    }

    // vengono aggiunti i punti come separatori delle migliaia solo alla parte intera, la parte decimale rimane inalterata
    // funziona sia con numeri che hanno come separatore di decimali il "." (punto) sia la "," (virgola)
    static formatPositiveNum = ( numero, decimali = 0 ) => {
        // 5 agosto 2020
        let retVal = '', stringNum = '', numParsed = '' ;
        if ( numero != null ) {

            // Elimino i caratteri che non sono numeri (lascio il segno meno e il punto)
            stringNum = numero.toString().replace(',', '.');
            stringNum = stringNum.replace(/[^0-9\.\-]?/gi, '');
            numParsed = parseFloat(stringNum);

            if ( !isNaN(numParsed) && numParsed >= 0 ) {
                retVal += numParsed.toFixed(decimali);
                retVal =  retVal.replace('.', ',');
                let [ strInt, strDec ]  = retVal.split(',');

                if ( strInt.length > 3 ) { // Aggiungo il separatore delle migliaia - ogni 3 numeri sulla parte intera
                    strInt = strInt.replace(/\B(?=(?:\d{3})+(?!\d))/g, ' '); // oppure '˙'
                }
                return strInt + ',' + strDec;
            }

        }
        return '';
    }

    // vengono aggiunti i punti come separatori delle migliaia
    // anche se viene passato un numero con decimali viene presa in considerazione e restituita solo la parte intera
    static formatPositiveIntNum = numero => {
        // 5 agosto 2020
        let stringNum = '', numParsed = '' ;
        if ( numero != null ) {

            stringNum       = numero.toString();            // converto in stringa per usare split()
            let [ strInt ]  = stringNum.split('.');         // prendo solo il primo elemento dell'array (la parte intera)
            numParsed       = parseInt(strInt);             // eseguo il parseInt()

            if ( !isNaN(numParsed) && numParsed >= 0 ) {    // se il risultato è NaN oppure un numero negativo esco
                let numAsString = numParsed.toString();     // altrimenti riconverto in stringa il numero, per controllare la lunqhezza e usare replace()
                if ( numAsString.length > 3 ) {             // aggiungo il separatore delle migliaia - ogni 3 numeri sulla parte intera
                    numAsString = numAsString.replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // oppure '˙'
                }
                return numAsString;
            }

        }
        return '';
    }

    static defaultSort = () => {
        let toStr      = anyVar => ( anyVar || 0 ).toString();          // per avere stringhe simili da confrontare (i falsy vengono uniformati)
        let uniformRes = res    => res < 0 ? -1 : ( res > 0 ? 1 : 0 );  // serve per uniformare i risultati del localeCompare (-1 0 +1)
        return ( e1, e2 ) => uniformRes(                                // restituisce un valore uniformato per la comparazione di stringhe
            toStr(e1).localeCompare( toStr(e2), 'en', {numeric: false, sensitivity: 'base'} )
        )
    };

    static sortBy = ( arrayOfObjects, arrayOfProperties ) => {

        // se non è un array, lo converto in array ( per chiamare il sortBy con un solo parametro di tipo string )
        if ( !Array.isArray(arrayOfProperties) ) { arrayOfProperties = [arrayOfProperties]; }

        let toStr                = anyVar     => ( anyVar || 0 ).toString(),         // per avere stringhe simili da confrontare (i falsy vengono uniformati)
            uniformRes           = res        => res < 0 ? -1 : ( res > 0 ? 1 : 0 ), // serve per uniformare i risultati del localeCompare (-1 0 +1)
            compare              = ( e1, e2 ) => uniformRes(                         // restituisce un valore uniformato per la comparazione di stringhe
                toStr(e1).localeCompare( toStr(e2), 'en', {numeric: true, sensitivity: 'base'} )
            ),
            // controlla se è una stringa non vuota
            validateString       = str        => ( typeof str === 'string' && str !== '' ) ? str : 'err: "'+JSON.stringify(str)+'"',
            validateProperty     = ( elToDebug, prop )  => {
                console.assert( typeof elToDebug[prop] !== 'undefined', 'ERROR in sortBy: proprietà ' + prop + ' non trovata in', elToDebug );
                return elToDebug[prop] || 0
            },
            compareAllProperties = ( obj1, obj2, props = [] ) => {
                let retVal          = 0 ;
                props.forEach( property => {
                    property        = validateString(property);
                    retVal          = retVal || compare ( validateProperty(obj1, property), validateProperty(obj2, property) );
                });
                return retVal
            }
        ;

        return [...arrayOfObjects].sort( ( a, b ) => compareAllProperties( a, b, arrayOfProperties ) );

    };

    static inputOnlyNumbers = (input) => {
        const regex = /^[0-9]*$/;
        
        return regex.test(input);
    };

}
