import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/authContext'
import axios from 'axios';
import Nprogress from 'nprogress';
import { FormattedMessage, injectIntl } from 'react-intl';
import InfoIcon          from '@mui/icons-material/InfoOutlined';
import backgroundImage from '../../assets/Infopages.png'
import Popper            from '@mui/material/Popper';
import Fade              from '@mui/material/Fade';
import Paper             from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import config from '../../config';
import CircleProgress from '../../util/CircleProgress'
import { useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { Language } from '../../App';

//regex e funzioni di controllo per conformità dati
const regexEmailValidator    = /^[a-z0-9]+([.\-_]?[a-z0-9]+)+@[a-z0-9]+([.\-_]?[a-z0-9]+)+\.[a-z]{2,}$/;
const regexPhoneValidator = /^[+ 0-9]*$/;
const regexPasswordValidator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{12,}$/;

const checkPassword          = (value) => regexPasswordValidator.test(value);

const checkPhoneNumber       = (value) => regexPhoneValidator.test(value);

const checkEmail             = (value) => value.length > 50 || regexEmailValidator.test(value);

const PersonalInfo = (props) =>{

    const [loading, setLoading] = useState(true);
    //stati da settare con valori della chiamata al db
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    //const [userActualPassword, setUserActualPassword] = useState("");
    const [prefix, setPrefix] = useState("")

    //dati inseriti negli input / controlli conformità
    const [showPasswordFields, setShowPasswordFields] = useState(false);
    const [showPasswordInputOld, setShowPasswordInputOld] = useState(false);
    const [showPasswordInputNew, setShowPasswordInputNew] = useState(false);
    const [showPasswordInputConfirm, setShowPasswordInputConfirm] = useState(false);        
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [emailCheck, setEmailCheck] = useState(true)
    const [phoneCheck, setPhoneCheck] = useState(true)
    const [oldPasswordCheck, setOldPasswordCheck] = useState(true)
    const [newPasswordCheck, setNewPasswordCheck] = useState(true)

    //stati contenenti gli altri campi ricevuti da db da ripassare nella post
    const [defaultLanguage , setDefaultLanguage ] = useState("");
    const [companyName     , setCompanyName     ] = useState("");
    const [city            , setCity            ] = useState("");
    const [zipCode         , setZipCode         ] = useState("");
    const [address         , setAddress         ] = useState("");
    const [companyType     , setCompanyType     ] = useState("");
    const [companyLegalForm, setCompanyLegalForm] = useState("");
    const [siretNumber     , setSiretNumber     ] = useState("");
    const [companyNaf      , setCompanyNaf      ] = useState("");
    const [tvaNumber       , setTvaNumber       ] = useState("");
    const [country         , setCountry         ] = useState("");

    //stati per il popper della password
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('');

    const [error, setError] = useState('');
    const history = useHistory();
    const auth = useContext(AuthContext);
    const {language, setLanguage} = useContext(Language)

    const checkPhoneLength = () => {
        if(phone.length===10 && country==='1'){
            return true;
        }else if(phone.length===9 && country==='2'){
            return true;
        }else if(phone.length===10 && country==='2'){
            return true;
        }else{
            return false;
        }
    }

    useEffect(() => {
        async function init(){
            const url = `${config.API_URL}/profile`;

            await axios.get(
                url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    }   
                }
            ).then((response) => {
                const data = response.data;
                setName(data.NOME);
                setSurname(data.COGNOME);
                setEmail(data.EMAIL_ADDRESS);
                setPhone(data.PHONE_NUMBER.substring(3));
                setCompanyName(data.COMPANY_NAME);
                setCity(data.CITY);
                setZipCode(data.ZIPCODE);
                setAddress(data.ADDRESS);
                setCompanyType(data.COMPANY_TYPE);
                setCompanyLegalForm(data.COMPANY_LEGAL_FORM);
                setSiretNumber(data.COMPANY_SIRET);
                setCompanyNaf(data.COMPANY_NAF);
                setTvaNumber(data.COMPANY_TVA);
                setDefaultLanguage(data.PREFERRED_LANGUAGE_COD);
                setPrefix(data.PHONE_NUMBER.substring(0,3));
                setCountry(data.LISTA_KCOUNTRY);
            }, (error) => {
                if(error.response.status === 401){ //status 401: Unauthorized
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                    auth.signOut();
                    history.push('/signin');
                }else if(error.response.status ===500){
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 5000);
                }else{
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 5000);
                }
            });
            setLoading(false);
        }
        init();
    }, []);

    //handler per visualizzare i campi di modifica password invece di quella dei dati
    const handleTogglePasswordFields = () => {
        setShowPasswordFields(!showPasswordFields)
    }

    const handleCopy = (event) => {
        event.preventDefault();
        return false;
    }

    const handlePaste = (event) => {
        event.preventDefault();
        return false;
    }

    //mentre effettuo il cambio del valore, controllo la conformità di quello che inserisce l'utente ed eventualmente blocco il bottone di submit
    const handleChange = (event) =>{
        if(event.target.name === 'phone' && !regexPhoneValidator.test(event.target.value)){
            event.preventDefault();
            return false;
        }

        switch(event.target.name){
            case 'oldPassword':
                setOldPassword(event.target.value);
                checkPassword(event.target.value) ? setOldPasswordCheck(true) : setOldPasswordCheck(false);
                break;
            case 'newPassword':
                setNewPassword(event.target.value);
                checkPassword(event.target.value) ? setNewPasswordCheck(true) : setNewPasswordCheck(false);
                break;
            case 'newPasswordConfirm':
                setNewPasswordConfirm(event.target.value);
                break;
            case 'email':
                //se l'email è sbagliata mostro il messaggio
                setEmail(event.target.value);
                checkEmail(event.target.value) ? setEmailCheck(true) : setEmailCheck(false);
                break;
            case 'phone':
                setPhone(event.target.value);
                checkPhoneNumber(event.target.value) ? setPhoneCheck(true) : setPhoneCheck(false);
                break;
            case 'surname':
                setSurname(event.target.value);
                break;
            case 'name':
                setName(event.target.value);
                break;
            case 'appLanguage':
                setDefaultLanguage(event.target.value);
                break;
            default :
                break;
        }
    }

    //handler per trasformare l'input type da 'password' a 'text' e viceversa
    const handleTogglePasswordVisibleOld = () => {
        setShowPasswordInputOld(!showPasswordInputOld)
    }
    const handleTogglePasswordVisibleNew = () => {
        setShowPasswordInputNew(!showPasswordInputNew)
    }
    const handleTogglePasswordVisibleConfirm = () => {
        setShowPasswordInputConfirm(!showPasswordInputConfirm)
    }

    //handler per il popper con le info sulla conformità della password
    const handlePopperClick = (newPosition, event) => {
        event.preventDefault()
        setAnchorEl(event.target);
        setOpen(!open);
        setPlacement(newPosition);
    }
    //per chiudere il popper quando si clicca da qualsiasi altra parte della pagina
    const handleClosePopperClickAway = (event) => {
        event.preventDefault()
        setOpen(false);
    }

    //sumbit dei dati
    const handleSubmit = (event) => {
        event.preventDefault();

        Nprogress.start();

        const params = {
            surname: surname,
            name: name,
            phone: prefix + phone,
            eMailAddress: email,
            companyName,     
            city,            
            zipCode,         
            address,         
            companyType,     
            companyLegalForm,
            siretNumber,    
            nafCode: companyNaf,      
            tvaNumber,
            defaultLanguage,
            country,
            action: '1'
        };
        //console.log(params)
        const url = `${config.API_URL}/profile-mod`;

        axios.post(
            url,
            null,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params
            }
            ).then((response) => {
                localStorage.setItem('linguaUtente', defaultLanguage);
                if( defaultLanguage !== language){
                    setLanguage(event.target.value);
                    window.location.reload();
                }
                NotificationManager.success(<FormattedMessage id={"mod.success"}/>, <FormattedMessage id={"confirm.success.title"}/>, 5000 );
                Nprogress.done();
            }, (error) => {
                if(error.response.status === 401){ //status 401: Unauthorized
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                    setLoading(true);
                    auth.signOut();
                    history.push('/signin');
                }else if(error.response.status ===500){
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 5000);
                }else{
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 5000);
                }
                Nprogress.done();
            });
    }

    //submit delle password
    const handleSubmitPassword = async(event) =>{
        event.preventDefault();
        const url = `${config.API_URL}/change-password`;
        const params = {
            oldPassword,
            newPassword
        };
        Nprogress.start();

        try {

            let response = await axios.post(
                url,
                null,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    },
                    params  
                }
            )
            if(response.status === 200){
                //console.log('delete terminata con successo')
                setOldPassword('');
                setNewPassword('');
                setNewPasswordConfirm('');
                setShowPasswordInputOld(false);
                setShowPasswordInputNew(false);
                setShowPasswordInputConfirm(false);
                NotificationManager.success(<FormattedMessage id={"mod.success"}/>, <FormattedMessage id={"confirm.success.title"}/>, 5000 );
            }
            //await auth.changePassword(oldPassword, newPassword);
        } catch (err) {
            setError(err.message);
            NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 5000);
        }
        Nprogress.done();
    }

    const { intl } = props;

    const translate = label => intl.formatMessage({ id: 'signup.' + label }) || '';

    const translatePswLabel = label => intl.formatMessage({ id: 'personal.info.' + label }) || '';

    if(loading){
        return(<CircleProgress/>);
    }else{
    return(
        <div>
            {/* Modifica dati personali */}
            {!showPasswordFields && (
            <div>
                    <form noValidate className="margin-form" onSubmit={handleSubmit}>
                    <div className="grid main-content">
                        <div className="col-desk-12 col-tab-8 col-mob-4 no-gutter layout-align-self-center">
                        <div className="section-layout--divide-view margin-bottom--08">
                        <h1 className="h1"><FormattedMessage id='personal.info.title'/></h1>
                            <div className="input-container margin-top--05">
                                <label className="label" htmlFor="email">{translate('email')} *</label>
                                <div className="row">
                                    <input className="input" data-cy="email" data-testid="email" type="email" spellCheck="false" name="email" required="" maxLength={100} disabled={true} value={email} onChange={handleChange}/>
                                    {!emailCheck && (<span className="message--error"><FormattedMessage id='personal.info.errEmail'/></span>)}
                                </div>
                            </div>

                            <div className="input-container">
                            <label className="label" htmlFor="contactName">{translate('surname')} *</label>
                            <div className="row">
                                <input className="input" data-cy="contactSurname" data-testid="contactSurname" type="text" spellCheck="false" name="surname" required="" maxLength={50} disabled="" value={surname} onChange={handleChange}/>
                            </div>
                            </div>

                            <div className="input-container">
                            <label className="label" htmlFor="contactName">{translate('name')} *</label>
                            <div className="row">
                                <input className="input" data-cy="contactName" data-testid="contactName" type="text" spellCheck="false" name="name" required="" maxLength={50} disabled="" value={name} onChange={handleChange}/>
                            </div>
                            </div>

                            <div className="input-container ">
                            <label className="label" htmlFor="numéroTelephone">{translate('phone')} *</label>
                            <div className="row">
                                <input className="input" style={{width:'80px', margin:'0 12px 0 0'}} data-cy="prefixPhone" value={prefix} id='pPrefix' name='prefix' type='text' readOnly/><label  className='phone-prefix-label'></label>
                                <input className="input" style={{}} data-cy="numéroTelephone" data-testid="numéroTelephone" type="text" name="phone" spellCheck="false" required="" maxLength={10} disabled="" value={phone} onChange={handleChange}/>
                            </div>
                            </div>

                         {/* cambio di lingua disabilitato al momento causa glooh non ha modo di rilevare la lingua
                         <div className="input-container">
                            <label className="label" htmlFor="appLanguage"><FormattedMessage id='signup.app_language'/> *</label>
                                <span className="inputIcon dropdown">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"></path>
                                        </g>
                                    </svg>
                                    <select className="select" id="appLanguage" name="appLanguage" required="" value={defaultLanguage} onChange={handleChange}>
                                        <option value="en">English</option>
                                        <option value="fr">French</option>
                                        <option value="nl">Dutch</option>
                                    </select> 
                                </span>
                            </div> */} 
                            <div>
                            <button className="button--tertiary-large margin-bottom--03" id="changepassword" data-cy="changePasswordButton" type="button" onClick={handleTogglePasswordFields}><FormattedMessage id='personal.info.btnPassword'/></button>
                            <button className="button--primary-large" id="saveChanges" data-cy="saveChanges" type="submit" disabled={(!surname || !name || !email || !phone || !checkPhoneLength()) ? true : false}><FormattedMessage id='personal.info.btnSaveInfos'/></button>
                        </div>
                        </div>
                        </div>
                        <div
                            className="col-desk-12 col-tab-4 featured-image"
                            style={{ backgroundImage: `url(${backgroundImage})` }}
                        ></div>
                </div> 
                </form>
               
            </div>)}

            {/* Modifica password */}
            {showPasswordFields && (
                <div>
                <form noValidate className="margin-form" onSubmit={handleSubmitPassword}>
                    <div className="grid main-content">
                        <div className="col-desk-12 col-tab-8 col-mob-4 no-gutter layout-align-self-center">
                        <div className="section-layout--divide-view margin-bottom--08">
                        <h1>
                            <FormattedMessage id='personal.info.titlePassword'/>
                        </h1>
                        <div className="input-container margin-top--05">
                            <label className="label" htmlFor="oldPassword">{translatePswLabel('passwordLabel1')}</label>
                            <div className="row">
                                <input className="input" data-cy="oldPassword" data-testid="oldPassword" type={showPasswordInputOld ? "text" : "password"} name="oldPassword" spellCheck="false" required="" maxLength="" disabled="" value={oldPassword} onChange={handleChange} />
                                <button className="button--tertiary-large" tabIndex="-1" type='button' onClick={handleTogglePasswordVisibleOld}>
                                    <span className="form-menu-icon">
                                        {showPasswordInputOld 
                                            ? 
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path></svg>
                                            :
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path></svg>
                                        }          
                                </span>
                                </button>
                            {
                            /*
                            !oldPasswordCheck && (<h6 className="invalid-input">Le mot de passe est le même que l'ancien</h6>)
                            */
                            }
                            </div>
                        </div>
                        <div className="input-container">
                            <label className="label" htmlFor="newPassword">{translatePswLabel('passwordLabel2')}</label>
                            <div className="row">
                                <input className="input" data-cy="newPassword" data-testid="newPassword" type={showPasswordInputNew ? "text" : "password"} name="newPassword" spellCheck="false" required="" maxLength="" disabled="" value={newPassword} onChange={handleChange} onPaste={handlePaste} onCopy={handleCopy} />
                                <button className="button--tertiary-large" tabIndex="-1" type='button' onClick={handleTogglePasswordVisibleNew}>
                                    <span className="form-menu-icon">
                                        {showPasswordInputNew
                                            ? 
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path></svg>
                                            :
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path></svg>
                                        }          
                                </span>
                                </button>
                            </div>
                            {!newPasswordCheck && (<span className="message--error">{translate('errPassword')}</span>)}<br />
                            <span className="caption"><FormattedMessage id='personal.info.popperInfo'/></span>
                        </div>
                        <div className="input-container">
                            <label className="label" htmlFor="contactName">{translatePswLabel('passwordLabel3')}</label>
                            <div className="row">
                            <input className="input" data-cy="newPasswordConfirm" data-testid="newPasswordConfirm" type={showPasswordInputConfirm ? "text" : "password"} name="newPasswordConfirm" spellCheck="false" required="" maxLength="" disabled="" value={newPasswordConfirm} onChange={handleChange} onPaste={handlePaste} onCopy={handleCopy}/>
                            <button className="button--tertiary-large" tabIndex="-1" type='button' onClick={handleTogglePasswordVisibleConfirm}>
                                <span className="form-menu-icon">
                                    {showPasswordInputConfirm 
                                        ? 
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path></svg>
                                        :
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path></svg>
                                    }          
                            </span>
                            </button>
                            </div>
                            {newPasswordConfirm !== newPassword  && (<span className="message--error">{translate('errPassword2')}</span>)}
                        </div>                
                        <div className="buttonline">
                            <button className="button--tertiary-large" id="retour" data-cy="retour" type="button" onClick={handleTogglePasswordFields}><FormattedMessage id='campaigns.go-back'/></button>
                            <button className="button--primary-large" id="saveChanges" data-cy="saveChanges" type="submit" disabled={(!oldPassword || !newPassword || !newPasswordCheck || !newPasswordConfirm|| (newPasswordConfirm !== newPassword)) ? true : false} ><FormattedMessage id='personal.info.btnSavePassword'/></button>
                        </div>
                        </div>
                        </div>
                        <div
                            className="col-desk-12 col-tab-4 featured-image"
                            style={{ backgroundImage: `url(${backgroundImage})` }}
                        ></div>
                    </div>
                </form>
            </div>)}
            {/*Popper per il messaggio della conformità della password */}
            <Popper style={{ zIndex:1000 }} open={open} anchorEl={anchorEl} placement={placement} transition>
                    {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClosePopperClickAway}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper className="infopw-box">
                                <p className="infopw"><FormattedMessage id='personal.info.popperInfo'/></p>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                    )}
            </Popper>
            
        </div>
    )}

}
export default injectIntl(PersonalInfo);