import React, { useEffect, useState } from 'react';
import config from '../../config.js';
import CircleProgress from '../../util/CircleProgress';

const PanelImage = (props) => {
    
    const [panelId, setPanelId] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setPanelId(props.match.params.id);
        setLoading(false);
    }, []);

    if(loading){
        return(<CircleProgress/>)
    }else{
        return (
            <img src={`${config.CONTENT_DISTRIBUTION_URL_ENV}/panel-photos/${panelId}`} alt='panel-img'></img>
        )
    }
    
}

export default PanelImage