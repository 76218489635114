const messages = {
  "error.global.generic": "Er heeft zich een fout voorgedaan",
  "extraPages.404Msg": "Oeps, er is een fout opgetreden. Pagina niet gevonden !",
  "extraPages.500Msg": "Er is een verbindingsfout opgetreden, ga terug en probeer het opnieuw",
  "error.global.no-privileges": "Onvoldoende aanmeldingsgegevens om de bewerking uit te voeren",
  "message.global.success": "Operatie voltooid",
  "assistance.message": "Hulp nodig? Neem contact met ons op via: ",
  /* 1 = Francia, 2 = Belgio */
  "assistance.email.1": " developers@systrategy.it",
  "assistance.email.2": " developers@systrategy.it",
  "assistance.email.3": " developers@systrategy.it",
  "connect": "Inloggen",
  "disconnect": "Uitloggen",
  "email": "Emailadres",
  "error": "Fout",
  "yes": "Ja",
  "no": "Nee",
  "of": "op",
  "france": "Frankrijk",
  "belgium": "België",
  "italy": "Italië",
  "mod.success": "Je veranderingen zijn bewaard",
  "cookies.disclaimer": "Door verder te surfen op deze site gaat u akkoord met het gebruik van essentiële cookies die u in staat stellen de belangrijkste functies van onze site te gebruiken. Zonder deze cookies kunt u onze site niet normaal gebruiken.",
  "cookies.info": "Meer informatie",
  "first.link": "Mijn campagnes",
  "first.title": "Overal gezien worden",
  "first.subtitle": "Versterk de visibiliteit van uw winkel in",
  "first.description.1": "Creëer uw eigen digitale reclamecampagne",
  "first.description.2": "Een campagne uitvoeren op digitale schermen",
  "first.description.3": "Een campagne uitvoeren op digitale schermen",
  "first.sub.description.1": "Bereik elke dag miljoenen schoppers in de winkelcentra in een paar klikken.",
  "first.sub.description.2": "Bereik elke dag miljoenen reizigers in een paar klikken.",
  "first.sub.description.3": "Bereik elke dag miljoenen reizigers in een paar klikken.",
  "first.description1": "Start een campagne op de digitale panelen van het centrum",
  "first.description2": "Het is snel, eenvoudig en in 3 stappen! Als u hulp nodig heeft, zijn onze teams op elk moment beschikbaar via onze chatbot!",
  "first.description3": "Het is snel, eenvoudig en in 3 stappen! Als u hulp nodig heeft, zijn onze teams op elk moment beschikbaar via onze chatbot!",
  "first.boxTitle1.1": "Kies uw schermen...",
  "first.boxTitle2.1": "Ontwerp uw reclame-advertentie...",
  "first.boxTitle3.1": "Plan en betaal online...",
  "first.boxTitle4.1": "Beheer uw reclamecampagne op een zeer eenvoudige en gebruiksvriendelijke manier...",
  "first.boxTitle1.2": "Selecteer uw schermen...",
  "first.boxTitle2.2": "Ontwerp uw visual...",
  "first.boxTitle3.2": "Plan en betaal online...",
  "first.boxTitle4.2": "Beheer uw reclamecampagne op een zeer eenvoudige en gebruiksvriendelijke manier...",
  "first.boxTitle1.3": "Selecteer uw schermen...",
  "first.boxTitle2.3": "Ontwerp uw visual...",
  "first.boxTitle3.3": "Plan en betaal online...",
  "first.boxTitle4.3": "Beheer uw reclamecampagne op een zeer eenvoudige en gebruiksvriendelijke manier...",
  "first.boxText1.1": "in de winkelcentra met een gerichte communicatie naar uw gewenste doelgroep.",
  "first.boxText2.1": "aan de hand van onze sjablonen en professionele tools.",
  "first.boxText3.1": "dankzij het performante op maat gemaakte platform, 100% beveiligd.",
  "first.boxText4.1": "u beschikt over gepersonaliseerde indicatoren en historiek van al uw campagnes.",
  "first.boxText1.2": "in de NMBS stations met een gerichte communicatie naar uw gewenste doelgroep.",
  "first.boxText2.2": "aan de hand van onze vooropgestelde layouts en professionele tools.",
  "first.boxText3.2": "dankzij het op maat gemaakte platform die zeer performant en 100% veilig is.",
  "first.boxText4.2": "u beschikt over gepersonaliseerde indicatoren en historiek van al uw campagnes.",
  "first.boxText1.3": "in de NMBS stations met een gerichte communicatie naar uw gewenste doelgroep.",
  "first.boxText2.3": "aan de hand van onze vooropgestelde layouts en professionele tools.",
  "first.boxText3.3": "dankzij het op maat gemaakte platform die zeer performant en 100% veilig is.",
  "first.boxText4.3": "u beschikt over gepersonaliseerde indicatoren en historiek van al uw campagnes.",
  "first.buttonText": "Mijn eerste campagne aanmaken",
  "etape1": "Stap 1",
  "etape2": "Stap 2",
  "etape3": "Stap 3",
  "etape4": "Stap 4",
  "breadcrumb2": "Creëer uw campagne",
  "breadcrumb.stepLocation": "Locatie selectie",
  "breadcrumb.step1": "Uw campagne",
  "breadcrumb.step2": "Afbeelding",
  "breadcrumb.step3": "Samenvatting campagne",
  "breadcrumb.step4": "Betaling",
  "sidebar.dashboard": "Dashboard",
  "my-campaigns.title.malls": "winkelcentra",
  "my-campaigns.title.stations": "treinstations",
  "my-campaigns.created": "Aangemaakt",
  "my-campaigns.wait-payment": "In afwachting van betaling",
  "my-campaigns.wait-approval": "In afwachting van verwerking",
  "my-campaigns.approved": "Bevestigd",
  "my-campaigns.newCampaignButton": "Nieuwe campagne",
  "my-campaigns.table.name": "Naam",
  "my-campaigns.table.location": "Plaats",
  "my-campaigns.table.period": "Datums",
  "my-campaigns.table.status": "Status",
  "my-campaigns.table.price": "Prijs",
  "my-campaigns.table.panels": "Panelen",
  "my-campaigns.table.action": "Acties",
  "my-campaigns.table.actionBtn": "Doorgaan naar betaling",
  "my-campaigns.table.fromDate": "Van",
  "my-campaigns.table.toDate": "Naar",
  "my-campaigns.box.title.empty": "U heeft geen campagnes die overeenkomen met de kolomstatus",
  "my-campaigns.box.title": "Acties in behandeling",
  "my-campaigns.actions.empty": "Je hebt geen lopende acties!",
  "my-campaigns.actions.create": "Maak een campagne",
  "my-campaigns.box.situation.alert": "Waarschuwing",
  "my-campaigns.box.situation.info": "Info",
  "my-campaigns.box.situation.await.planning": "IN AFWACHTING VAN PLANNING",
  "my-campaigns.box.situation.await.planning.lowercase": "In afwachting van planning",
  "my-campaigns.box.situation.await.payment": "IN AFWACHTING VAN BETALING",
  "my-campaigns.box.situation.await.payment.lowercase": "In afwachting van betaling",
  "my-campaigns.box.situation.approved": "GOEDGEKEURD",
  "my-campaigns.box.situation.approved.lowercase": "Goedgekeurd",
  "my-campaigns.box.situation.confirmed": "BEVESTIGD",
  "my-campaigns.box.situation.confirmed.lowercase": "Bevestigd",
  "my-campaigns.box.situation.await.approval": "IN AFWACHTING VAN GOEDKEURING",
  "my-campaigns.box.situation.await.approval.lowercase": "In afwachting van goedkeuring",
  "my-campaigns.box.situation.live": "Bezig",
  "my-campaigns.box.situation.live.left": "RESTERENDE DAGEN!",
  "my-campaigns.box.situation.live.left.1": "RESTERENDE DAG!",
  "my-campaigns.box.situation.completed": "Voltooid",
  "my-campaigns.box.situation.completed.uppercase": "VOLTOOID",
  "my-campaigns.box.situation.deleted": "Verwijderd",
  "my-campaigns.box.situation.deleted.uppercase": "VERWIJDERD",
  "my-campaigns.box.situation.ongoing": "In afwachting",
  "my-campaigns.box.situation.running": "In uitvoering",
  "my-campaigns.box.situation.ended": "Uitgevoerd / Geannuleerd",
  "my-campaigns.box.situation.ongoing.noRow": "Voorlopig geen komende campagne",
  "my-campaigns.box.situation.running.noRow": "Voorlopig geen lopende campagne",
  "my-campaigns.box.situation.ended.noRow": "Geen voltooide/verwijderde campagne voor het moment",
  "my-campaigns.box.footer.button.mod": "Aanpassen",
  "my-campaigns.box.footer.button.del": "Verwijderen",
  "my-campaigns.box.footer.button.pay": "Betaling",
  "my-campaigns.box.footer.button.final": "Details",
  "my-campaigns.box.more": "Laat meer zien...",
  "my-campaigns.box.less": "Laat minder zien...",
  "my-campaigns.box.created": " ",
  "my-campaigns.box.created.day": " dag geleden gemaakt",
  "my-campaigns.box.created.days": " dagen geleden gemaakt",
  "my-campaigns.box.created.today": "Vandaag gemaakt",
  "my-campaigns.box.purchased": "Gekocht op ",
  "my-campaigns.box.resume": "Wijziging",
  "my-campaigns.modal.delete.warn": "U kunt een campagne met gereserveerde beschikbaarheid niet verwijderen. Probeer het later opnieuw.",
  "my-campaigns.modal.delete.title": "Wilt u de geselecteerde campagne verwijderen?",
  "my-campaigns.warning.configuration": "De configuratie van deze campagne is niet voltooid",
  "my-campaigns.warning.payment": "Deze campagne wacht op betaling",
  "my-campaigns.warning.rejected": "Deze campagne is afgewezen door Clear Channel",
  "location-selection.table.panel": "Paneel",
  "location-selection.table.panels": "Schermen",
  "location-selection.table.noRows": "Geen Elementen Gevonden",
  "your-campaigns.newCampaign": "Nieuwe campagne",
  "your-campaigns.campaignName": "Ik kies mijn campagnenaam",
  "your-campaigns.perimeter": "Ik kies mijn perimeter",
  "your-campaigns.perimeter1": "Het volledige winkelcentrum",
  "your-campaigns.perimeter2": "Niveau 0",
  "your-campaigns.perimeter3": "Niveau 1",
  "your-campaigns.perimeter.screens": "schermen",
  "your-campaigns.perimeter.days": "dagen",
  "your-campaigns.perimeter.day": "dag",
  "your-campaigns.perimeter.label": "Vanaf",
  "your-campaigns.giants": "Ik wil reuzenschermen integreren in mijn uitzending",
  "your-campaigns.refine": "Ik verfijn mijn selectie",
  "your-campaigns.refine.label.begin": "Selecteer",
  "your-campaigns.refine.full": "alle schermen",
  "your-campaigns.refine.half": "de helft van de schermen",
  "your-campaigns.refine.label.end": "in mijn perimeter",
  "your-campaigns.dates": "Ik kies mijn datum van campagne",
  "your-campaigns.dates.begin": "Start datum",
  "your-campaigns.dates.end": "Eind datum",
  "your-campaigns.dates.error.format": "Ongeldige datum : DD/MM/YYYY",
  "your-campaigns.dates.error.minBeginDate": "De datum mag niet minder zijn dan",
  "your-campaigns.dates.error.minEndDate": "De datum mag niet lager zijn dan de startdatum",
  "your-campaigns.dates.error.maxDate": "De einddatum van de campagne mag niet later zijn dan",
  "your-campaigns.level.fr.floor-all": "Het volledige winkelcentrum",
  "your-campaigns.level.fr.floor-0": "Niveau 0",
  "your-campaigns.level.fr.floor-1": "Niveau 1",
  "your-campaigns.level.be.p-all": "Hele station",
  "your-campaigns.level.be.p1": "Pakket 1",
  "your-campaigns.level.be.p2": "Pakket 2",
  "your-campaigns.level.be.p3": "Pakket 3",
  "your-campaigns.level.be.p4": "Pakket 4",
  "your-campaigns.level.be.p5": "Pakket 5",
  "your-campaigns.group.be.level-1": "Niveau -1",
  "your-campaigns.group.be.level0": "Niveau 0",
  "your-campaigns.group.be.level1": "Niveau 1",
  "your-campaigns.group.be.level3": "Niveau 3",
  "your-campaigns.group.be.eurostar": "Thalys-Eurostar area",
  "your-campaigns.group.be.entry": "Ingang",
  "your-campaigns.group.be.level0.entry": "Niveau 0 Ingang",
  "your-campaigns.group.be.level0.exit": "Niveau 0 Uitgang",
  "your-campaigns.group.be.level0.hall": "Niveau 0 Alle",
  "your-campaigns.group.be.international-trains": "Internationale treinen",
  "your-campaigns.group.be.platform": "Platform",
  "your-campaigns.group-be-panel": "Panel",
  "your-campaigns.group-it-panel": "Panel",
  "your-campaigns.daypart.title": "Tijdslot",
  "your-campaigns.daypart": "Ik kies de dagdelen",
  "your-campaigns.daypart.fr.morning": "Tot 15.00 uur",
  "your-campaigns.daypart.fr.afternoon": "Vanaf 15.00 uur",
  "your-campaigns.daypart.full": "Hele dag",
  "your-campaigns.daypart.be.morning": "6.00 uur - 12.00 uur",
  "your-campaigns.daypart.be.afternoon": "12.00 - 18.00 uur",
  "your-campaigns.daypart.be.evening": "18.00 - 00.00 uur",
  "your-campaigns.daypart.it.morning": "6.00 uur - 12.00 uur",
  "your-campaigns.daypart.it.afternoon": "12.00 - 18.00 uur",
  "your-campaigns.daypart.it.evening": "18.00 - 00.00 uur",
  "your-campaigns.resume.title": "Prijs van uw campagne",
  "your-campaigns.resume.screens": "Aantal schermen:",
  "your-campaigns.resume.spot": "Aantal spots:",
  "your-campaigns.resume.spot.frequence1": "Gemiddelde frequentie:",
  "your-campaigns.resume.spot.frequence2.fr": "10 seconden/minuut",
  "your-campaigns.resume.spot.frequence2.be": "6 seconden/minuut",
  "your-campaigns.resume.contacts": "Geschatte gemiddelde contacten:",
  "your-campaigns.resume.price": "Nettoprijs excl. BTW :",
  "your-campaigns.map.info": "Klik op de pictogrammen op de kaart om meer informatie te bekijken",
  "your-campaigns.map.overlap": "*Deze panelen delen dezelfde positie*",
  "your-campaigns.basket.head": "Jouw keuze",
  "your-campaigns.basket.empty": "Selecteer de gewenste schermen op de kaart",
  "your-campaigns.basket.foot.singlePanel.panel": "scherm",
  "your-campaigns.basket.foot.singlePanel.select": "geselecteerd",
  "your-campaigns.basket.foot.multiPanel.panel": "scherm(en)",
  "your-campaigns.basket.foot.multiPanel.select": "geselecteerd",
  "your-campaigns.carousel.panel.add": "Toevoegen aan mijn selectie",
  "your-campaigns.carousel.panel.remove": "Uit mijn selectie verwijderen",
  "your-campaigns.resetCreativityDialog.title": "Uw creatie resetten?",
  "your-campaigns.resetCreativityDialog": "Wil je uw creatie resetten?",
  "your-campaigns.keep": "Houden",
  "your-campaigns.reset": "Opnieuw initialiseren",
  "your-campaigns.sync.start": "Dit scherm",
  "your-campaigns.sync.mid": "is gesynchroniseerd met het scherm",
  "your-campaigns.sync.end": "en moeten samen worden geselecteerd",
  "campaign.creative.title": "Ik personaliseer het uitzicht van mijn campagne",
  "campaign.creative.template": "Kies een sjabloon",
  "campaign.creative.custom": "Gebruik je eigen video",
  "campaign.creative.specs": "Zorg ervoor dat je video voldoet aan de vereiste specificaties",
  "campaign.creative.dimension.label": "Afmetingen",
  "campaign.creative.dimension.value": "1080 x 1920px",
  "campaign.creative.duration.label": "Looptijd",
  "campaign.creative.duration.value": "seconden",
  "campaign.creative.format.label": "Formaat",
  "campaign.creative.format.value": "mp4",
  "campaign.creative.size.label": "Maximale grootte",
  "campaign.creative.size.value": "15mb",
  "campaign.creative.addFile": "Bestand toevoegen",
  "campaign.creative.upload.success": "Het uploaden van het bestand is voltooid",
  "campaign.creative.upload.invalid": "Ongeldig",
  "campaign.creative.upload.criteria": "Het bestand voldoet niet aan de gevraagde criteria",
  "campaign.creative.dropBox.first": "Klik hier om een bestand te kiezen",
  "campaign.creative.dropBox.second": "Of zet het hier neer",
  "campaign.summary.title": "Hier is mijn samenvatting campagne  :",
  "campaign.summary.name": "Campagne naam",
  "campaign.summary.date.begin": "Campagne startdatum",
  "campaign.summary.date.end": "Einddatum van de campagne",
  "campaign.summary.days": "Aantal dagen",
  "campaign.summary.details": "Geselecteerd aanbod",
  "campaign.summary.details.perimeter": "Perimeter",
  "campaign.summary.details.perimeter.giants.yes": "Reuzenschermen inbegrepen",
  "campaign.summary.details.perimeter.giants.no": "Reuzenschermen niet inbegrepen",
  "campaign.summary.details.perimeter.daypart.full": "Hele dag",
  "campaign.summary.details.perimeter.daypart.morning": "Tot 15u",
  "campaign.summary.details.perimeter.daypart.afternoon": "Vanaf 15u",
  "campaign.summary.details.creativity": "Inhoud",
  "campaign.summary.details.creativityStatus": "Inhoud in uitvoering",
  "campaign.summary.distribution": "Wijze van verspreiding",
  "campaign.summary.distribution.screens": "Aantal schermen",
  "campaign.summary.distribution.frequence": "Gemiddelde frequentie",
  "campaign.summary.distribution.frequence/time.fr": "10 seconden/minuut",
  "campaign.summary.distribution.frequence/time.be": "6 seconden/minuut",
  "campaign.summary.distribution.spot": "Aantal spots",
  "campaign.summary.distribution.contacts": "Geschat gemiddeld aantal contacten",
  "campaign.summary.distribution.campaignCost": "Campagne kosten",
  "campaign.summary.distribution.advertiseCost": "Kosten voor adverteren",
  "campaign.summary.distribution.creationCost": "Aanmaakkosten",
  "campaign.summary.distribution.creationCost.free": "Gratis",
  "campaign.summary.distribution.priceNet": "NETTOPRIJS excl. BTW",
  "campaign.summary.distribution.tax": "BTW Bedrag",
  "campaign.summary.distribution.totalPrice": "PRIJS INCL. BTW",
  "campaign.summary.disclaimer": "Ik accepteer de algemene verkoopsvoorwaarden",
  "campaign.summary.panel.address": "Adres",
  "campaign.summary.panel.digital": "Digital ID",
  "campaign.summary.panel.dimensions": "Dimensies",
  "campaign.summary.panel.resolution": "Oplossing",
  "campaign.summary.panel.info.hover": "Beweeg de muis over de digitale ID voor meer scherminformatie",
  "campaign.summary.promoCode.title": "Promotie Code",
  "campaign.summary.promoCode.button": "De code gebruiken",
  "campaign.summary.promoCode.error.first": "De promocode",
  "campaign.summary.promoCode.error.second": "is niet geldig",
  "campaign.summary.promoCode.error.used": "De promocode is al gebruikt",
  "campaign.summary.promoCode.promotion": "Promotie",
  "campaign.summary.promoCode.saved": "Gespaard",
  "campaign.summary.promoCode.saved.first": "Je hebt ",
  "campaign.summary.promoCode.saved.second": " bespaard!",
  "campaign.summary.promoCode.saved.applied": "Promotiecode toegepast",
  "campaign.summary.promoCode.first": "Promotiecode",
  "campaign.summary.promoCode.second": "gebruikt",
  "campaign.summaryPay.dialogTitle": "Betalingsmogelijkheden",
  "campaign.summaryPay.dialogTransfer": "Overschrijving",
  "campaign.summaryPay.card": "Kredietkaart",
  "campaign.summaryPay.bancontact": "Bancontact",
  "campaign.summaryPay.dialogWarning": "U heeft de toegestane limiet voor online betaling bereikt",
  "campaign.summaryPay.be.title.confirm": "Campagne bevestigen",
  "campaign.summaryPay.be.text": "Door de betaalmethode te kiezen worden de gevraagde advertentieruimtes gereserveerd. U wordt doorgestuurd naar de betaalpagina.",
  "campaign.summaryPay.confirm": "Bevestigen",
  "campaign.summaryPay.dialog.transfer.title": "Keuze van betaling via overschrijving",
  "campaign.summaryPay.dialog.transfer.confirm": "Ik bevestig",
  "campaign.summaryPay.dialog.transfer.abort": "Annuleren",
  "campaign.summaryPay.failedPayment": "De betaling is niet afgerond. Neem contact op met het ondersteuningsteam met de chatbot of het e-mailadres",
  "campaign.stripePay.title": "Met kaart betalen",
  "campaign.stripePay.infos": "Kredietkaart informatie",
  "campaign.stripePay.card.name": "Naam op de kredietkaart",
  "campaign.stripePay.error.text": "Er is een fout opgetreden in de betalingsfase. Check je verbinding of beschikbaarheid",
  "campaign.stripePay.success.text": "Betaling succesvol gedaan",
  "campaigns.menu.account": "Mijn account",
  "campaigns.menu.personal-info": "Persoonlijke info",
  "campaigns.menu.my-company": "Mijn bedrijf",
  "campaigns.menu.my-campaigns": "Mijn campagnes",
  "campaigns.next-step": "Volgende stap",
  "campaigns.go-back": "Terug",
  "campaigns.slot-booked": "Geen beschikbaarheid voor deze campagne. Gelieve uw campagne criteria te wijzigen",
  "campaigns.pay-close": "Het verkoopteam zal contact met u opnemen om uw campagne af te ronden",
  "campaigns.error-title-1": "De naam van de campagne is verplicht",
  "campaigns.error-title-2": "De naam van de campagne moet uniek zijn",
  "label-adv-campaign.feedback-1": "Bedankt voor uw boeking!",
  "label-adv-campaign.feedback-2": "Uw campagne zal worden gevalideerd en geautoriseerd door Clear Channel",
  "campaign-stripe.buy-now": "Betalen",
  "campaign-stripe.name": "Naam",
  "campaign-stripe.name-placeholder": "Naam op kredietkaart",
  "signin.title": "Maak uw account aan en lanceer eenvoudig uw campagnes.",
  "signin.errMail": "Voer een geldig e-mail adres in",
  "signin.errPassword": "Voer een wachtwoord in",
  "signin.footerText": "Heeft u nog geen account en wilt u uw winkel promoten?",
  "signin.goToSignup": "Maak uw account aan",
  "signin.Error": "Geef de juiste gebruikersnaam en wachtwoord",
  "signin.Error.title": "Kan niet inloggen",
  "signin.Error.attempts": "U heeft te veel mislukte pogingen gehad om in te loggen. U bent tijdelijk buitengesloten",
  "signin.Error.attempts.preMin": "nog ",
  "signin.Error.attempts.postMin": "minuten ",
  "signin.UserNotConfirmed.title": "Account activatie",
  "signin.UserNotConfirmed.text": "Uw account is nog niet geactiveerd, ga verder met de activering",
  "signin.account.created": "Uw account is actief en u kunt inloggen om uw campagnes te starten",
  "signup.title-1": "Account aanmaken",
  "signup.title-2": "Door uw account aan te maken op LaunchPAD Direct kunt u uw vitrine uitbreiden en nieuwe klanten vinden!",
  "signup.contactinfo": "Persoonlijke info",
  "signup.name": "Voornaam",
  "signup.surname": "Naam",
  "signup.app_language": "Standaard taal",
  "signup.email": "Email adres",
  "signup.phone.prefix": "Land",
  "signup.phone": "Telefoon",
  "signup.password1": "Wachtwoord",
  "signup.password2": "Bevestiging wachtwoord",
  "signup.company-info": "Bedrijfs info",
  "signup.companyName": "Bedrijfsnaam",
  "signup.address": "Straat",
  "signup.codePostal": "Postcode",
  "signup.city": "Stad",
  "signup.company_type": "Type bedrijf",
  "signup.form_juridique": "Juridische status",
  "signup.siret_number": "SIRET-nummer",
  "signup.company_number": "Bedrijfsnummer",
  "signup.code_naf": "NAF / APE code",
  "signup.industry_type": "Type bedrijfstak",
  "signup.tva_number": "Intracommunautair BTW-nummer",
  "signup.mall": "Winkelcentrum",
  "signup.code": "Code",
  "signup.errMail": "Voer een geldig e-mailadres in",
  "signup.errPassword1": "Uw paswoord moet minstens 12 karakters bevatten, waaronder alfanumerieke karakters, hoofdletters en kleine letters en speciale karakters.",
  "signup.requiredError": "Dit veld is verplicht",
  "signup.errEmail": "Het e-mail formaat is onjuist",
  "signup.errPhone": "Dit formaat van telefoonnummer is niet correct",
  "signup.errZipCode": "Postcode moet 5 cijfers zijn",
  "signup.errZipCodeBe": "De code moet 4 cijfers bevatten",
  "signup.errTva": "Het BTW-nummer is onjuist",
  "signup.errSiret": "Het SIRET-nummer is onjuist",
  "signup.errCompanyNumber": "Het bedrijfsnummer moet ten minste 10 cijfers lang zijn",
  "signup.errPassword": "Het wachtwoordformaat is niet correct",
  "signup.errPassword2": "De wachtwoorden komen niet overeen",
  "signup.popperInfo": "Uw wachtwoord moet ten minste 12 karakters bevatten, alfanumeriek, hoofdletters, kleine letters en speciale tekens.",
  "signup.emptySelectValue": "Gelieve te kiezen...",
  "signup.submitButton": "Creëer uw account",
  "signup.registration-success": "Succesvolle registratie",
  "signup.registration-error": "Er is een fout opgetreden.",
  "signup.success": "De account is succesvol aangemaakt. Controleer uw inbox voor de bevestigingsmail.",
  "signup.error": "Er is een fout opgetreden tijdens de registratiefase, probeer het opnieuw",
  "signup.usernameExistsException": "E-mailadres bestaat al",
  "signup.disc1Text": "Ik heb de ",
  "signup.disc1Link": "Gebruiksvoorwaarden",
  "signup.disc1Text2": " gelezen en geaccepteerd",
  "signup.disc2Text": "Ik heb het ",
  "signup.disc2Link": "Privacy- en Cookiebeleid",
  "signup.disc2Text2": " gelezen en geaccepteerd",
  "confirm.title": "Account Activering",
  "confirm.success": "Dank u voor uw registratie, ga naar de login pagina en voer uw gegevens in om toegang te krijgen tot de applicatie.",
  "confirm.error": "Sorry, uw activeringscode is ongeldig.",
  "confirm.firstLoad": "Bedankt voor uw registratie, voer de code in die u per e-mail is toegestuurd",
  "confirm.verify": "Verifieer",
  "confirm.error.code": "Ongeldige code",
  "confirm.success.title": "Succes!",
  "confirm.success.text": "Account bevestigd",
  "confirm.gotologin": "Ga naar de inlogpagina",
  "forgot-password.title-1": "Wachtwoord vergeten?",
  "forgot-password.title-2": "Vul uw e-mailadres in op het onderstaande formulier. U ontvangt een bericht in uw inbox.",
  "forgot-password.send": "Verzenden",
  "forgot-password.code-sent.title": "Code verzonden",
  "forgot-password.code-sent.text": "Check je inbox",
  "forgot-password.code-sent.error": "Er is een fout opgetreden. Probeer het alstublieft nog eens",
  "forgot-password.code-sent.code.success": "Je wachtwoord is gewijzigd",
  "forgot-password.code-sent.code.error": "Er is een fout opgetreden, controleer de code of verbinding en probeer het opnieuw",
  "msg-reset-account-ok": "Uw verzoek is verwerkt. Er is een e-mail verzonden naar ",
  "msg-reset-account-mail-ko": "E-mailadres is ongeldig",
  "msg-reset-account-user-not-found": "E-mailadres niet geregistreerd",
  "msg-reset-account-user-email-body": "Uw nieuwe wachtwoord is gegenereerd. Vergeet niet om het te wijzigen de volgende keer dat u inlogt",
  "error-change-password-3": "Het wachtwoord moet tussen de 12 en 50 karakters lang zijn",
  "error-change-password-4": "Het ingevoerde oude wachtwoord is onjuist",
  "personal.info.title": "Mijn persoonlijke gegevens",
  "personal.info.titlePassword": "Wijzig mijn wachtwoord",
  "personal.info.passwordLabel1": "Huidig wachtwoord",
  "personal.info.passwordLabel2": "Nieuw wachtwoord",
  "personal.info.passwordLabel3": "Wachtwoord bevestigen",
  "personal.info.popperInfo": "Uw paswoord moet minstens 12 karakters bevatten waaronder minstens één hoofdletter, een cijfer en een speciaal teken.",
  "personal.info.btnSaveInfos": "Wijzigingen opslaan",
  "personal.info.btnPassword": "Wijzig mijn wachtwoord",
  "personal.info.btnSavePassword": "Wijzig mijn wachtwoord",
  "personal.info.errEmail": "Het e-mail formaat is onjuist",
  "personal.info.errPassword": "Het wachtwoordformaat is onjuist",
  "personal.info.success": "Uw persoonlijke gegevens zijn bijgewerkt",
  "personal.editPassword.success": "Uw wachtwoord is bijgewerkt",
  "company.data.title": "De gegevens van uw bedrijf",
  "company.data.success": "Uw bedrijfsgegevens zijn bijgewerkt",
  "error-campaign-slot-booked": "De datums van de campagne zijn niet langer beschikbaar. Gelieve de periode van uw campagne aan te passen",
  "legalLinks.legals": "Wettelijke vermeldingen",
  "legalLinks.generals": "Bepalingen en voorwaarden van het gebruik",
  "legalLinks.cookies": "Privacy- en cookiebeleid",
  "legalLinks.report": "Ernstige bezorgdheid melden",
  "cookies.text": "Door op \"Ik accepteer\" te klikken, gaat u akkoord met het opslaan van cookies op uw apparaat om de navigatie op de site te verbeteren, het gebruik van de site te analyseren en onze marketinginspanningen te ondersteunen.",
  "cookies.more": "Privacy & Cookie Mededeling",
  "cookies.accept": "Ik accepteer",
  "cookies.refuse": "Ik weiger",
  "notificationManager.connectionError.text.postLogin": "Verbindingsfout, log opnieuw in",
  "notificationManager.connectionError.text": "Verbindingsfout",
  "notificationManager.connectionError.text.wait": "Verbindingsfout, wachten op verbinding",
  "notificationManager.connectionError.401.title": "Sessie verlopen",
  "notificationManager.connectionError.401.text": "Sessie verlopen, log opnieuw in",
  "notificationManager.availabilitiesError.text": "De geselecteerde data voor de campagne zijn niet beschikbaar, selecteer nieuwe data",
  "notificationManager.availabilitiesError.title": "Geen beschikbaarheid",
  "modal.confirm.back-summary.text": "Als u doorgaat, wordt een nieuwe campagne geïnitialiseerd",
  "modal.confirm.back.text": "Als u doorgaat, worden uw instellingen niet opgeslagen en wordt een nieuwe campagne geïnitialiseerd",
  "modal.confirm.back.confirm": "Bevestigen",
  "modal.confirm.back.cancel": "Annuleren",
  "modal.confirm.back.warning": "Waarschuwing",
  "notificationManager.configurationError.text": "Deze campagne is op dit ogenblik niet langer beschikbaar. Gelieve de criteria te wijzigen",
  "notificationManager.configurationError.title": "Fout",
  "notificationManager.stationDisabledError.text": "Dit station is op dit ogenblik niet meer beschikbaar voor gebruikers om te boeken. Sorry voor het ongemak",
  "notificationManager.stationDisabledError.title": "Sorry",
  "notificationManager.GloohError.text": "Er is een fout opgetreden met Glooh",
  "notificationManager.GloohError.title": "Glooh fout",
  "infoWindow.button": "Zien",
  "MFA.next": "Volgende",
  "MFA.submit": "Stuur in",
  "MFA.firstStep.title": "Multi-Factor Authenticatie instellen",
  "MFA.firstStep.label": "Beste gebruiker, om de veiligheid van je account te garanderen, moet je deze MFA-configuratie voltooien.",
  "MFA.firstStep.secondLabel": "Als je geen authenticatie-app op je telefoon hebt",
  "MFA.firstStep.thirdLabel": "(Google Authenticator, Authy, Microsft Authenticator, etc...)",
  "MFA.firstStep.fourthLabel": "Installeer er een uit de App Store of Play Store voordat je verder gaat.",
  "MFA.secondStep.title": "Scan de QR-code met je verificatie-app.",
  "MFA.secondStep.secretCodeInfo": "Als je de QR-code niet kunt scannen, klik dan hier om de geheime code te zien die je in je authenticator-app moet invoeren.",
  "MFA.secondStep.label": "Als je de code correct hebt geregistreerd, ga dan verder.",
  "MFA.thirdStep.title": "Voer een geldige MFA-code van uw applicatie in om de configuratie te voltooien",
  "MFA.showQR": "Klik om te tonen",
  "MFA.invalidSubmitCode.text": "De ingevoerde code is ongeldig. Probeer het opnieuw.",
  "MFA.invalidSubmitCode.title": "Ongeldige code",
  "MFA.errorActivation.text": "MFA activering mislukt. Probeer het opnieuw.",
  "MFA.successActivation.title": "MFA geactiveerd",
  "MFA.successActivation.text": "De configuratie van de MFA is succesvol voltooid!",
  "MFA.loginDialog.title": "Multi-Factor Authenticatie",
  "MFA.loginDialog.text": "Open uw verificatie-app en voer een geldige MFA-code in om het inloggen te voltooien"
}
export default messages;