import React, { useState, useEffect, useContext } from 'react'
import QRCode from "react-qr-code";
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import { NotificationManager } from 'react-notifications';
import { FormattedMessage, injectIntl } from 'react-intl';
import LegalLinks from '../../components/LegalLinks';
import $ from 'jquery';
import './ConfigMFA.css';
import config from '../../config';
import axios from 'axios';
import { Language } from '../../App.tsx';
import CircleProgress from '../../util/CircleProgress';
import TextField from '@mui/material/TextField';
import CommonUtilities from '../../util/CommonUtilities';
import sysLogo from '../../assets/systrategy_logo.svg';

function changeclass(element) {
    if($('#menu-btn').hasClass('close')){
        $('#menu-btn').removeClass('close');
        $('#menu-btn').addClass('open');
        $('#menu-ul').removeClass('none-display');
    }else{
        $('#menu-btn').removeClass('open');
        $('#menu-btn').addClass('close');
        $('#menu-ul').addClass('none-display');
    }
}

function ConfigMFA(props) {
    const {intl} = props;
    const {language, setLanguage} = useContext(Language);
    const translate = (label) =>  intl.formatMessage({id: label});
    const history = useHistory();
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState(true)
    const [stringQr, setStringQr] = useState('');
    const [secretCode, setSecretCode] = useState('');
    const [isSecretToReveal, setIsSecretToReveal] = useState(false);
    const [confirmTotpCode, setConfirmToptCode] = useState('');
    const [step, setStep] = useState(1);
    const [country, setCountry] = useState('');
    const [showQrCode, setShowQrCode] = useState(false);

    useEffect(() => {
        async function init(){
        try{

            const url = `${config.API_URL}/profile`;
            let response = await axios.get(
                url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    }   
                }
            )
            
            if(response.status === 200){
                const data = response.data;
                setLanguage(data.PREFERRED_LANGUAGE_COD);
                setCountry(data.LISTA_KCOUNTRY);
            }

            response = await auth.requestTokenTOTP();

            setStringQr(response.qrCodeUrl);
            setSecretCode(response.secretCode);

        }catch(error){
            //questi parametri sono importanti in ogni parte autenticata dell'applicazione quindi nel caso andasse in errore l'utente DEVE rifare il login
            if(error.response.status === 401){ //status 401: Unauthorized
                NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                auth.signOut();
                history.push('/signin');
            }else if(error.response.status === 500){
                NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.text.postLogin'}/>, <FormattedMessage id={'notificationManager.connectionError.text'}/>, 5000);
                auth.signOut();
                history.push('/signin');
            }else{
                NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.text.postLogin'}/>, <FormattedMessage id={'notificationManager.connectionError.text'}/>, 5000);
                auth.signOut();
                history.push('/signin');
            }
        }
            setLoading(false)
        }
        init();
    },[])

    const menuActive = window.location.href.indexOf('/personal-info') >= 0 
    ? 
        1 
    :  
        window.location.href.indexOf('/company-info') >= 0 
            ?
                2
            :
                3;

    const signOut = () => {
        auth.signOut();
        history.push('/signin');
    }

    function signOutClicked() {
        setLoading(true);
        history.push('/signin')
        auth.signOut();
    }

    const submitTotp = async() => {
        setLoading(true);
        try{
            let response = await auth.verifySoftwareTOTP(confirmTotpCode);
            
            if(response.Status==='SUCCESS'){
                attivazioneMFA();
            }
        }catch(e){
            setLoading(false)
            NotificationManager.error(<FormattedMessage id={'MFA.invalidSubmitCode.text'}/>, <FormattedMessage id={'MFA.invalidSubmitCode.title'}/>, 5000);
        }
    }
    
    const attivazioneMFA = async() => {
        try{
            //probabilmente il remember qua non serve
            await auth.rememberDevice();
            await auth.enableMFA();
            NotificationManager.success(<FormattedMessage id={'MFA.successActivation.text'}/>, <FormattedMessage id={'MFA.successActivation.title'}/>, 5000);
            //setTimeout(() => {
            localStorage.removeItem('isMFAToConfig');
            window.location.reload();
            //},1000);
           
        }catch(e){
            setLoading(false)
            NotificationManager.error(<FormattedMessage id={'MFA.errorActivation.text'}/>, <FormattedMessage id={'error.global.generic'}/>, 5000);
        }
    }


        return (
            <div className='configMFA'>
                {loading &&(<div style={{height:'100%',zIndex:14, backgroundColor:'rgba(255, 255, 255, 60%)', width:'100%', position:'fixed', transition:'background-color 0.3s'}}><CircleProgress/></div>)}

                <a data-cy="header-logo-link" href="/">
                    <img src={sysLogo} className='logo-main-page-header' alt=""/>
                </a>
                <header className="header">
                    <input className="menu-btn close" name="menu-btn" type="checkbox" id="menu-btn" onClick={changeclass} />
                    <label htmlFor="menu-btn" className="menu-icon" data-cy="mobileMenuToggle" role="button">
                        <span className="navicon"></span>
                    </label>
                    
                    <ul id="menu-ul" className="menu none-display" role="button">
                            
                        <li>
                        
                            <a href="#/signin" onClick={() => signOutClicked()} className={menuActive === 3 ? "is-active" : ""}>
                                <button className="button--quaternary-small">
                                    <FormattedMessage id='disconnect' />
                                </button>
                            </a>
                        </li>
                    </ul>
                        
                </header>
                <div className='steps-box'>
                {
                    step === 1 ?
                        <div className='first-step'>
                            <div><FormattedMessage id="MFA.firstStep.title"/></div>
                            <div><FormattedMessage id="MFA.firstStep.label"/></div>
                            <div>
                                <FormattedMessage id="MFA.firstStep.secondLabel"/>
                                <br></br>
                                <FormattedMessage id="MFA.firstStep.thirdLabel"/>
                                <br></br>
                                <FormattedMessage id="MFA.firstStep.fourthLabel"/>
                            </div>
                            <button className="no-margin button--primary-large margin-top--04" onClick={ () => setStep(2) }>
                                <FormattedMessage id="MFA.next"/>
                            </button>
                        </div>
                    : step === 2 ?
                        <div className='second-step'>
                            <div><FormattedMessage id="MFA.secondStep.title"/></div>
                            <div className={!showQrCode && 'qrCodeClass'} onClick={()=>setShowQrCode(true)}>
                                {!showQrCode&&(<span className='showQrLabel' onClick={()=>setShowQrCode(true)}><FormattedMessage id="MFA.showQR"/></span>)}
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "180px", width: "100%", filter: showQrCode ? 'none' : 'blur(1.2rem)'}}
                                    value={stringQr}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                            <div onClick={ () => setIsSecretToReveal(!isSecretToReveal)} className='secretCodeLabel'><FormattedMessage id="MFA.secondStep.secretCodeInfo"/></div>
                            {isSecretToReveal && (<div>{secretCode}</div>)}
                            <div><FormattedMessage id="MFA.secondStep.label"/></div>
                            <div className='second-step-buttons'>
                                <button className="no-margin button--primary-large margin-top--04" onClick={ () => {setStep(1); setShowQrCode(false)} }>
                                    <FormattedMessage id="campaigns.go-back"/>
                                </button>
                                <button className="no-margin button--primary-large margin-top--04" disabled={!showQrCode} onClick={ () => setStep(3) }>
                                    <FormattedMessage id="MFA.next"/>
                                </button>
                            </div>
                        </div>
                    :
                        <div className='third-step'>

                            <div><FormattedMessage id="MFA.thirdStep.title"/></div>
                            <div className="input-container">
                                <input 
                                    className="input"
                                    data-cy="totp" 
                                    data-testid="totp" 
                                    type="text" 
                                    name="totp" 
                                    maxLength={6}
                                    placeholder={translate("signup.code")}
                                    spellCheck={ false }
                                    autoComplete="off"
                                    value={confirmTotpCode} 
                                    onChange={ e => { if(CommonUtilities.inputOnlyNumbers(e.target.value))setConfirmToptCode(e.target.value); }}/>
                            </div>
                            <div className='third-step-buttons'>
                                <button className="no-margin button--primary-large margin-top--04" onClick={ () => setStep(2) }>
                                    <FormattedMessage id="campaigns.go-back"/>
                                </button>
                                <button className="no-margin button--primary-large margin-top--04" disabled={confirmTotpCode.length!==6} onClick={ submitTotp }>
                                    <FormattedMessage id="MFA.submit"/>
                                </button>
                            </div>
                        </div>
                }
                </div>
                <footer className="section-layout text-center">
                    <p className="paragraph"><FormattedMessage id="assistance.message"/><a href={`mailto:${translate(`assistance.email.${country}`)}`}><FormattedMessage id={`assistance.email.${country}`}/></a></p>
                    <LegalLinks country={country}/>
                </footer>
            </div>
        )
    }


export default injectIntl(ConfigMFA)