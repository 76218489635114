import React from 'react';
import { FormattedMessage } from 'react-intl';

const Error500 = () => (
    <section className="section-layout">
        <h2 className="text-center margin-top--08">
        <FormattedMessage id="extraPages.500Msg"/></h2>
    </section>
);

export default Error500;