import React, { useContext, useState, useEffect } from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../contexts/authContext'
import LegalLinks from '../components/LegalLinks';
import { Language } from '../App';
import axios from 'axios';
import config from '../config';
import FranceFlag from '../assets/france-flag.svg';
import UKFlag from '../assets/united-kingdom-flag.svg';
import DutchFlag from '../assets/netherlands-flag.svg';
import CircleProgress from '../util/CircleProgress';
import { NotificationManager } from 'react-notifications';
import MyCampaigns from './campaigns/MyCampaigns'
import LocationSelection from './campaigns/LocationSelection';
import YourCampaign from './campaigns/YourCampaign';
import CampaignCreative from './campaigns/CampaignCreative';
import CampaignSummary from './campaigns/CampaignSummary';
import CampaignSummaryPay from './campaigns/CampaignSummaryPay';
import CampaignFinal from './campaigns/CampaignFinal';
import PersonalInfo from './users/PersonalInfo';
import CompanyData from './company/CompanyData';
import Page404 from './extraPages/routes/404';
import Page500 from './extraPages/routes/500';
import ConfigMFA from './users/ConfigMFA';
import sysLogo from '../assets/systrategy_logo.svg';

function changeclass(element) {
    if($('#menu-btn').hasClass('close')){
        $('#menu-btn').removeClass('close');
        $('#menu-btn').addClass('open');
        $('#menu-ul').removeClass('none-display');
    }else{
        $('#menu-btn').removeClass('open');
        $('#menu-btn').addClass('close');
        $('#menu-ul').addClass('none-display');
    }
}

const countriesArray = config.COUNTRIES_LABELS;

const CampaignsIndex = (props) => {
    const history = useHistory();
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const {language, setLanguage} = useContext(Language);
    const [country, setCountry] = useState('');
    const {intl} = props;
    const isMFAToConfig = !!localStorage.getItem('isMFAToConfig');

    const translate = (label) =>  intl.formatMessage({id: label});

    useEffect(() => {
        //if(isMFAToConfig){setLoading(false);return};
        async function init(){
            //await auth.checkIfUserHasMFA();
            const url = `${config.API_URL}/profile`;
            await axios.get(
                url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    }   
                }
            ).then((response) => {
                const data = response.data;
                setLanguage(data.PREFERRED_LANGUAGE_COD);
                setCountry(data.LISTA_KCOUNTRY);
                localStorage.setItem('country', data.LISTA_KCOUNTRY);
                localStorage.setItem('user_email', data.EMAIL_ADDRESS);
                localStorage.setItem('gloohId', data.GLOOH_ID);
                localStorage.setItem('gloohPwd', data.GLOOH_PWD);
                //questo farà casini forse se si riabilita il controllo della lingua all'utente meglio eliminarlo in caso.
                localStorage.setItem('linguaUtente', data.PREFERRED_LANGUAGE_COD);
                /* if(language !== localStorage.getItem('linguaUtente')){
                    localStorage.setItem('linguaUtente', data.PREFERRED_LANGUAGE_COD);
                    window.location.reload();
                } */
    
            }, (error) => {
                //questi parametri sono importanti in ogni parte autenticata dell'applicazione quindi nel caso andasse in errore l'utente DEVE rifare il login
                if(error.response.status === 401){ //status 401: Unauthorized
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                    auth.signOut();
                    history.push('/signin');
                }else if(error.response.status === 500){
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.text.postLogin'}/>, <FormattedMessage id={'notificationManager.connectionError.text'}/>, 5000);
                    auth.signOut();
                    history.push('/signin');
                }else{
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.text.postLogin'}/>, <FormattedMessage id={'notificationManager.connectionError.text'}/>, 5000);
                    auth.signOut();
                    history.push('/signin');
                }
            });
            setLoading(false);
        }
        init();
    }, []);

    useEffect(() => {
        //solo in belgio nascondo il chatbot
        /* if(country!=='2' && !loading){
            let interval = setInterval(function(){
                $(".crisp-client").css("display", "block");
                //console.log('aaaa')
            if( $(".crisp-client").css("display")==="block"){
                clearInterval(interval);
                //console.log('entrato')
            }
            },1000)  
        }else{
            let interval = setInterval(function(){
                $(".crisp-client").css("display", "none");
                //console.log('aaaa none')
            if( $(".crisp-client").css("display")==="none"){
                clearInterval(interval);
                //console.log('entrato none')
            }
            },1000) 
        } */
    },[loading]);
    
    const menuActive = window.location.href.indexOf('/personal-info') >= 0 
                            ? 
                                1 
                            :  
                                window.location.href.indexOf('/company-info') >= 0 
                                    ?
                                        2
                                    :
                                        3;

    const pagesWithFooter = ["your-campaign", "campaign-creative", "campaign-summary", "campaign-final"]
    let urlCountry = countriesArray[country-1];
    
    const gotoPersonalInfo = (e) => {
        e.preventDefault();
        history.push('/personal-info');
    }

    const gotoMyCompany = (e) => {
        e.preventDefault();
        history.push('/company-info');
    }

    const gotoMyCampaigns = (e) => {
        e.preventDefault();
        history.push(`/my-campaigns`);
    }

    function signOutClicked() {
      setLoading(true);
      auth.signOut();
      history.push('/signin')
    }

    if (loading){return (<CircleProgress/>)}
    else{
    return (
        <div className="theme--launchpad-light">
            <div className="mainBody">
{/*             {isMFAToConfig ?
                <Switch>
                    <Route path="/" component={ConfigMFA}/>
                </Switch>
            : */}
                <><a data-cy="header-logo-link" href="/">
                    <img src={sysLogo} className='logo-main-page-header' alt=""/>
                </a>
                <header className="header">
                    <input className="menu-btn close" name="menu-btn" type="checkbox" id="menu-btn" onClick={changeclass} />
                    <label htmlFor="menu-btn" className="menu-icon" data-cy="mobileMenuToggle" role="button">
                        <span className="navicon"></span>
                    </label>
                    <ul id="menu-ul" className="menu none-display" role="button">
                        
                        <li>
                            <a data-cy="myAccountShopsLink" onClick={gotoMyCampaigns} className={menuActive === 3 ? "is-active" : ""}>
                                <button className="button--quaternary-small"><FormattedMessage id="campaigns.menu.my-campaigns"/></button>
                            </a>
                        </li>

                                                
                        {/*     il dropdown con la lingua temporanea viene disabilitato secondo ticket GF-239 
                        <li>
                            <div className="input-container margin-bottom--00">
                            <span className="inputIcon dropdown">
                                <svg className="no-label" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                        <path d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"></path>
                                    </g>
                                </svg>
                                <select className="select margin-bottom--0 no-border"  onChange={(event) => {setLanguage(event.target.value)}} value={language}>
                                    <option value='en'>English</option>
                                    <option value='fr'>French</option>
                                    <option value='nl'>Dutch</option>
                                </select> 
                            </span>
                            </div>
                        </li> 
                        */}
                        <div className="dropdown-menu margin-left--03">
                            <button className="button--secondary-small"><FormattedMessage id="campaigns.menu.account"/></button>
                            <svg className="no-label" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"></path>
                                </g>
                            </svg>
                            <div className="dropdown-menu--content">
                                <a data-cy="myAccountLink" href="#" onClick={gotoPersonalInfo} aria-current="page" className={menuActive === 1 ? "is-active" : ""}>
                                        <button className="button--quaternary-small">
                                        <FormattedMessage id="campaigns.menu.personal-info"/>
                                    </button>
                                </a>

                                <a data-cy="myAccountLink" href="#" onClick={gotoMyCompany} aria-current="page" className={menuActive === 2 ? "is-active" : ""}>
                                    <button className="button--quaternary-small">
                                        <FormattedMessage id="campaigns.menu.my-company"/>
                                    </button>
                                </a>
                                
                                <a href="#/signin" onClick={() => signOutClicked()}>
                                    <button className="button--quaternary-small">
                                        <FormattedMessage id='disconnect' />
                                    </button>
                                </a>
                            </div>
                        </div>

                    </ul>
                </header>
                <div>
                    <div style={props.match.url + 'personal-info' ? {minHeight: 'calc( 100vh - 197px)'} : {}}>
                        <Switch>
                            <Redirect exact from={`${props.match.url}`} to={`${props.match.url}my-campaigns`}/>
                            <Redirect exact from={`${props.match.url}mfa-setup`} to={`${props.match.url}my-campaigns`}/>
                            <Redirect exact from={`${props.match.url}signin`} to={`${props.match.url}my-campaigns`}/>
                            <Redirect exact from={`${props.match.url}signup`} to={`${props.match.url}my-campaigns`}/>
                            <Redirect exact from={`${props.match.url}verify`} to={`${props.match.url}my-campaigns`}/>
                            <Redirect exact from={`${props.match.url}requestcode`} to={`${props.match.url}my-campaigns`}/>
                            <Redirect exact from={`${props.match.url}forgotpassword`} to={`${props.match.url}my-campaigns`}/>
                                                {/* Dashboard (?) render={(props) => country}*/ }
                            <Route path={`${props.match.url}my-campaigns`}                           component={MyCampaigns}/>
                                                        {/* Modifica dati per l'utente */}
                            <Route path={`${props.match.url}personal-info`}                          component={PersonalInfo}/>
                            <Route path={`${props.match.url}company-info`}                           component={CompanyData}/>
                                                        {/* Campagne */}
    {/* location selection disponibile solo in belgio al momento */}
    {country==='1' ? null : <Route path={`${props.match.url}${urlCountry}/location-selection`}       component={LocationSelection}/>} 
                            <Route path={`${props.match.url}${urlCountry}/your-campaign/:id`}        component={YourCampaign}/>
                            <Route path={`${props.match.url}${urlCountry}/campaign-creative`}        component={CampaignCreative}/>
                            <Route path={`${props.match.url}${urlCountry}/campaign-summary`}         component={CampaignSummary}/>
                            <Route path={`${props.match.url}${urlCountry}/campaign-summary-pay/:id`} component={CampaignSummaryPay}/>
                            <Route path={`${props.match.url}${urlCountry}/campaign-final/:id`}       component={CampaignFinal}/>
                                                        {/* Pagine di errore */}
                            <Route path={`${props.match.url}connection-error`}                       component={Page500}/>
                            <Route                                                                   component={Page404}/>
                        </Switch>
                    </div>
                </div>

                {!pagesWithFooter.some(page => window.location.href.includes(page)) && window.location.href.indexOf('/campaign-stripe') < 0 && (
                    <footer className="section-layout text-center">
                        <p className="paragraph"><FormattedMessage id="assistance.message"/><a href={`mailto:${translate(`assistance.email.${country}`)}`}><FormattedMessage id={`assistance.email.${country}`}/></a></p>
                        <LegalLinks country={country}/>
                    </footer>
                )}
                </>{/* } */}
            </div>
        </div>
    );}
}

export default injectIntl(CampaignsIndex);