import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function CircleProgress() {
  return (
    <div className='middlePage-Loader'>
      <div className='circularProgress'>
        <CircularProgress />
      </div>
    </div>
  );
}