import React, {useState, createContext, useMemo, useEffect } from 'react'
import $ from 'jquery';
import './App.css';
import './styles/app.scss';
import 'react-notifications/lib/notifications.css';
import PanelImage from './routes/campaigns/PanelImage';
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications';
import { IntlProvider } from 'react-intl';
import appLocales from './lang/index';

import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from './contexts/authContext'

import CampaignsIndex from './routes/index'

import asyncComponent from './util/asyncComponent';
import config from './config';
import { NotificationManager } from 'react-notifications';
import { FormattedMessage} from 'react-intl';

export const Language = createContext({
  language: '',
  setLanguage: (val?:string) => {},
});

const SignInRoute: React.FunctionComponent = React.memo(() => {

  useEffect(()=> {
    const stringParameters = new URLSearchParams( '?' + window.location.href.split('?')[1]);
    if(stringParameters.has('accountCreated')){
      if(stringParameters.get('accountCreated')==='Y'){
        NotificationManager.success(<FormattedMessage id={'signin.account.created'}/>, <FormattedMessage id={'confirm.success.title'}/>, 5000);
      }
    }
    //showChatBot()
  },[])

  const showChatBot = () => {
    let interval = setInterval(function(){
      $(".crisp-client").css("display", "block");
      if( $(".crisp-client").css("display")==="block"){
        clearInterval(interval);
      }
    },1000)  
  }

  return(
  <>
    <Router>
      <Switch>
        <Route path="/signin"         component={asyncComponent(() => import('./routes/auth/signIn'))}/>
        <Route path="/signup"         component={asyncComponent(() => import('./routes/auth/signUp'))}/>
        <Route path="/forgotpassword" component={asyncComponent(() => import('./routes/auth/forgotPassword'))} />
        <Route path="/"               component={asyncComponent(() => import('./routes/auth/signIn'))}/>
      </Switch>
    </Router>
  </>
)})

const MainRoute: React.FunctionComponent = React.memo(() => {
return(
  <Router>
    <Switch>
      <Route path='/panel-image/:id' component={PanelImage}/>
      <Route path="" component={CampaignsIndex} />
    </Switch>
  </Router>
)})

const App: React.FunctionComponent = () => {
  
  let availableLanguages = config.LANGUAGES;

  // Get the locale to use. You could use Redux, useContext, URL params or local storage 
  // to manage this value.
  const [language, setLanguage] = useState(localStorage.getItem('linguaUtente') ? localStorage.getItem('linguaUtente') : ( availableLanguages.includes(navigator.language.substring(0,2)) ? navigator.language.substring(0,2) : 'en'));
  //console.log('language status', language);
  const languageValue:any = useMemo(
    () => ({ language, setLanguage }), 
    [language]
  );

  const localeConfig = appLocales[language || navigator.language.substring(0,2)];
  
  return (
    <div className="app-main">
      <Language.Provider value={languageValue}>
        <IntlProvider locale={localeConfig.locale} messages={localeConfig.messages} >
          <NotificationContainer/>
          <AuthProvider>
            <AuthIsSignedIn>
              <MainRoute/>
            </AuthIsSignedIn>
            <AuthIsNotSignedIn>
              <SignInRoute/>
            </AuthIsNotSignedIn>
          </AuthProvider>
        </IntlProvider>
      </Language.Provider>
    </div>
)}

export default App;