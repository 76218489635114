const messages = {
  "error.global.generic": "An error has occurred",
  "extraPages.404Msg": "Oops, an error has occurred. Page not found !",
  "extraPages.500Msg": "There was a connection error, go back and try again",
  "error.global.no-privileges": "Insufficient credentials to execute the operation",
  "message.global.success": "Operation completed",
  "assistance.message": "Need help? Contact us at: ",
  /* 1 = Francia, 2 = Belgio */
  "assistance.email.1": " developers@systrategy.it",
  "assistance.email.2": " developers@systrategy.it",
  "assistance.email.3": " developers@systrategy.it",
  "connect": "Login",
  "disconnect": "Logout",
  "email": "Email address",
  "error": "Error",
  "yes": "Yes",
  "no": "No",
  "of": "of",
  "france": "France",
  "belgium": "Belgium",
  "italy": "Italy",
  "mod.success": "Your changes have been saved",
  "cookies.disclaimer": "By continuing to browse this site you agree to the use of essential cookies that allow you to use the main features of our site. Without these cookies, you will not be able to use our site normally.",
  "cookies.info": "More information",
  "first.link": "My campaigns",
  "first.title": "Be seen everywhere",
  "first.subtitle": "Expand your shop visibility in",
  "first.description.1": "Launch a poster campaign on digital panels",
  "first.description.2": "Launch a poster campaign on digital panels",
  "first.description.3": "Launch a poster campaign on digital panels",
  "first.sub.description.1": "Reach millions of shoppers, every day in malls, with just a few clicks.",
  "first.sub.description.2": "Reach millions of passengers, every day in stations, with just a few clicks.",
  "first.sub.description.3": "Reach millions of passengers, every day in stations, with just a few clicks.",
  "first.description1": "Launch a campaign on the digital panels of the center",
  "first.description2": "It's fast, simple and in 3 steps! If you need help, our teams are available at any time via our chatbot!",
  "first.description3": "It's fast, simple and in 3 steps! If you need help, our teams are available at any time via our chatbot!",
  "first.boxTitle1.1": "Select your screens...",
  "first.boxTitle2.1": "Create your advertising visual...",
  "first.boxTitle3.1": "Plan and pay online...",
  "first.boxTitle4.1": "Simply manage your advertising...",
  "first.boxTitle1.2": "Select your screens...",
  "first.boxTitle2.2": "Create your advertising visual...",
  "first.boxTitle3.2": "Plan and pay online...",
  "first.boxTitle4.2": "Simply manage your advertising...",
  "first.boxTitle1.3": "Select your screens...",
  "first.boxTitle2.3": "Create your advertising visual...",
  "first.boxTitle3.3": "Plan and pay online...",
  "first.boxTitle4.3": "Simply manage your advertising...",
  "first.boxText1.1": "in malls, while simply targeting your communication.",
  "first.boxText2.1": "with our professional templates and design tools.",
  "first.boxText3.1": "on a 100% online, efficient and secure platform.",
  "first.boxText4.1": "with indicators and the history of your campaigns.",
  "first.boxText1.2": "in SNCB stations, while simply targeting your communication.",
  "first.boxText2.2": "with our professional templates and design tools.",
  "first.boxText3.2": "on a 100% online, efficient and secure platform.",
  "first.boxText4.2": "with indicators and the history of your campaigns.",
  "first.boxText1.3": "in SNCB stations, while simply targeting your communication.",
  "first.boxText2.3": "with our professional templates and design tools.",
  "first.boxText3.3": "on a 100% online, efficient and secure platform.",
  "first.boxText4.3": "with indicators and the history of your campaigns.",
  "first.buttonText": "Create my first campaign",
  "etape1": "Step 1",
  "etape2": "Step 2",
  "etape3": "Step 3",
  "etape4": "Step 4",
  "breadcrumb2": "Create your campaign",
  "breadcrumb.stepLocation": "Location selection",
  "breadcrumb.step1": "Your campaign",
  "breadcrumb.step2": "Visual",
  "breadcrumb.step3": "Summary",
  "breadcrumb.step4": "Payment",
  "sidebar.dashboard": "Dashboard",
  "my-campaigns.title.malls": "malls",
  "my-campaigns.title.stations": "railway stations",
  "my-campaigns.created": "Created",
  "my-campaigns.wait-payment": "Pending payment",
  "my-campaigns.wait-approval": "Awaiting processing",
  "my-campaigns.approved": "Confirmed",
  "my-campaigns.newCampaignButton": "New campaign",
  "my-campaigns.table.name": "Name",
  "my-campaigns.table.location": "Location",
  "my-campaigns.table.period": "Dates",
  "my-campaigns.table.status": "Status",
  "my-campaigns.table.price": "Price",
  "my-campaigns.table.panels": "Panels",
  "my-campaigns.table.action": "Actions",
  "my-campaigns.table.actionBtn": "Proceed to payment",
  "my-campaigns.table.fromDate": "From",
  "my-campaigns.table.toDate": "To",
  "my-campaigns.box.title.empty": "You have no campaigns matching the column status",
  "my-campaigns.box.title": "Pending Actions",
  "my-campaigns.actions.empty": "You have no pending actions!",
  "my-campaigns.actions.create": "Create a campaign",
  "my-campaigns.box.situation.alert": "Alert",
  "my-campaigns.box.situation.info": "Info",
  "my-campaigns.box.situation.await.planning": "AWAITING PLANIFICATION",
  "my-campaigns.box.situation.await.planning.lowercase": "Awaiting planification",
  "my-campaigns.box.situation.await.payment": "AWAITING PAYMENT",
  "my-campaigns.box.situation.await.payment.lowercase": "Awaiting payment",
  "my-campaigns.box.situation.approved": "APPROVED",
  "my-campaigns.box.situation.approved.lowercase": "Approved",
  "my-campaigns.box.situation.confirmed": "CONFIRMED",
  "my-campaigns.box.situation.confirmed.lowercase": "Confirmed",
  "my-campaigns.box.situation.await.approval": "AWAITING APPROVAL",
  "my-campaigns.box.situation.await.approval.lowercase": "Awaiting approval",
  "my-campaigns.box.situation.live": "Live",
  "my-campaigns.box.situation.live.left": "DAYS LEFT!",
  "my-campaigns.box.situation.live.left.1": "DAY LEFT!",
  "my-campaigns.box.situation.completed": "Finished",
  "my-campaigns.box.situation.completed.uppercase": "CONCLUDED",
  "my-campaigns.box.situation.deleted": "Cancelled",
  "my-campaigns.box.situation.deleted.uppercase": "DELETED",
  "my-campaigns.box.situation.ongoing": "Coming up",
  "my-campaigns.box.situation.running": "Running",
  "my-campaigns.box.situation.ended": "Finished / Cancelled",
  "my-campaigns.box.situation.ongoing.noRow": "No coming campaign for the moment",
  "my-campaigns.box.situation.running.noRow": "No running campaign for the moment",
  "my-campaigns.box.situation.ended.noRow": "No finished/deleted campaign for the moment",
  "my-campaigns.box.footer.button.mod": "Modify",
  "my-campaigns.box.footer.button.del": "Delete",
  "my-campaigns.box.footer.button.pay": "Payment",
  "my-campaigns.box.footer.button.final": "Details",
  "my-campaigns.box.more": "Show more...",
  "my-campaigns.box.less": "Show less...",
  "my-campaigns.box.created": "Created",
  "my-campaigns.box.created.day": " day ago",
  "my-campaigns.box.created.days": " days ago",
  "my-campaigns.box.created.today": "Created today",
  "my-campaigns.box.purchased": "Purchased on ",
  "my-campaigns.box.resume": "Resume",
  "my-campaigns.modal.delete.warn": "You can't delete a campaign with reserved availabilities. Try again later.",
  "my-campaigns.modal.delete.title": "Do you want to delete the selected campaign?",
  "my-campaigns.warning.configuration": "The configuration of this campaign is not complete",
  "my-campaigns.warning.payment": "This campaign is waiting paiment",
  "my-campaigns.warning.rejected": "This campaign has been rejected by Clear Channel",
  "location-selection.table.panel": "Panel",
  "location-selection.table.panels": "Panels",
  "location-selection.table.noRows": "No Elements Found",
  "your-campaigns.newCampaign": "New campaign",
  "your-campaigns.campaignName": "I choose my campaign name",
  "your-campaigns.perimeter": "I choose my perimeter",
  "your-campaigns.perimeter1": "Whole mall",
  "your-campaigns.perimeter2": "Level 0",
  "your-campaigns.perimeter3": "Level 1",
  "your-campaigns.perimeter.screens": "screens",
  "your-campaigns.perimeter.days": "days",
  "your-campaigns.perimeter.day": "day",
  "your-campaigns.perimeter.label": "Starting from",
  "your-campaigns.giants": "I want to integrate giant screens in my broadcast",
  "your-campaigns.refine": "I refine my selection",
  "your-campaigns.refine.label.begin": "Select",
  "your-campaigns.refine.full": "all the screens",
  "your-campaigns.refine.half": "half of the screens",
  "your-campaigns.refine.label.end": "in my perimeter",
  "your-campaigns.dates": "I choose my campaign dates",
  "your-campaigns.dates.begin": "Start date",
  "your-campaigns.dates.end": "End date",
  "your-campaigns.dates.error.format": "Invalid date format : DD/MM/YYYY",
  "your-campaigns.dates.error.minBeginDate": "The date must not be less than",
  "your-campaigns.dates.error.minEndDate": "The date must not be less than the start date",
  "your-campaigns.dates.error.maxDate": "The campaign end date cannot exceed",
  "your-campaigns.level.fr.floor-all": "Whole mall",
  "your-campaigns.level.fr.floor-0": "Level 0",
  "your-campaigns.level.fr.floor-1": "Level 1",
  "your-campaigns.level.be.p-all": "Whole station",
  "your-campaigns.level.be.p1": "Package 1",
  "your-campaigns.level.be.p2": "Package 2",
  "your-campaigns.level.be.p3": "Package 3",
  "your-campaigns.level.be.p4": "Package 4",
  "your-campaigns.level.be.p5": "Package 5",
  "your-campaigns.group.be.level-1": "Level -1",
  "your-campaigns.group.be.level0": "Level 0",
  "your-campaigns.group.be.level1": "Level 1",
  "your-campaigns.group.be.level3": "Level 3",
  "your-campaigns.group.be.eurostar": "Thalys-Eurostar area",
  "your-campaigns.group.be.entry": "Entrance",
  "your-campaigns.group.be.level0.entry": "Level 0 Entry",
  "your-campaigns.group.be.level0.exit": "Level 0 Exit",
  "your-campaigns.group.be.level0.hall": "Level 0 Hall",
  "your-campaigns.group.be.international-trains": "International Trains",
  "your-campaigns.group.be.platform": "Platform",
  "your-campaigns.group-be-panel": "Panel",
  "your-campaigns.group-it-panel": "Panel",
  "your-campaigns.daypart.title": "Time slot",
  "your-campaigns.daypart": "I choose the daypart hours",
  "your-campaigns.daypart.fr.morning": "Until 3pm",
  "your-campaigns.daypart.fr.afternoon": "From 3pm",
  "your-campaigns.daypart.full": "Full day",
  "your-campaigns.daypart.be.morning": "6.00am - 12.00pm",
  "your-campaigns.daypart.be.afternoon": "12.00pm - 6.00pm",
  "your-campaigns.daypart.be.evening": "6.00pm - 12.00am",
  "your-campaigns.daypart.it.morning": "6.00am - 12.00pm",
  "your-campaigns.daypart.it.afternoon": "12.00pm - 6.00pm",
  "your-campaigns.daypart.it.evening": "6.00pm - 12.00am",
  "your-campaigns.resume.title": "Campaign amount",
  "your-campaigns.resume.screens": "No. of screens:",
  "your-campaigns.resume.spot": "No. of spots:",
  "your-campaigns.resume.spot.frequence1": "Average frequency:",
  "your-campaigns.resume.spot.frequence2.fr": "10 seconds/minute",
  "your-campaigns.resume.spot.frequence2.be": "6 seconds/minute",
  "your-campaigns.resume.contacts": "Estimated average contacts:",
  "your-campaigns.resume.price": "Net price excl. VAT :",
  "your-campaigns.map.info": "Please click screen icons on map to display more information",
  "your-campaigns.map.overlap": "*These panels share the same position*",
  "your-campaigns.basket.head": "Your selection",
  "your-campaigns.basket.empty": "Select the desired screens from the map",
  "your-campaigns.basket.foot.singlePanel.panel": "screen",
  "your-campaigns.basket.foot.singlePanel.select": "selected",
  "your-campaigns.basket.foot.multiPanel.panel": "screen(s)",
  "your-campaigns.basket.foot.multiPanel.select": "selected",
  "your-campaigns.carousel.panel.add": "Add to my selection",
  "your-campaigns.carousel.panel.remove": "Remove from my selection",
  "your-campaigns.resetCreativityDialog.title": "Reset your visual?",
  "your-campaigns.resetCreativityDialog": "Do you want to reset your visual?",
  "your-campaigns.keep": "Keep it",
  "your-campaigns.reset": "Reinitialize it",
  "your-campaigns.sync.start": "This screen",
  "your-campaigns.sync.mid": "is synchronized with screen",
  "your-campaigns.sync.end": "and must be selected together",
  "campaign.creative.title": "I personalize the appearance of my campaign",
  "campaign.creative.template": "Choose a template",
  "campaign.creative.custom": "Use your own video",
  "campaign.creative.specs": "Please make sure that your video follows the required specifications",
  "campaign.creative.dimension.label": "Dimensions",
  "campaign.creative.dimension.value": "1080 x 1920px",
  "campaign.creative.duration.label": "Duration",
  "campaign.creative.duration.value": "seconds",
  "campaign.creative.format.label": "Format",
  "campaign.creative.format.value": "mp4",
  "campaign.creative.size.label": "Max size",
  "campaign.creative.size.value": "15mb",
  "campaign.creative.addFile": "Add file",
  "campaign.creative.upload.success": "The file upload has been completed",
  "campaign.creative.upload.invalid": "Invalid",
  "campaign.creative.upload.criteria": "The file doesn't match the requested criterias",
  "campaign.creative.dropBox.first": "Click to choose a file",
  "campaign.creative.dropBox.second": "Or drop it here",
  "campaign.summary.title": "Here is my campaign summary :",
  "campaign.summary.name": "Campaign name",
  "campaign.summary.date.begin": "Campaign start date",
  "campaign.summary.date.end": "Campaign end date",
  "campaign.summary.days": "No. of days",
  "campaign.summary.details": "Selected offer",
  "campaign.summary.details.perimeter": "Perimeter",
  "campaign.summary.details.perimeter.giants.yes": "Giant screens included",
  "campaign.summary.details.perimeter.giants.no": "Giant screens excluded",
  "campaign.summary.details.perimeter.daypart.full": "Full day",
  "campaign.summary.details.perimeter.daypart.morning": "Until 3pm",
  "campaign.summary.details.perimeter.daypart.afternoon": "From 3pm",
  "campaign.summary.details.creativity": "Content",
  "campaign.summary.details.creativityStatus": "Content in progress",
  "campaign.summary.distribution": "Methods of distribution",
  "campaign.summary.distribution.screens": "No. of screens",
  "campaign.summary.distribution.frequence": "Average frequency",
  "campaign.summary.distribution.frequence/time.fr": "10 seconds/minute",
  "campaign.summary.distribution.frequence/time.be": "6 seconds/minute",
  "campaign.summary.distribution.spot": "No. of spots",
  "campaign.summary.distribution.contacts": "Estimated average contacts",
  "campaign.summary.distribution.campaignCost": "Campaign cost",
  "campaign.summary.distribution.advertiseCost": "Adserving fees",
  "campaign.summary.distribution.creationCost": "Creation fees",
  "campaign.summary.distribution.creationCost.free": "Free",
  "campaign.summary.distribution.priceNet": "NET PRICE excl. VAT",
  "campaign.summary.distribution.tax": "VAT Amount",
  "campaign.summary.distribution.totalPrice": "PRICE INCL. VAT",
  "campaign.summary.disclaimer": "I accept the general conditions of sales",
  "campaign.summary.panel.address": "Address",
  "campaign.summary.panel.digital": "Digital ID",
  "campaign.summary.panel.dimensions": "Dimensions",
  "campaign.summary.panel.resolution": "Resolution",
  "campaign.summary.panel.info.hover": "Please hover over digital ID for more screen information",
  "campaign.summary.promoCode.title": "Promotion Code",
  "campaign.summary.promoCode.button": "Apply",
  "campaign.summary.promoCode.error.first": "The promo code",
  "campaign.summary.promoCode.error.second": "is not valid",
  "campaign.summary.promoCode.error.used": "The promo code has already been used",
  "campaign.summary.promoCode.promotion": "Promotion",
  "campaign.summary.promoCode.saved": "Saved",
  "campaign.summary.promoCode.saved.first": "You saved ",
  "campaign.summary.promoCode.saved.second": "!",
  "campaign.summary.promoCode.saved.applied": "Promo code applied",
  "campaign.summary.promoCode.first": "Promo code",
  "campaign.summary.promoCode.second": "used",
  "campaign.summaryPay.dialogTitle": "Payment choices",
  "campaign.summaryPay.dialogTransfer": "Bank transfer",
  "campaign.summaryPay.card": "Credit card",
  "campaign.summaryPay.bancontact": "Bancontact",
  "campaign.summaryPay.dialogWarning": "You have reached the authorized limit for online payment",
  "campaign.summaryPay.be.title.confirm": "Confirm campaign",
  "campaign.summaryPay.be.text": "By choosing the payment method, the requested advertising spaces will be reserved. You will be redirected to the payment page.",
  "campaign.summaryPay.confirm": "Confirm",
  "campaign.summaryPay.dialog.transfer.title": "Choice of payment by bank transfer",
  "campaign.summaryPay.dialog.transfer.confirm": "I validate",
  "campaign.summaryPay.dialog.transfer.abort": "Cancel",
  "campaign.summaryPay.failedPayment": "The payment hasn't been concluded. Please contact the support team with the chatbot or email address",
  "campaign.stripePay.title": "Pay by card",
  "campaign.stripePay.infos": "Credit card information",
  "campaign.stripePay.card.name": "Name on the credit card",
  "campaign.stripePay.error.text": "An error has occured in the payment phase. Check your connection or availability",
  "campaign.stripePay.success.text": "Payment done successfully",
  "campaigns.menu.account": "Account",
  "campaigns.menu.personal-info": "Personal info",
  "campaigns.menu.my-company": "My company",
  "campaigns.menu.my-campaigns": "My campaigns",
  "campaigns.next-step": "Next step",
  "campaigns.go-back": "Back",
  "campaigns.slot-booked": "No availability for this campaign. Please change your campaign criteria",
  "campaigns.pay-close": "The sales team will contact you to finalize your campaign",
  "campaigns.error-title-1": "The name of the campaign is mandatory",
  "campaigns.error-title-2": "The name of the campaign must be unique",
  "label-adv-campaign.feedback-1": "Thanks for your booking!",
  "label-adv-campaign.feedback-2": "Your campaign will be validated and authorized by Clear Channel",
  "campaign-stripe.buy-now": "Pay",
  "campaign-stripe.name": "Name",
  "campaign-stripe.name-placeholder": "Name on credit card",
  "signin.title": "Create your account and easily launch your campaigns.",
  "signin.errMail": "Please enter a valid email address",
  "signin.errPassword": "Please enter a password that is not empty",
  "signin.footerText": "You don't have an account yet and want to promote your store?",
  "signin.goToSignup": "Create your account",
  "signin.Error": "Please provide correct username and password",
  "signin.Error.title": "Unable to sign-in",
  "signin.Error.attempts": "You have had too many unsuccessful attempts to login. You have been temporarily locked out",
  "signin.Error.attempts.preMin": " ",
  "signin.Error.attempts.postMin": "minutes remaining ",
  "signin.UserNotConfirmed.title": "Account activation",
  "signin.UserNotConfirmed.text": "Your account has not been activated yet, proceed with the activation",
  "signin.account.created": "Your account is active and you can login to start your campaigns",
  "signup.title-1": "Account creation",
  "signup.title-2": "Creating your account on LaunchPAD Direct allows you to expand your showcase and find new customers!",
  "signup.contactinfo": "Personal info",
  "signup.name": "First name",
  "signup.surname": "Last name ",
  "signup.app_language": "Default language",
  "signup.email": "Email address",
  "signup.phone.prefix": "Country",
  "signup.phone": "Phone number",
  "signup.password1": "Password",
  "signup.password2": "Password Confirmation",
  "signup.company-info": "Company info",
  "signup.companyName": "Company name",
  "signup.address": "Street",
  "signup.codePostal": "Zip code",
  "signup.city": "City",
  "signup.company_type": "Type of business",
  "signup.form_juridique": "Legal Status",
  "signup.siret_number": "SIRET number",
  "signup.company_number": "Company number",
  "signup.code_naf": "NAF / APE code",
  "signup.industry_type": "Industry type",
  "signup.tva_number": "Intracommunity VAT number",
  "signup.mall": "Shopping Mall",
  "signup.code": "Code",
  "signup.errMail": "Veuillez entrer un email valide",
  "signup.errPassword1":  "Votre mot de passe doit contenir au moins 12 caractères, des caractères alphanumériques, des majuscules et des minuscules etdes caractères spéciaux.",
  "signup.requiredError": "This field is mandatory",
  "signup.errEmail": "The email format is incorrect",
  "signup.errPhone": "The phone number format is incorrect",
  "signup.errZipCode": "Postal code must be 5 digits",
  "signup.errZipCodeBe": "Postal code must be 4 digits",
  "signup.errTva": "The VAT number is incorrect",
  "signup.errSiret": "The SIRET number is incorrect",
  "signup.errCompanyNumber": "The company number must be at least 10 digits long",
  "signup.errPassword": "Password format is incorrect",
  "signup.errPassword2": "Passwords do not match",
  "signup.popperInfo": "Your password must contain at least 12 characters, alphanumeric, upper and lower case and special characters.",
  "signup.emptySelectValue": "Please choose...",
  "signup.submitButton": "Create your account",
  "signup.registration-success": "Successful registration",
  "signup.registration-error": "An error has occurred.",
  "signup.success": "The account has been successfully created. Please check your inbox for the confirmation email.",
  "signup.error": "There was an error during the registration phase, please try again",
  "signup.usernameExistsException": "Email address already exists",
  "signup.disc1Text": "I have read and I accept the ",
  "signup.disc1Link": "Terms and Conditions of Use",
  "signup.disc1Text2": "",
  "signup.disc2Text": "I have read and I accept the ",
  "signup.disc2Link": "Privacy and Cookie Policy",
  "signup.disc2Text2": "",
  "confirm.title": "Account Activation",
  "confirm.success": "Thank you for your registration, please go to the login page and enter your credentials to access the application.",
  "confirm.error": "Sorry, your activation code is not valid.",
  "confirm.firstLoad": "Thank you for your registration, enter the code sent by email",
  "confirm.verify": "Verify",
  "confirm.error.code": "Invalid Code",
  "confirm.success.title": "Success!",
  "confirm.success.text": "Account confirmed",
  "confirm.gotologin": "Go to login page",
  "forgot-password.title-1": "Forgot your password?",
  "forgot-password.title-2": "Please enter your email address in the form below. You will receive a message in your inbox.",
  "forgot-password.send": "Send",
  "forgot-password.code-sent.title": "Code sent",
  "forgot-password.code-sent.text": "Check your inbox",
  "forgot-password.code-sent.error": "An error has occurred, please try again",
  "forgot-password.code-sent.code.success": "Your password has been changed",
  "forgot-password.code-sent.code.error": "An error has occurred, check the code or connection and try again",
  "msg-reset-account-ok": "Your request has been processed. An email has been sent to ",
  "msg-reset-account-mail-ko": "Email address is invalid",
  "msg-reset-account-user-not-found": "Email address not registered",
  "msg-reset-account-user-email-body": "Your new password has been generated. Don't forget to change it the next time you login",
  "error-change-password-3": "The password length must be between 12 and 50 characters",
  "error-change-password-4": "The old password entered is incorrect",
  "personal.info.title": "My personal information",
  "personal.info.titlePassword": "Change my password",
  "personal.info.passwordLabel1": "Current password",
  "personal.info.passwordLabel2": "New password",
  "personal.info.passwordLabel3": "Confirm password",
  "personal.info.popperInfo": "Your password must have at least 12 alphanumeric characters, one upper case, one lower case and at least one special character.",
  "personal.info.btnSaveInfos": "Save changes",
  "personal.info.btnPassword": "Modify my password",
  "personal.info.btnSavePassword": "Change my password",
  "personal.info.errEmail": "The email format is incorrect",
  "personal.info.errPassword": "Password format is incorrect",
  "personal.info.success": "Your personal information has been updated",
  "personal.editPassword.success": "Your password has been updated",
  "company.data.title": "Your company's information",
  "company.data.success": "Your company information has been updated",
  "error-campaign-slot-booked": "The campaign dates are no longer available. Please modify the dates of your campaign",
  "legalLinks.legals": "Legals Mentions",
  "legalLinks.generals": "Terms and conditions of use",
  "legalLinks.cookies": "Privacy and Cookie Policy",
  "legalLinks.report": "Reporting serious concerns",
  "cookies.text": "By clicking \"I accept\", you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.",
  "cookies.more": "Privacy & Cookie Notice",
  "cookies.accept": "I accept",
  "cookies.refuse": "I refuse",
  "notificationManager.connectionError.text.postLogin": "Connection error, please login again",
  "notificationManager.connectionError.text": "Connection Error",
  "notificationManager.connectionError.text.wait": "Connection error, waiting for connection",
  "notificationManager.connectionError.401.title": "Session expired",
  "notificationManager.connectionError.401.text": "Session expired, log in again",
  "notificationManager.availabilitiesError.text": "The dates selected for the campaign are not available, please select new dates",
  "notificationManager.availabilitiesError.title": "No availability",
  "modal.confirm.back-summary.text": "If you proceed, a new campaign will be initialized",
  "modal.confirm.back.text": "If you proceed, your settings will not be saved and a new campaign will be initialized",
  "modal.confirm.back.confirm": "Confirm",
  "modal.confirm.back.cancel": "Cancel",
  "modal.confirm.back.warning": "Warning",
  "notificationManager.configurationError.text": "This campaign is no longer available for users to book at this time, please change campaign criteria",
  "notificationManager.configurationError.title": "Error",
  "notificationManager.stationDisabledError.text": "This station is no longer available for users to book at this time. Sorry for the inconvenience",
  "notificationManager.stationDisabledError.title": "Sorry",
  "notificationManager.GloohError.text": "An error with Glooh occurred",
  "notificationManager.GloohError.title": "Glooh error",
  "infoWindow.button": "See",
  "MFA.next": "Next",
  "MFA.submit": "Submit",
  "MFA.firstStep.title": "Multi-Factor Authentication Setup",
  "MFA.firstStep.label": "Dear user, to enhance your account security, please complete this MFA configuration.",
  "MFA.firstStep.secondLabel": "If you don't have an authenticator app on your phone",
  "MFA.firstStep.thirdLabel": "(Google Authenticator, Authy, Microsft Authenticator, etc...)",
  "MFA.firstStep.fourthLabel": "Please install one from the App Store or the Play Store before proceeding.",
  "MFA.secondStep.title": "Scan the QR code with your authentication app.",
  "MFA.secondStep.secretCodeInfo": "If you can't scan the QR code click here to show the secret code to input in your authenticator app.",
  "MFA.secondStep.label": "If you registered correctly the code, please proceed.",
  "MFA.thirdStep.title": "Enter a valid MFA code from your application to complete the configuration",
  "MFA.showQR": "Click to show",
  "MFA.invalidSubmitCode.text": "The code entered is invalid. Please try again.",
  "MFA.invalidSubmitCode.title": "Invalid code",
  "MFA.errorActivation.text": "MFA Activation Failed. Please try again.",
  "MFA.successActivation.title": "MFA Activated",
  "MFA.successActivation.text": "The configuration of the MFA has been successfully completed!",
  "MFA.loginDialog.title": "Multi-Factor Authentication",
  "MFA.loginDialog.text": "Open your authentication app and enter a valid MFA code to complete login"
}
export default messages;