import React, { useState, useEffect, useContext }   from 'react';
import                                                   "./MyCampaigns.css";
import config                                       from '../../config';
import { FormattedMessage, injectIntl }             from 'react-intl';
import { useHistory }                               from 'react-router-dom'
import CommonUtilities                              from '../../util/CommonUtilities';
import AddCircleOutlineIcon                         from '@mui/icons-material/AddCircleOutline';
import moment                                       from "moment";
import axios                                        from 'axios'
import { AuthContext }                              from '../../contexts/authContext'
import CircleProgress                               from '../../util/CircleProgress'
import { /* Alerts */ Language }                    from '../../App';
import { NotificationManager }                      from 'react-notifications';
import { Edit20, View20, Purchase20, TrashCan20, Image32 } from '@carbon/icons-react';
import Dialog                                       from '@mui/material/Dialog';
import DialogActions                                from '@mui/material/DialogActions';
import DialogContent                                from '@mui/material/DialogContent';
import DialogContentText                            from '@mui/material/DialogContentText';
import DialogTitle                                  from '@mui/material/DialogTitle';
import NavigateNextIcon                             from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon                           from '@mui/icons-material/NavigateBefore';
import ArrowDownwardIcon                            from '@mui/icons-material/ArrowDownward';
import WindowResizeHook                             from '../../hooks/WindowResizeHook';

const checkReadyForPayment = (campaign) => {

    // si va al pagamento diretto solo se:
    // - la campagna si trova nello stato 2 (slot prenotati e attesa di pagamento)
    // - la campagna si trova nello stato 1 e ci sono slot liberi per la sua configurazione
    // - le date della campagna continuano ad essere valide

    if(campaign.STATUS_COD > 2){
        return false;
    }

    if(campaign.STATUS_COD === 2){
        return true;
    }

    if(campaign.STATUS_COD === 1 && campaign.SLOT_FREE === 'N'){
        return false;
    }
    
    let checkDay = moment();

    for(let i=0; i<3; i++){
        checkDay = checkDay.add(1, 'days');
        if(checkDay.format('ddd') === 'Sat'){
            checkDay = checkDay.add(2, 'days');
        }else if(checkDay.format('ddd') === 'Sun'){
            checkDay = checkDay.add(1, 'days');
        }
    }

    const campaignDate = moment(campaign.BEGIN_DATE, 'YYYYMMDD');
    campaignDate.set({ 'hour': 23, minute: 59, second: 59 });

    if(campaignDate.isBefore(checkDay)){
        return false;
    }

    return true;
}

const MyCampaigns = (props) =>{

    const [loading, setLoading] = useState(true);
    const [campaigns, setCampaigns] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedLocationDesc, setSelectedLocationDesc] = useState('');
    const [country, setCountry] = useState( localStorage.getItem('country') );
    const auth = useContext(AuthContext);
    const {language, setLanguage} = useContext(Language);
    const [showDoneCampaigns, setShowDoneCampaigns] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [loadingCalculation, setLoadingCalculation] = useState(false)
    
    //stati per il controllo delle colonne
    //cambiare il valore dello useState per cambiare il numero di elementi da mostrare

    const [firstColLength   ,setFirstColLength  ] = useState(0);
    const [secondColLength  ,setSecondColLength ] = useState(0);
    const [thirdColLength   ,setThirdColLength  ] = useState(0);
    const [fourthColLength  ,setFourthColLength ] = useState(0);
    const [fifthColLength   ,setFifthColLength  ] = useState(0);

    //numero di elementi nella nuova dashboard da visualizzare
    
    
    const [startingSliceValue, setStartingSliceValue] = useState(0)
    const [endingSliceValue, setEndingSliceValue] = useState(4);
    const [blockForwardAction, setBlockForwardAction] = useState(false);

    const [selectedCampaignTableTab, setSelectedCampaignTableTab] = useState(1);
    //la colonna delle date è quella di default
    const [colToOrderBy, setColToOrderBy] = useState(4);
    const [orderByVal, setOrderByVal] = useState('desc');

    const [showRejectMessage, setShowRejectMessage] = useState('N');

    const history = useHistory();

    const windowWidth = WindowResizeHook();
    const fixedElementsToShow = windowWidth ? 1 : 4;
    const [maxNumberCardsPerView, setMaxNumberCardsPerView] = useState(windowWidth ? 1 : 4);
    
    useEffect(() => {
        async function init(){
            //remove parameters from localStorage
            localStorage.removeItem('kCampaign');
            localStorage.removeItem('campaignName');
            localStorage.removeItem('floor');
            localStorage.removeItem('giant');
            localStorage.removeItem('allFrames');
            localStorage.removeItem('daypart');
            localStorage.removeItem('beginDate');
            localStorage.removeItem('endDate');
            localStorage.removeItem('gloohCreativeId');
            localStorage.removeItem('gloohBriefId');
            localStorage.removeItem('gloohRendered');
            localStorage.removeItem('priceMedia');
            localStorage.removeItem('priceAdv');
            localStorage.removeItem('priceTva');
            localStorage.removeItem('priceTtc');        
            localStorage.removeItem('price');
            localStorage.removeItem('framesSelected');
            localStorage.removeItem('days');
            localStorage.removeItem('numberOfSpot');
            localStorage.removeItem('slotFree');
            localStorage.removeItem('slotSelected');
            localStorage.removeItem('estimatedAvgContacts');
            localStorage.removeItem('showAudience');
            localStorage.removeItem('listaKLocation');
            localStorage.removeItem('locationName');
            localStorage.removeItem( 'packageName' );
            localStorage.removeItem( 'floorName' );
            localStorage.removeItem( 'panelsSelected');
            localStorage.removeItem( 'groupList' );
            localStorage.removeItem( 'framesLevels');
            localStorage.removeItem( 'userBasket');
            localStorage.removeItem('campaignRejected');
            localStorage.removeItem('panelsList');
            localStorage.removeItem('customCreativeId');
            localStorage.removeItem('gloohBriefIdForPreview');
            localStorage.removeItem('gloohCreativeIdForPreview');
            localStorage.removeItem('promoCodeDiscount');
            localStorage.removeItem('originalPrice');
            localStorage.removeItem('originalPriceTva');
            localStorage.removeItem('originalPriceTtc');
            
            let params = {
                kCountry: country,
                flagOnlyEnabled: 'N'
            }
        
            let url = `${config.API_URL}/locations`;

            let response = '';

            try {
                response = await axios.get(
                    url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                        },
                        params  
                    }
                )
                if(response.status === 200){
                    //console.log('locations',response.data)
                    setLocations(response.data);
                }else{
                    history.push('/connection-error');
                    return;
                }

                url = `${config.API_URL}/countries`;

                response = await axios.get(
                    url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                        },
                    }
                )

                if(response.status===200){
                    setShowRejectMessage(response.data[0].FLAG_REJECT_MSG);
                }

                url = `${config.API_URL}/campaigns`;

                response = await axios.get(
                    url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                        },
                    }
                )

                if(response.status === 200){
                    //console.log('campaign', response.data)
                    setCampaigns(response.data);
                    setFirstColLength(response.data.filter( campaign => (((campaign.STATUS_COD === 1) || (campaign.STATUS_COD === 2)) && checkReadyForPayment(campaign) !== true) || campaign.REJECTED==='Y' ).length);
                    setSecondColLength(response.data.filter( campaign => (((campaign.STATUS_COD === 1) || (campaign.STATUS_COD === 2)) && checkReadyForPayment(campaign) === true) && campaign.REJECTED==='N').length);
                    setThirdColLength(response.data.filter( campaign => ((campaign.STATUS_COD === 3) || (campaign.STATUS_COD === 4)) && campaign.LIVE_STATUS_COD === 0).length);
                    setFourthColLength(response.data.filter( campaign => ((campaign.STATUS_COD === 4) && (campaign.LIVE_STATUS_COD === 1))).length);
                    setFifthColLength(response.data.filter( campaign => ((campaign.STATUS_COD === 4) && (campaign.LIVE_STATUS_COD === 2))).length);
                }else{
                    history.push('/connection-error');
                    return;
                }
            } catch (error) {
                if (!error.response){
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 0);
                    history.push('/connection-error');
                    return;
                }else if(error.response.status === 401){ //status 401: Unauthorized
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                    auth.signOut();
                    history.push('/signin');
                    return;
                }else if(error.response.status ===500){//nelle chiamate in cui bisogna recuperare informazioni critiche per il corretto caricamento della pagina, in caso di errore, l'utente viene indirizzato alla pagina 'connection-error'
                    history.push('/connection-error');
                    return;
                }else{
                    history.push('/connection-error');
                    return;
                }
            }
            setLoading(false);
        }
        init();
    }, []);

    useEffect(() => {
        if(windowWidth){
            setMaxNumberCardsPerView(fixedElementsToShow)
        }else if(!windowWidth){
            setMaxNumberCardsPerView(fixedElementsToShow)
        }
    },[windowWidth])

    useEffect(() => {
        setSelectedLocation((locations[0] || {}).KLOCATION);
        setSelectedLocationDesc((locations[0] || {}).LOCATION_DESC);
    }, [locations]);

    useEffect(() => {
        localStorage.setItem('listaKLocation',selectedLocation);
    }, [selectedLocation]);

    const {intl} = props;

    const translate = (label) =>  intl.formatMessage({id: label});

    const gotoEditYourCampaign = (campaign) => {
        if(campaign.STATUS_COD === 1){
            localStorage.setItem('listaKLocation', campaign.LISTA_KLOCATION);
            localStorage.setItem('locationName', findLocationName(campaign.LISTA_KLOCATION));
            localStorage.setItem('campaignRejected', campaign.REJECTED);
            history.push({ pathname: '/'+urlCountry+'/your-campaign/'+campaign.KCAMPAIGN});
        }else if(campaign.STATUS_COD === 2){
            localStorage.setItem('locationName', findLocationName(campaign.LISTA_KLOCATION));
            history.push(`/${urlCountry}/campaign-summary-pay/${campaign.KCAMPAIGN}`);
        }else{
            localStorage.setItem('locationName', findLocationName(campaign.LISTA_KLOCATION));
            history.push({ pathname: '/'+urlCountry+'/campaign-final/'+campaign.KCAMPAIGN});
        }
    }
    const countriesArray = config.COUNTRIES_LABELS;
    let urlCountry = countriesArray[country-1];
    const gotoYourCampaign = () => {
        if(locations.length !== 1){
            history.push(`/${urlCountry}/location-selection`);
        }else{
            localStorage.setItem('locationName', locations[0].LOCATION_DESC);
            history.push(`/${urlCountry}/your-campaign/new`);
        };
        locations.length !== 1 ? history.push(`/${urlCountry}/location-selection`) : history.push(`/${urlCountry}/your-campaign/new`);
    }
    
    const gotoCampaignPayment = (kCampaign, kLocation) => {
        localStorage.setItem('locationName', findLocationName(kLocation));
        history.push(`/${urlCountry}/campaign-summary-pay/${kCampaign}`);
    }
    
    const createLocationsList = () => {
        return locations.map( location => <option key={location.KLOCATION} value={location.KLOCATION}>{location.LOCATION_DESC}</option>)
    };

    const handleLocationChange = async(event) => {
        //console.log('evento', event.target.value)
        setSelectedLocation(event.target.value);
        localStorage.setItem('listaKLocation',event.target.value);
        handleDescLocationChange(event.target.value)
    }

    const handleDescLocationChange = (kloc) =>{
        //console.log('kloc', typeof(kloc))
        let desc = locations.find((element) => element.KLOCATION === parseInt(kloc));
        setSelectedLocationDesc(desc.LOCATION_DESC)
    }

    const findLocationName = (locazione) =>{
        //console.log('locazione', locazione)
        let desc = locations.find(luogo => luogo.KLOCATION === parseInt(locazione));
        //console.log('desc',desc);
        return desc.LOCATION_DESC;
    }

    const handleShiftActionsRow = (action) => {
        if (action === '-'){
            if(maxNumberCardsPerView-fixedElementsToShow < 0){
                //nel caso in cui si switcha la dimensione della finestra e quindi il valore su cui fare lo slice
                //verifico che lo slice non assuma valori negativi risettandolo al valore fixed di incremento/decrescenza che
                //rispecchia il valore base
                setMaxNumberCardsPerView(fixedElementsToShow);
            }else{
                setMaxNumberCardsPerView(maxNumberCardsPerView-fixedElementsToShow)
            };
        }else{
            if(maxNumberCardsPerView-fixedElementsToShow > campaigns.filter( campaign => ((campaign.STATUS_COD === 1) || (campaign.STATUS_COD === 2)) || campaign.REJECTED==='Y' ).length){
                setMaxNumberCardsPerView(fixedElementsToShow)
            }else{
                setMaxNumberCardsPerView(maxNumberCardsPerView+fixedElementsToShow);
            }
        }
    }

    useEffect(() => {
        if(campaigns.length > 0){
            let sortedCampaignsLength = campaigns.filter( campaign => ((campaign.STATUS_COD === 1) || (campaign.STATUS_COD === 2)) || campaign.REJECTED==='Y' ).length;

            if (maxNumberCardsPerView > sortedCampaignsLength){
                setBlockForwardAction(true);
            }else if(maxNumberCardsPerView === sortedCampaignsLength){
                setBlockForwardAction(true);
            }else{
                setBlockForwardAction(false);
            }
        }
    },[maxNumberCardsPerView])

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    }

    const deleteCampaign = async () => {
        setLoadingCalculation(true);

        let params = {
            kCampaign: openDeleteDialog,
        }
    
        let url = `${config.API_URL}/campaign-del`;

        let response = '';

        try {
            response = await axios.post(
                url,
                params,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    }  
                }
            )
            if(response.status === 200){
                //console.log('delete terminata con successo')
                setOpenDeleteDialog(false);
            }else if(response.status === 201){
                setLoadingCalculation(false);
                NotificationManager.warning(<FormattedMessage id={'my-campaigns.modal.delete.warn'}/>, <FormattedMessage id={'notificationManager.stationDisabledError.title'}/>, 5000);
                setOpenDeleteDialog(false);
                return;
            }else{
                history.push('/connection-error');
                return;
            }
        } catch (error) {
            if (!error.response){
                NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 0);
                history.push('/connection-error');
                return;
            }else if(error.response.status === 401){ //status 401: Unauthorized
                NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                auth.signOut();
                history.push('/signin');
                return;
            }else if(error.response.status ===500){//nelle chiamate in cui bisogna recuperare informazioni critiche per il corretto caricamento della pagina, in caso di errore, l'utente viene indirizzato alla pagina 'connection-error'
                history.push('/connection-error');
                return;
            }else{
                history.push('/connection-error');
                return;
            }
        }
        window.location.reload();
    }

    const createCampaignsStatusBoxes = ( campaigns ) => {
        let sortedCampaigns = campaigns.sort(function(a, b){return a.BEGIN_DATE-b.BEGIN_DATE});
        
        return sortedCampaigns.map((campaign) => 
            <div className='card__container boxy'>
                <div className='card__container--content'>
                    <div className='boxy-content'>
                        {   
                            campaign.CUSTOM_CREATIVE_ID !== null
                            ?
                            <img className='slot-thumb' src={`${config.CONTENT_DISTRIBUTION_URL_ENV}/thumbs/${campaign.CUSTOM_CREATIVE_ID}.png`}></img>    
                            :
                            campaign.GLOOH_RENDERED === 'Y'
                            ?
                            <img className='slot-thumb' src={`${config.CONTENT_DISTRIBUTION_URL_ENV}/thumbs/${campaign.GLOOH_CREATIVE_ID}.png`}></img>
                            :
                            <div className='slot-no-img'>
                                <Image32/>
                            </div>
                        }
                        <div className='boxy-infos'>
                            <div className='main-infos'>
                                <h4 style={{marginBottom:0, fontSize:'12px', whiteSpace:'nowrap'}}>{campaign.DCAMPAIGN}</h4>
                                <span className='date-label'>
                                    <FormattedMessage id="your-campaigns.dates.begin"/> : {moment(campaign.BEGIN_DATE,'YYYYMMDDhhmmss' ).format('DD/MM/YYYY').toString()}
                                </span>
                            </div>
                            {
                                campaign.REJECTED === 'Y'
                                ?
                                <div className='boxy-red-background'>
                                    {showRejectMessage==='N'
                                    ?
                                    <FormattedMessage id="my-campaigns.warning.rejected"/>
                                    :
                                    campaign.REJECT_REASON && campaign.REJECT_REASON.trim().length > 0
                                    ?
                                    campaign.REJECT_REASON
                                    :
                                    <FormattedMessage id="my-campaigns.warning.rejected"/>
                                    }
                                </div>
                                :
                                checkReadyForPayment(campaign) === true
                                ?
                                <div className='boxy-red-background'><FormattedMessage id="my-campaigns.warning.payment"/></div>
                                :
                                <div className='boxy-yellow-background'><FormattedMessage id={`my-campaigns.warning.${campaign.REJECTED === 'Y' ? 'rejected' : 'configuration'}`}/></div>
                            }
                        </div>
                    </div>
                </div>
                <div className='card__container--footer'>
                    <button className="arrow-carousel item boxy-btn" type="button" onClick={() => {setOpenDeleteDialog(campaign.KCAMPAIGN)}}><TrashCan20/></button>
                    {
                        campaign.REJECTED==='Y' 
                        ?
                        <button className="arrow-carousel item boxy-btn" type="button" onClick={() => {gotoEditYourCampaign(campaign)}}>
                            <FormattedMessage id="my-campaigns.box.resume"/>
                            &nbsp;
                            <NavigateNextIcon/>
                        </button>
                        : 
                        checkReadyForPayment(campaign) === true
                        ?
                        <button className="arrow-carousel item boxy-btn" type="button" onClick={() => {gotoCampaignPayment(campaign.KCAMPAIGN, campaign.LISTA_KLOCATION)}}>
                            <FormattedMessage id="breadcrumb.step4"/>
                            &nbsp;
                            <Purchase20/>
                        </button>
                        :
                        <button className="arrow-carousel item boxy-btn" type="button" onClick={() => {gotoEditYourCampaign(campaign)}}>
                            <FormattedMessage id="my-campaigns.box.resume"/>
                            &nbsp;
                            <NavigateNextIcon/>    
                        </button>
                    }
                </div>
            </div>).slice(maxNumberCardsPerView - fixedElementsToShow, maxNumberCardsPerView);
    }
 
    const createRowCampaignTable = (campaigns, tab) => {

        let sortedCampaigns = campaigns;
        
        if(sortedCampaigns.length === 0){
            return (
                <div className='empty-row'>
                    <div className='row-cell'>
                        <FormattedMessage id={`my-campaigns.box.situation.${tab===1?'ongoing':tab===2?'running':'ended'}.noRow`}/>
                    </div>
                </div>
            )
        }else{
            switch(colToOrderBy){
                case 1:
                    if(orderByVal === 'desc'){
                        sortedCampaigns = campaigns.sort((a,b) => (b.DCAMPAIGN.trim().toLowerCase() > a.DCAMPAIGN.trim().toLowerCase()) ? 1 : ((a.DCAMPAIGN.trim().toLowerCase() > b.DCAMPAIGN.trim().toLowerCase()) ? -1 : 0));
                    }else{
                        sortedCampaigns = campaigns.sort((a,b) => (a.DCAMPAIGN.trim().toLowerCase() > b.DCAMPAIGN.trim().toLowerCase()) ? 1 : ((b.DCAMPAIGN.trim().toLowerCase() > a.DCAMPAIGN.trim().toLowerCase()) ? -1 : 0))
                    }
                    break;
                case 2:
                    if(orderByVal === 'desc'){
                        sortedCampaigns = campaigns.sort(function(a, b){return b.TOTAL_COST-a.TOTAL_COST});
                    }else{
                        sortedCampaigns = campaigns.sort(function(a, b){return a.TOTAL_COST-b.TOTAL_COST})
                    }
                    break;
                case 4:
                    if(orderByVal === 'desc'){
                        sortedCampaigns = campaigns.sort(function(a, b){return b.BEGIN_DATE-a.BEGIN_DATE});
                    }else{
                        sortedCampaigns = campaigns.sort(function(a, b){return a.BEGIN_DATE-b.BEGIN_DATE})
                    }
                    break;
                case 5:
                    if(orderByVal === 'desc'){
                        sortedCampaigns = campaigns.sort(function(a, b){return b.STATUS_COD-a.STATUS_COD});
                    }else{
                        sortedCampaigns = campaigns.sort(function(a, b){return a.STATUS_COD-b.STATUS_COD})
                    }
                    break;
                default:
                    sortedCampaigns = campaigns.sort(function(a, b){return b.BEGIN_DATE-a.BEGIN_DATE});
                    break;
            }

            return sortedCampaigns.map(campaign => 
                <div className='row'>
                    <div className='row-cell' style={{width:'30%'}}>
                        
                        {   
                            campaign.CUSTOM_CREATIVE_ID !== null
                            ?
                            <img className='slot-thumb' src={`${config.CONTENT_DISTRIBUTION_URL_ENV}/thumbs/${campaign.CUSTOM_CREATIVE_ID}.png`}></img>    
                            :
                            campaign.GLOOH_RENDERED === 'Y'
                            ?
                            <img className='slot-thumb' src={`${config.CONTENT_DISTRIBUTION_URL_ENV}/thumbs/${campaign.GLOOH_CREATIVE_ID}.png`}></img>
                            :
                            <div className='slot-no-img'>
                                <Image32/>
                            </div>
                        }
                        &nbsp;
                        {campaign.DCAMPAIGN}
                    </div>
                    <div className='row-cell' style={{width:'10%'}}>{CommonUtilities.formatNumber(campaign.TTC_COST, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}</div>
                    <div className='row-cell' style={{width:'10%'}}>{campaign.PANELS_LIST ? campaign.PANELS_LIST.split(',').length : campaign.FRAMES_TOTAL}</div>
                    <div className='row-cell' style={{width:'15%'}}>
                        <div>
                            <FormattedMessage id="my-campaigns.table.fromDate"/> {moment(campaign.BEGIN_DATE,'YYYYMMDDhhmmss' ).format('DD/MM/YYYY').toString()}
                        </div>
                        <div>
                            <FormattedMessage id="my-campaigns.table.toDate"/> {moment(campaign.END_DATE,'YYYYMMDDhhmmss' ).format('DD/MM/YYYY').toString()}
                        </div>
                    </div>
                    <div className='row-cell' style={{width:'25%'}}>
                        <div className={`${campaign.STATUS_COD === 3 ? 'yellow-status' : (campaign.STATUS_COD === 4 ? 'green-status' : 'red-status')}`}>
                            {campaign.STATUS_COD === 3 ?
                            translate('my-campaigns.box.situation.await.approval.lowercase') 
                            : 
                            campaign.STATUS_COD === 4 && campaign.LIVE_STATUS_COD === 0 ? 
                            translate('my-campaigns.box.situation.approved.lowercase') 
                            : 
                            campaign.STATUS_COD === 4 && campaign.LIVE_STATUS_COD === 1 ?
                            translate('my-campaigns.box.situation.live')
                            :
                            campaign.STATUS_COD === 4 && campaign.LIVE_STATUS_COD === 2 ?
                            translate('my-campaigns.box.situation.completed')
                            :
                            translate('my-campaigns.box.situation.deleted')
                            }
                        </div>
                    </div>
                    <div className='row-cell' style={{width:'10%', display:'flex', justifyContent:'flex-end'}}>
                        <button className="button--tertiary-small item" type="button" onClick={() =>{gotoEditYourCampaign(campaign)}}><View20 /></button>
                    </div>
                </div>
            )
        }
    }

    const createVerticalTableView = () => {
        let sortedCampaigns = [];
        
        switch(selectedCampaignTableTab){
            case 1:
                sortedCampaigns = campaigns.filter( campaign => ((campaign.STATUS_COD === 3) || (campaign.STATUS_COD === 4)) && campaign.LIVE_STATUS_COD === 0);
                break;
            case 2:
                sortedCampaigns = campaigns.filter( campaign => ((campaign.STATUS_COD === 4) && (campaign.LIVE_STATUS_COD === 1)));
                break;
            case 3:
                sortedCampaigns = campaigns.filter( campaign => ((campaign.STATUS_COD === 4) && (campaign.LIVE_STATUS_COD === 2)) || campaign.STATUS_COD === 99);
                break;
            default:
                break;
        }
        sortedCampaigns = sortedCampaigns.sort(function(a, b){return b.BEGIN_DATE-a.BEGIN_DATE});
        if(sortedCampaigns.length === 0){
            return (
                <div className='empty-row'>
                    <div className='row-cell'>
                        <FormattedMessage id={`my-campaigns.box.situation.${selectedCampaignTableTab===1?'ongoing':selectedCampaignTableTab===2?'running':'ended'}.noRow`}/>
                    </div>
                </div>
            )
        }else{
            return sortedCampaigns.map( campaign => 
                <div className='v-box card__container'>
                    <div className='v-box-row'>
                        <span className='v-box-row-head'><FormattedMessage id={`my-campaigns.table.name`}/></span>
                        <span className='v-box-row-val'>{campaign.DCAMPAIGN}</span>
                    </div>
                    <div className='v-box-row'>
                        <span className='v-box-row-head'><FormattedMessage id={`my-campaigns.table.price`}/></span>
                        <span className='v-box-row-val'>{CommonUtilities.formatNumber(campaign.TTC_COST, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}</span>
                    </div>
                    <div className='v-box-row'>
                        <span className='v-box-row-head'><FormattedMessage id={`my-campaigns.table.panels`}/></span>
                        <span className='v-box-row-val'>{campaign.PANELS_LIST ? campaign.PANELS_LIST.split(',').length : campaign.FRAMES_TOTAL}</span>
                    </div>
                    <div className='v-box-row'>
                        <span className='v-box-row-head'><FormattedMessage id={`my-campaigns.table.period`}/></span>
                        <div className='v-box-row-val'>
                            <div>
                                <FormattedMessage id="my-campaigns.table.fromDate"/> {moment(campaign.BEGIN_DATE,'YYYYMMDDhhmmss' ).format('DD/MM/YYYY').toString()}
                            </div>
                            <div>
                                <FormattedMessage id="my-campaigns.table.toDate"/> {moment(campaign.END_DATE,'YYYYMMDDhhmmss' ).format('DD/MM/YYYY').toString()}
                            </div>
                        </div>
                    </div>
                    <div className='v-box-row'>
                        <span className='v-box-row-head'><FormattedMessage id={`my-campaigns.table.status`}/></span>
                        <div className={`${campaign.STATUS_COD === 3 ? 'yellow-status' : (campaign.STATUS_COD === 4 ? 'green-status' : 'red-status')}`}>
                            {campaign.STATUS_COD === 3 ?
                            translate('my-campaigns.box.situation.await.approval.lowercase') 
                            : 
                            campaign.STATUS_COD === 4 && campaign.LIVE_STATUS_COD === 0 ? 
                            translate('my-campaigns.box.situation.approved.lowercase') 
                            : 
                            campaign.STATUS_COD === 4 && campaign.LIVE_STATUS_COD === 1 ?
                            translate('my-campaigns.box.situation.live')
                            :
                            campaign.STATUS_COD === 4 && campaign.LIVE_STATUS_COD === 2 ?
                            translate('my-campaigns.box.situation.completed')
                            :
                            translate('my-campaigns.box.situation.deleted')
                            }
                        </div>
                    </div>
                    <div className='v-box-row'>
                        <span className='v-box-row-head'><FormattedMessage id={`my-campaigns.table.action`}/></span>
                        <button className="button--tertiary-small item" type="button" onClick={() =>{gotoEditYourCampaign(campaign)}}><View20 /></button>
                    </div>
                </div>
            )
        }
    }

    const changeOrderCol = (colId) => {
        if(colId === colToOrderBy){
            let newOrderVal = orderByVal === 'desc' ? 'asc' : 'desc';
            setOrderByVal(newOrderVal);
        }else{
            setColToOrderBy(colId);
            setOrderByVal('desc');
        }
    }

    if(loading){
        return(<CircleProgress/>);
    }else{
        return(
        <div className="my-campaign">
            {loadingCalculation &&(
                <div>
                    <CircleProgress/>
                </div>
            )}
            <header className="section-layout brand-banner" style={campaigns.length > 0?{paddingBottom:0}:{}}>
                <div className="grid wrapper">
                    {campaigns.length > 0 ? 
                    <h2 className="h1brand dashboard-ttl">Dashboard</h2>
                    :
                    <>
                        <div className="texts col-desk-12 col-tab-6 col-mob-4">
                            <h1 className="h1brand margin"><FormattedMessage id="first.title"/></h1>
                            <h2 className="h2"><FormattedMessage id="first.subtitle" style={{width:'100%'}}/>&nbsp;
                                {/* <select className='locationSelect' onChange={handleLocationChange} value={selectedLocation}>
                                    {createLocationsList()}
                                </select> */country==='1'? <FormattedMessage id="my-campaigns.title.malls"/> : <FormattedMessage id="my-campaigns.title.stations"/>}
                            </h2>
                            <button className="button--primary-large  margin-top--05" aria-label="Nouvelle Campagne" type="button" name="credit" onClick={() =>{gotoYourCampaign()}}>
                                <AddCircleOutlineIcon />&nbsp;{campaigns.length === 0 ? <FormattedMessage id="first.buttonText"/> : <FormattedMessage id={'my-campaigns.newCampaignButton'}/>}
                            </button>
                        </div>
                        <div className="visual col-desk-12 col-tab-6 col-mob-4">
                            <img src="assets/HomePageNoCC.png"  alt="" style={{height:'425px'}}/>
                        </div>
                    </>}
                </div>
            </header>

            {campaigns.length > 0 && (
                <section className="section-layout">
                    <div className="grid wrapper">
                        <article className="card col-desk-24 col-tab-12 col-mob-4 space-between">
                            <h3 className="h3 margin">🔔&nbsp;<FormattedMessage id={'my-campaigns.box.title'}/></h3>
                            {campaigns.filter( campaign => ((campaign.STATUS_COD === 1) || (campaign.STATUS_COD === 2)) || campaign.REJECTED==='Y' ).length !==0 && campaigns.filter( campaign => ((campaign.STATUS_COD === 1) || (campaign.STATUS_COD === 2)) || campaign.REJECTED==='Y' ).length > fixedElementsToShow && (
                                <div className='flexed'>
                                    <div className={maxNumberCardsPerView === fixedElementsToShow ? 'arrow-carousel-disabled' : 'pointer-curs arrow-carousel'} disabled={maxNumberCardsPerView=== fixedElementsToShow} onClick={ maxNumberCardsPerView===fixedElementsToShow ? () => {} : () => handleShiftActionsRow('-')}><NavigateBeforeIcon/></div>
                                    <div className={!!blockForwardAction ? 'arrow-carousel-disabled' : 'pointer-curs arrow-carousel'} disabled={!!blockForwardAction} onClick={ !!blockForwardAction ? () => {} : () => handleShiftActionsRow('+')}><NavigateNextIcon/></div>
                                </div>)
                            }
                        </article>
                        <article className="card col-desk-24 col-tab-12 col-mob-4">
                            {campaigns.filter( campaign => ((campaign.STATUS_COD === 1) || (campaign.STATUS_COD === 2)) || campaign.REJECTED==='Y' ).length !== 0 ?
                            (<div className='list-pending-actions'>
                                {createCampaignsStatusBoxes( campaigns.filter( campaign => ((campaign.STATUS_COD === 1) || (campaign.STATUS_COD === 2)) || campaign.REJECTED==='Y' ))}
                            </div>)
                            :
                            (<div className='empty-pending-actions'>
                                <FormattedMessage id={'my-campaigns.actions.empty'}/>
                            </div>)
                            }
                        </article>
                    </div>
                </section>
            )}

            {campaigns.length > 0 && (
            <>
                <div className="phase2-container section-layout no-padding">  
                    <section className="wrapper">
                        <div className="layout-spacebetween">
                            <div className="layout-flex">
                                <h3 className="h3 margin-top--05 margin-left--02 margin-right--02"><FormattedMessage id={'campaigns.menu.my-campaigns'}/></h3>&nbsp;&nbsp;
                                <button className="button--primary-small margin-top--05" style={{borderRadius:'50px'}} aria-label="Nouvelle Campagne" type="button" name="credit" onClick={() => {gotoYourCampaign()}}>
                                    <FormattedMessage id={'my-campaigns.actions.create'}/>
                                    <AddCircleOutlineIcon />
                                </button>
                            </div>
                            <div className="layout-flex table-buttons">
                                <button className={`button--${selectedCampaignTableTab === 1 ? 'secondary' : 'tertiary'}-small margin-top--05 margin-right--02`} onClick={ () => setSelectedCampaignTableTab(1)}><FormattedMessage id="my-campaigns.box.situation.ongoing"/></button>
                                <button className={`button--${selectedCampaignTableTab === 2 ? 'secondary' : 'tertiary'}-small margin-top--05 margin-right--02`} onClick={ () => setSelectedCampaignTableTab(2)}><FormattedMessage id="my-campaigns.box.situation.running"/></button>
                                <button className={`button--${selectedCampaignTableTab === 3 ? 'secondary' : 'tertiary'}-small margin-top--05 margin-right--02`} onClick={ () => setSelectedCampaignTableTab(3)}><FormattedMessage id="my-campaigns.box.situation.ended"/></button>
                            </div>
                        </div>
                    </section>
                </div>
                <section className='section-layout'>
                        <div className='campaigns-table grid wrapper no-wrap'>
                            {windowWidth
                            ?
                            createVerticalTableView()
                            :
                            <>
                                <div className='head contrast-bg'>
                                    <div className='col' style={{width:'408px'}} onClick={() => changeOrderCol(1)}><span className='click'><FormattedMessage id={`my-campaigns.table.name`}/>&nbsp;{colToOrderBy === 1 ? <ArrowDownwardIcon className={`${orderByVal==='asc' ? 'asc-direction' : 'desc-direction'}`} fontSize='large'/> : ''}</span></div>
                                    <div className='col' style={{width:'136px'}} onClick={() => changeOrderCol(2)}><span className='click'><FormattedMessage id={`my-campaigns.table.price`}/>&nbsp;{colToOrderBy === 2 ? <ArrowDownwardIcon className={`${orderByVal==='asc' ? 'asc-direction' : 'desc-direction'}`} fontSize='large'/> : ''}</span></div>
                                    <div className='col' style={{width:'136px'}}><FormattedMessage id={`my-campaigns.table.panels`}/></div>
                                    <div className='col' style={{width:'204px'}} onClick={() => changeOrderCol(4)}><span className='click'><FormattedMessage id={`my-campaigns.table.period`}/>&nbsp;{colToOrderBy === 4 ? <ArrowDownwardIcon className={`${orderByVal==='asc' ? 'asc-direction' : 'desc-direction'}`} fontSize='large'/> : ''}</span></div>
                                    <div className='col' style={{width:'304px'}} onClick={() => changeOrderCol(5)}><span className='click'><FormattedMessage id={`my-campaigns.table.status`}/>&nbsp;{colToOrderBy === 5 ? <ArrowDownwardIcon className={`${orderByVal==='asc' ? 'asc-direction' : 'desc-direction'}`} fontSize='large'/> : ''}</span></div>
                                    <div className='col' style={{width:'136px'}}><FormattedMessage id={`my-campaigns.table.action`}/></div>
                                </div>
                                {
                                selectedCampaignTableTab === 1 ?
                                    <div>
                                        {createRowCampaignTable(campaigns.filter( campaign => ((campaign.STATUS_COD === 3) || (campaign.STATUS_COD === 4)) && campaign.LIVE_STATUS_COD === 0), 1)}
                                    </div>
                                :
                                selectedCampaignTableTab === 2 ?
                                    <div>
                                        {createRowCampaignTable(campaigns.filter( campaign => ((campaign.STATUS_COD === 4) && (campaign.LIVE_STATUS_COD === 1))), 2)}
                                    </div>
                                :
                                    <div>
                                        {createRowCampaignTable(campaigns.filter( campaign => ((campaign.STATUS_COD === 4) && (campaign.LIVE_STATUS_COD === 2)) || campaign.STATUS_COD === 99), 3)}
                                    </div>
                                }
                            </>
                            }
                        </div>
                </section>
            </>
            )}
            
        {campaigns.length === 0 && (
            <>
            <div className="phase2-container section-layout contrast-bg">
                <section className="wrapper">
                    <h3 className="h3 margin text-center">
                        🚀&nbsp;<FormattedMessage id={`first.description.${country}`}/>
                    </h3>
                    <p className="paragraph text-center">
                        <FormattedMessage id={`first.sub.description.${country}`}/>
                    </p>
            <section className="grid margin-top--06 margin-bottom--08 campaign-cards-stretch">
                <article className="card col-desk-6 col-tab-6 col-mob-4">
                    <div className="card__container">
                        <div className="card__container--image" style={{backgroundImage: 'url("assets/step-1.png")'}}></div>
                        <div className="card__container--content">
                            <span className="tag secondary margin-bottom--04"><FormattedMessage id="etape1"/></span>
                            <h4 className="h4 margin"><FormattedMessage id={`first.boxTitle1.${country}`}/></h4>
                            <p className="paragraph no-margin"><FormattedMessage id={`first.boxText1.${country}`}/></p>
                        </div>
                    </div>
                </article>
                <article className="card col-desk-6 col-tab-6 col-mob-4">
                    <div className="card__container">
                        <div className="card__container--image" style={{backgroundImage: 'url("assets/step-2.png")'}}></div>
                        <div className="card__container--content">
                            <span className="tag secondary margin-bottom--04"><FormattedMessage id="etape2"/></span>
                            <h4 className="h4 margin"><FormattedMessage id={`first.boxTitle2.${country}`}/></h4>
                            <p className="paragraph no-margin"><FormattedMessage id={`first.boxText2.${country}`}/></p>
                        </div>
                    </div>
                </article>

                <article className="card col-desk-6 col-tab-6 col-mob-4">
                    <div className="card__container">
                        <div className="card__container--image" style={{backgroundImage: 'url("assets/step-3.png")'}}></div>
                        <div className="card__container--content">
                            <span className="tag secondary margin-bottom--04"><FormattedMessage id="etape3"/></span>
                            <h4 className="h4 margin"><FormattedMessage id={`first.boxTitle3.${country}`}/></h4>
                            <p className="paragraph no-margin"><FormattedMessage id={`first.boxText3.${country}`}/></p>
                        </div>
                    </div>
                </article>

                <article className="card col-desk-6 col-tab-6 col-mob-4">
                    <div className="card__container">
                        <div className="card__container--image" style={{backgroundImage: 'url("assets/step-4.png")'}}></div>
                        <div className="card__container--content">
                            <span className="tag secondary margin-bottom--04"><FormattedMessage id="etape4"/></span>
                            <h4 className="h4 margin"><FormattedMessage id={`first.boxTitle4.${country}`}/></h4>
                            <p className="paragraph no-margin"><FormattedMessage id={`first.boxText4.${country}`}/></p>
                        </div>
                    </div>
                </article>
            </section>
            </section>
            </div></>)
        }

            {/* quando si preme sul bottone di delete lo stato di controllo per aprire il dialog equivale al kCampaign da eliminare */}
            <Dialog
              open={!!openDeleteDialog}
              onClose={handleCloseDeleteDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle>
                <h4 className="h4" style={{fontWeight: '400'}}>
                    <FormattedMessage id="my-campaigns.modal.delete.title"/>
                </h4>
              </DialogTitle>
              <DialogActions>
                <button className="button--quaternary-large" style={{padding:'0.6rem 1.4rem', color:'black'}} onClick={handleCloseDeleteDialog}>
                    <FormattedMessage id="modal.confirm.back.cancel"/>
                </button>
                <button className="button--destructive-small item" type="button" style={{padding:'0.6rem 1.4rem'}} onClick={deleteCampaign}>
                    <FormattedMessage id="my-campaigns.box.footer.button.del"/>
                    &nbsp;
                    <TrashCan20/>
                </button>
              </DialogActions>
            </Dialog>

        </div>
    );
}
}

export default injectIntl(MyCampaigns);