import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import config from '../../config'
import axios from 'axios';
import CookieConsent from "react-cookie-consent";
const LegalLinks = (props) =>{

    let availableLanguages = config.LANGUAGES;

    const [country, setCountry] = useState("");
    const [language, setLanguage] = useState(localStorage.getItem('linguaUtente')!==null
                                             ? 
                                             localStorage.getItem('linguaUtente').toLowerCase()
                                             :
                                             availableLanguages.includes(navigator.language.substring(0,2))
                                             ?
                                             navigator.language.substring(0,2)
                                             :
                                             'en' );

    useEffect(() =>  {
        async function init(){
            //stringa vuota lo ottengo solo se arrivo da una pagina non autenticata.
            if(props.country!==''){
                setCountry(props.country);
            }else{
                //await getGeoInfo();
            }
        }
        init();
    },[])

    const getGeoInfo = async() => {
        try{
            let response = await axios.get('https://ipapi.co/json/');
            let data = response.data;
            setCountry(data.country);
        }catch(error){
            //console.log(error);
        };
    };

    const getLegalByCountry   = () => {
        return '/';
        switch(country){
            case 'FR':
            case  '1':
                return "/docsFR/LaunchPAD Direct-Mentions Legales.pdf";
            case 'BE':
            case  '2':
                return `/docsBE/LaunchPAD Direct-Mentions Légales_${language}.pdf`;
            default:
                return `/docsBE/LaunchPAD Direct-Mentions Légales_${language}.pdf`;
        }
    }

    const getTermsByCountry   = () => {
        return '/';
        switch(country){
            case 'FR':
            case  '1':
                return "/docsFR/LaunchPAD Direct-CGU_fr.pdf";
            case 'BE':
            case  '2':
                return `/docsBE/LaunchPAD Direct-CGU_${language}.pdf`;
            default:
                return `/docsBE/LaunchPAD Direct-CGU_${language}.pdf`;
        }
    }

    const getCookiesByCountry = () => {
        return '/';
        switch(country){
            case 'FR':
            case  '1':
                return "/docsFR/CCF Privacy and Cookies Notice Version_FR.pdf";
            case 'BE':
            case  '2':
                return `/docsBE/CCI-Privacy-and-Cookies_${language}.pdf`;
            default:
                return `/docsBE/CCI-Privacy-and-Cookies_${language}.pdf`;
        }
    }

    const getSupportUrlByCountry = () => {
        return '/'
        switch(country){
            case 'FR':
            case  '1':
                return "";
            case 'BE':
            case  '2':
                return `https://www.clearchannel.be/${language==='fr'?'':language+'/'}legal`;
            default:
                return `https://www.clearchannel.be/${language==='fr'?'':language+'/'}legal`;
        }
    }

    return (
        <div>
            <a href={getLegalByCountry()  } target="_blank" rel="noreferrer"><FormattedMessage id={"legalLinks.legals"}/></a>&nbsp;|&nbsp;
            <a href={getTermsByCountry()  } target="_blank" rel="noreferrer"><FormattedMessage id={"legalLinks.generals"}/></a>&nbsp;|&nbsp;
            <a href={getCookiesByCountry()} target="_blank" rel="noreferrer"><FormattedMessage id={"legalLinks.cookies"}/></a>
            {(country==='BE' || country==='2') &&(<>&nbsp;|&nbsp;<a href={getSupportUrlByCountry()} target="_blank" rel="noreferrer"><FormattedMessage id={"legalLinks.report"}/></a></>)}
            <CookieConsent
                enableDeclineButton
                style={{ background: "#FFFFFF",  color: "#171c29", boxShadow: "0 -3px 6px rgb(0 0 0 / 10%)"}}
                buttonText={<FormattedMessage id={'cookies.accept'} />}
                buttonClasses="button--primary-large"
                buttonStyle={{ backgroundImage: "linear-gradient(to right, #006AE3, #479bfb, #006AE3, #479bfb)", transition: "all 0.5s ease", color: "white", fontWeight: 500, backgroundSize: "300% 100%", borderRadius: "0.4rem", padding: "1.6rem 2.4rem" }}
                declineButtonText={<FormattedMessage id={'cookies.refuse'} />}
                declineButtonStyle={{ backgroundImage: "linear-gradient(to right, #006AE3, #479bfb, #006AE3, #479bfb)", transition: "all 0.5s ease", color: "white", fontWeight: 500, backgroundSize: "300% 100%", borderRadius: "0.4rem", padding: "1.6rem 2.4rem" }}
                declineButtonClasses="button--primary-large"
                buttonWrapperClasses="buttons-cookie-wrapper"
                contentClasses="cookies-info-container"
                expires={395}
                onAccept={()=>window.location.reload()}
                onDecline={()=>window.location.reload()}
                >
                <p> 
                    <FormattedMessage id={'cookies.text'} />
                    <br />
                    <a href={getCookiesByCountry()} rel="noreferrer" target="_blank" className='cookies-banner-link'><FormattedMessage id={'cookies.more'} /></a>
                </p>
            </CookieConsent>
        </div>
    )
}

export default LegalLinks
