import React, { useState, useEffect, useContext }   from 'react';
import                                       "./CampaignSummary.css";
import { AuthContext }                  from '../../contexts/authContext';
import axios                            from 'axios';
import CommonUtilities                  from "../../util/CommonUtilities";         
import { FormattedMessage, injectIntl } from 'react-intl';              
import NavigateNextIcon                 from '@mui/icons-material/NavigateNext';    
import Dialog                           from '@mui/material/Dialog';                
import MuiDialogTitle                   from '@mui/material/DialogTitle';           
import MuiDialogContent                 from '@mui/material/DialogContent';         
import DialogActions                    from '@mui/material/DialogActions';         
import { withStyles, makeStyles  }                  from '@mui/styles';          
import Typography           from '@mui/material/Typography';            
import IconButton           from '@mui/material/IconButton';            
import CloseIcon            from '@mui/icons-material/Close';           
import moment from "moment";                                            
import LegalLinks from '../../components/LegalLinks';                   
import { useHistory } from 'react-router-dom'                           
import { NotificationManager } from 'react-notifications';
import { createTheme, ThemeProvider } from "@mui/material";
import config from '../../config';
import CircleProgress from '../../util/CircleProgress';
import { Language } from '../../App'
import { CalendarHeatMap32, Screen32, Events32, TimePlot32, PlayOutline32, FitToWidth32, VideoChat32, Calendar32, Train32, Store32 }   from '@carbon/icons-react';
import WarningAmberIcon                             from '@mui/icons-material/WarningAmber';

const regexEmailValidator    = /^[a-z0-9]+([.\-_]?[a-z0-9]+)+@[a-z0-9]+([.\-_]?[a-z0-9]+)+\.[a-z]{2,}$/;

const checkEmail = (value) => value.length > 50 || regexEmailValidator.test(value);

const checkLessThan5Days = (beginDate) => {
    let checkDay = moment().set({ 'hour': 0, minute: 0, second: 0 });
    for(let i=0; i<3; i++){
        checkDay = checkDay.add(1, 'days');
        if(checkDay.format('ddd') === 'Sat'){
            checkDay = checkDay.add(2, 'days');
        }else if(checkDay.format('ddd') === 'Sun'){
            checkDay = checkDay.add(1, 'days');
        }
    }
    const campaignDate = beginDate.set({ 'hour': 0, minute: 0, second: 0 });
    if(campaignDate.isBefore(checkDay)){
        return true;
    }
    return false;
}

const checkReadyForPayment = (campaign) => {

    // controllo se possibile fare il pagamento diretto:
    // - la campagna si trova nello stato 2 (slot prenotati e attesa di pagamento)
    // - la campagna si trova nello stato 1 e ci sono slot liberi per la sua configurazione
    // - le date della campagna continuano ad essere valide

    if(campaign.STATUS_COD > 2){
        return false;
    }

    if(campaign.STATUS_COD === 2){
        return true;
    }

    if(campaign.STATUS_COD === 1 && campaign.SLOT_FREE === 'N'){
        return false;
    }
    
    let checkDay = moment();

    for(let i=0; i<3; i++){
        checkDay = checkDay.add(1, 'days');
        if(checkDay.format('ddd') === 'Sat'){
            checkDay = checkDay.add(2, 'days');
        }else if(checkDay.format('ddd') === 'Sun'){
            checkDay = checkDay.add(1, 'days');
        }
    }

    const campaignDate = moment(campaign.BEGIN_DATE, 'YYYYMMDD');
    campaignDate.set({ 'hour': 23, minute: 59, second: 59 });

    if(campaignDate.isBefore(checkDay)){
        return false;
    }

    return true;
}

const CampaignSummaryPay = (props) =>{
    //state params
    const [country, setCountry] = useState(localStorage.getItem('country'));
    const [locationName, setLocationName] = useState(localStorage.getItem('locationName'));
    //vengono inizializzati dal localStorage se !== null (in quanto si potrebbe arrivare da altre pagine con dei parametri) o con un valore di default
    const [kCampaign, setKCampaign] = useState(undefined);
    const [statusCod , setStatusCod] = useState(undefined);
    const [campaignName, setCampaignName] = useState(undefined);
    const [floor, setFloor] = useState(undefined);
    const [giant, setGiant] = useState(undefined);
    const [allFrames, setAllFrames] = useState(undefined);
    const [daypart, setDaypart] = useState(undefined);
    const [beginDate, setBeginDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [priceMedia, setPriceMedia] = useState(undefined);
    const [priceAdv, setPriceAdv] = useState(undefined);
    const [priceTva, setPriceTva] = useState(undefined);
    const [priceTtc, setPriceTtc] = useState(undefined);     
    const [price, setPrice] = useState(undefined);
    const [framesSelected, setFramesSelected] = useState(undefined);
    const [days, setDays] = useState(undefined);
    const [slotSelected, setSlotSelected] = useState(undefined);
    const [numberOfSpot, setNumberOfSpot] = useState(undefined);    
    const [estimatedAvgContacts, setEstimatedAvgContacts] = useState(undefined);
    const [gloohCreativeId, setGloohCreativeId] = useState(undefined);    
    const [gloohBriefId, setGloohBriefId] = useState(undefined);    
    const [gloohRendered, setGloohRendered] = useState(undefined);
    const [customCreativeId, setCustomCreativeId] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [loadingConfirm, setLoadingConfirm] = useState(false);
    const [showPayModal, setShowPayModal] = useState(false);
    const [showBankTransferModal, setShowBankTransferModal] = useState(false);
    const [email, setEmail] = useState(localStorage.getItem('user_email'));
    const [emailCheck, setEmailCheck] = useState(true);
    const [emailBankTransfer, setEmailBankTransfer] = useState(email)
    const [nomeUtente, setNomeUtente] = useState(localStorage.getItem('name'));
    const [cognomeUtente, setCognomeUtente] = useState(localStorage.getItem('surname'))
    const [lessThan5Days, setLessThan5Days] = useState(false);
    const [daypartList, setDaypartList] = useState([]);
    const [framesLevels, setFramesLevels] = useState([]);
    const [panelsSelected, setPanelsSelected] = useState([]);

    const [isCustomCreative, setIsCustomCreative] = useState(false);
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [loadingUpload,setLoadingUpload] = useState(false);

    const [panelsList, setPanelsList] = useState('');

    const onlinePaymentDisabled = parseInt(priceTtc) > 5000;

    const [listaKLocation, setListaKLocation] = useState(null);

    const [genCond, setGenCond] = useState(false);

    const [promoCode, setPromoCode] = useState('');
    const [prevPromoCode, setPrevPromoCode] = useState('');
    const [showPromoCodeError, setShowPromoCodeError] = useState(false);

    const [originalPrice, setOriginalPrice] = useState(undefined);
    const [originalTvaPrice, setOriginalTvaPrice] = useState(undefined);
    const [originalTtcPrice, setOriginalTtcPrice] = useState(undefined);
    
    const [showAudience, setShowAudience] = useState(false);
    const history = useHistory()

    const auth = useContext(AuthContext);
    const {language, setLanguage} = useContext(Language);

    const [confirmBackDialog, setConfirmBackDialog] = useState(false);
    //booleano per il warning nel caso ci siano state modifiche lato admin
    //sulle impostazioni di una stazione o sui suoi pannelli/gruppi.
    const [warning, setWarning] = useState(false);

    //promoCode status
    const [promoCodeDiscount, setPromoCodeDiscount] = useState(localStorage.getItem('promoCodeDiscount') !== null ? parseFloat(localStorage.getItem('promoCodeDiscount')) : '');
    const [alreadyUsedPromoCode, setAlreadyUsedPromoCode] = useState(false);
    const countriesArray = config.COUNTRIES_LABELS;
    let urlCountry = countriesArray[country-1];

    useEffect(() => {
        async function init(){

            const stringParameters = new URLSearchParams( '?' + window.location.href.split('?')[1]);
            //console.log('stringParameters', stringParameters)
            //console.log('stringParameters', stringParameters.has('stripe-id'))
            //console.log('stringParameters', stringParameters.has('client-secret'))
            //la payed viene solo lanciata se si ritorna dal pagamento di stripe
            if(stringParameters.has('stripe-status')){
                if(stringParameters.get('stripe-status')){
                    NotificationManager.error( <FormattedMessage id={'campaign.summaryPay.failedPayment'}/>, <FormattedMessage id={'error'}/>, 0);
                }
            }

            setKCampaign(props.match.params.id);
            await getCampaign(props.match.params.id);

            let url = `${config.API_URL}/dayparts`;

            let params = {
                kCountry: country,
            }

            await axios.get(
                url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    },params  
                }
            ).then((response) => {
                //console.log(response.data)
                setDaypartList(response.data);
            }, (error) => {
                //console.log(error)
                if (!error.response){
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 0);
                    history.push('/connection-error');
                    return;
                }else if(error.response.status === 401){ //status 401: Unauthorized
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                    auth.signOut();
                    history.push('/signin');
                }else if(error.response.status ===500){
                    history.push('/connection-error');
                }else{
                    history.push('/connection-error');
                }
            });
            setLoading(false);
        }
        init();
    }, []);

    useEffect(() => {        
        localStorage.setItem('kCampaign', kCampaign);
    }, [kCampaign]);

    useEffect(() => {
        if(beginDate !== undefined && endDate !== undefined){
            if(beginDate.startOf('day').isAfter(endDate.startOf('day'))){
                setEndDate(beginDate.clone());
            }

            const duration = moment.duration(endDate.diff(beginDate));
            const days = parseInt(duration.asDays()) + 1;
            setDays(days);

            setLessThan5Days(checkLessThan5Days(beginDate));
        }
    }, [beginDate, endDate]);

    const gotoMyCampaigns = () => {
        history.push('/my-campaigns');
    }
    
    const gotoYourCampaign = () => {
        localStorage.setItem( 'listaKLocation', listaKLocation );
        localStorage.setItem('kCampaign', kCampaign);
        history.push(`/${urlCountry}/your-campaign/${kCampaign}`);
    }

    const getCampaign = async(kCampaign) => {
        
        let response = '';
        let panelsToCheck = [];
        let pricesToCheck = {}; 

        try{
            //si carica una campagna già esistente
            let params = {
                kCampaign,
                savedData: 'Y'
            };
        
            let url = `${config.API_URL}/campaign`;
            response = await axios.get(
                url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    },params  
                }
            )

            if(response.status === 200){
                //console.log('campaign-summary-pay', response.data)
                const data = response.data;
                setCampaignName(data.DCAMPAIGN);
                setStatusCod(data.STATUS_COD);
                setBeginDate(moment(data.BEGIN_DATE));
                setEndDate(moment(data.END_DATE));
                setFloor(data.FRAMES_FLOOR);
                setGiant(data.FRAMES_GIANT === 'Y' ? true : false);
                setAllFrames(data.FRAMES_ALL === 'Y' ? true : false);
                setDaypart(!data.KDAYPART ? 0 : data.KDAYPART);
                setNumberOfSpot(data.NUMBER_OF_SPOT);   
                setEstimatedAvgContacts(data.ESTIMATED_AVG_CONTACTS);
                setPrice(data.TOTAL_COST);
                setPriceMedia(data.MEDIA_COST);
                setPriceAdv(data.ADV_COST);
                setPriceTva(data.TVA_COST);
                setPriceTtc(data.TTC_COST);                  
                //commentato per il problema con pSavedData pari ad 'Y' nella getCampaign(s)
                //setFramesSelected(data.FRAMES_SELECTED);
                setGloohCreativeId(data.GLOOH_CREATIVE_ID);
                setGloohBriefId(data.GLOOH_BRIEF_ID);
                setGloohRendered(data.GLOOH_RENDERED === 'Y' ? true : false);
                setSlotSelected(data.SLOT_SELECTED);
                setCustomCreativeId(data.CUSTOM_CREATIVE_ID);
                setShowAudience(data.SHOW_AUDIENCE === 'Y' ? true : false);
                setPanelsSelected(JSON.parse(data.PANELS_SELECTED));
                setListaKLocation(data.LISTA_KLOCATION);
                setPromoCodeDiscount(data.PROMO_CODE_DISCOUNT);
                setOriginalPrice(response.data.TOTAL_COST_SAVE);
                setOriginalTvaPrice(response.data.TVA_COST_SAVE);
                setOriginalTtcPrice(response.data.TTC_COST_SAVE);
                if(country==='1'){
                    let matchPackage = JSON.parse(data.FRAMES_LEVELS).find(framesLevels => framesLevels.kLevel.toString() === data.FRAMES_FLOOR);
                    setFramesSelected(JSON.parse(data.PANELS_SELECTED).length);
                    setFramesLevels(matchPackage);
                }else{
                    setPanelsList(data.PANELS_LIST);
                    setFramesSelected(data.PANELS_LIST.split(',').length);
                }
                if(data.CUSTOM_CREATIVE_ID){
                    setIsCustomCreative(true);
                }
                //parametri di appoggio del confronto
                panelsToCheck = country==='1' ? data.PANELS_SELECTED : data.PANELS_LIST;
                pricesToCheck = {
                    price: data.TOTAL_COST,
                    priceMedia: data.MEDIA_COST,
                    priceTva: data.TVA_COST,
                    priceTtc: data.TTC_COST
                };
            }else{
                history.push('/my-campaigns');
                return;
            }
            //confronto tra campagna salvata e campagna calcolata per controllare che
            //la stazione o un pacchetto non siano state modificate
            params = {
                kCampaign,
                savedData: 'N'
            };

            response = await axios.get(
                url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    },params  
                }
            )

            if (response.status === 200){
                const data = response.data;
                //console.log('datata',data);
                //console.log(pricesToCheck); 
                if(country !== '1'){
                    data.MEDIA_COST = null;
                }
                let panelsForControl = country === '1' ? data.PANELS_SELECTED : data.PANELS_LIST;
                //console.log('data.PANELS_LIST con saved N', data.PANELS_LIST)
                if(country==='1'){
                    return;
                }else{
                    if(panelsToCheck !== panelsForControl){
                        setWarning(true);
                        return;
                    }else if(pricesToCheck.price      !== data.TOTAL_COST ||
                            pricesToCheck.priceMedia !== data.MEDIA_COST ||
                            pricesToCheck.priceTva   !== data.TVA_COST   ||
                            pricesToCheck.priceTtc   !== data.TTC_COST){
                                setWarning(true);
                                return;
                    }
                }
            }

        }catch (error){
            if (!error.response){
                NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 0);
                history.push('/connection-error');
                return;
            }else if(error.response.status === 401){ //status 401: Unauthorized
                NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                setLoading(true);
                auth.signOut();
                history.push('/signin');
            }else if(error.response.status ===500){
                history.push('/connection-error');
            }else{
                history.push('/connection-error');
            }
        }
    };

    const handleTogglePayModal = () => {
        if(country === '1' && priceTtc < 0.50){
            confirmCampaign();
        }else if((country === '2' || country === '3') && price < 0.50){
            confirmCampaign();
        }else{
            setShowPayModal(!showPayModal);
        }
    }

    const confirmCampaign = (event, bancontact = false) => {
        setLoadingConfirm(true);
        //con questa chiamata vengono riservati gli spazi e la campagna passa in stato confermato (2)
        const params = {
            kCampaign,
            slotSelected,
            po : 'N',
            totalCost: price
        };
        //console.log('params confirm', params)
        const url = `${config.API_URL}/campaign-confirm`;

        axios.post(
            url,
            params,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                }
            }
            ).then((response) => {
                //console.log('response confirm', response);
                payWithStripe(bancontact);
                //gotoMyCampaigns()
            }, (error) => {
                if (!error.response){
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 0);
                    history.push('/connection-error');
                    return;
                }else if(error.response.status === 401){ //status 401: Unauthorized
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                    setLoading(true);
                    auth.signOut();
                    history.push('/signin');
                }else if(error.response.status ===500){
                    switch(error.response.data){
                        case -101:
                            NotificationManager.error( <FormattedMessage id={'notificationManager.configurationError.text'}/>, <FormattedMessage id={'notificationManager.configurationError.title'}/>, 5000);
                            setLoadingConfirm(false);
                            setShowPayModal(false);
                            gotoYourCampaign();//reinderizzo l'utente alla your-campaign nel caso le disponibilità siano state prese da un altro utente
                            break;
                        default:
                            NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 5000);
                            setLoadingConfirm(false);
                            setShowPayModal(false);
                            break;
                    }
                }else{
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 5000);
                    setLoadingConfirm(false);
                    setShowPayModal(false);
                }
            });
    }

    const payWithStripe = (bancontact) => {
        if((!onlinePaymentDisabled || country !== '1') && !bancontact){
            document.checkout.submit();
            //setLoadingDialog(false);
        }else if((!onlinePaymentDisabled || country !== '1') && bancontact){
            document.checkoutBancontact.submit();
        }
    }

    const payWithBankTransfer = () => {
        setShowPayModal(false);
        setShowBankTransferModal(true);
    }

    const handleToggleBankTransferModal = () => {
        setShowBankTransferModal(!showBankTransferModal);
    }

    const handleClosePayModal = () => {
        setShowPayModal(false);
    }

    const handleChange = (event) =>{
        setEmailBankTransfer(event.target.value);
        checkEmail(event.target.value) ? setEmailCheck(true) : setEmailCheck(false);
    }

    const confirmPoCampaign = () => {
        setLoadingConfirm(true);
        //con questa chiamata vengono riservati gli spazi (se non già riservarti) e la campagna passa in stato confermato (3) per il pagamento con Bonifico bancario
        const params = {
            kCampaign,
            slotSelected,
            po : 'Y',
            totalCost: price
        };

        //console.log('params confirmPo', params)
        const url = `${config.API_URL}/campaign-confirm`;

        axios.post(
            url,
            params,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                }
            }
            ).then((response) => {
                setLoadingConfirm(false);
                //console.log('response confirmPo', response);
                gotoCampaignFinal()
            }, (error) => {
                if (!error.response){
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 0);
                    history.push('/connection-error');
                    return;
                }else if(error.response.status === 401){ //status 401: Unauthorized
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                    setLoading(true);
                    auth.signOut();
                    history.push('/signin');
                }else if(error.response.status ===500){
                    switch(error.response.data){
                        case -101:
                            NotificationManager.error( <FormattedMessage id={'notificationManager.availabilitiesError.text'}/>, <FormattedMessage id={'notificationManager.availabilitiesError.title'}/>, 5000);
                            setLoadingConfirm(false);
                            setShowPayModal(false);
                            gotoYourCampaign();//reinderizzo l'utente alla your-campaign nel caso le disponibilità siano state prese da un altro utente
                            break;
                        default:
                            NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 5000);
                            setLoadingConfirm(false);
                            setShowPayModal(false);
                            break;
                    }
                }else{
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 5000);
                    setLoadingConfirm(false);
                    setShowPayModal(false);
                }
            });
    }    

    const gotoCampaignFinal = () => {
        history.push(`/${urlCountry}/campaign-final/${kCampaign}`);
    } 

    const {intl} = props;

    const translate = (label) =>  intl.formatMessage({id: label});

    const theme = createTheme();

    const daypartLabel = () =>{
        let fasciaOraria = daypartList.find(element => element.KDAYPART===daypart)||{};
        return( <FormattedMessage id={'your-campaigns.'+(fasciaOraria.KDAYPART===daypart ? fasciaOraria.DDAYPART : 'daypart.full')}/>)
    }

    const createPanelsSelectionInfo = () => {
        let arrayPanelsList = country === '1' ? panelsSelected.map(faccia => faccia.kFace+'') : panelsList.split(',');
        return panelsSelected.filter(pannello => arrayPanelsList.includes(pannello.kFace+'')).map( selectedPanel => {

                let titleContent =
`${translate('campaign.summary.panel.address')}: ${selectedPanel.address}
${translate('campaign.summary.panel.dimensions')}: ${selectedPanel.dimensions}
${translate('campaign.summary.panel.resolution')}: ${selectedPanel.resolution}`
            return ( <p className='margin-bottom--00' title={titleContent} style={{cursor:'default'}}>{selectedPanel.digitalId || 'N.A.'}</p>)    
            }
        )
    };

    const handleClickGenCond = () => {
        setGenCond(!genCond);
    };

    const handleConfirmCloseBackDialog = () =>{
        setConfirmBackDialog(false);
    };
    
    const handleClosePreviewDialog = () => {
        setOpenPreviewDialog(false);
    };

    const gotoLocationSelection = () => {
        history.push(`/${urlCountry}/location-selection`);
    };

    const gotoCampaignCreative = () => {
        history.push(`/${urlCountry}/campaign-creative`);
    } 

    const openPreview = async() => {
        if(videoUrl===''){
            setLoadingUpload(true);
            let response = '';
            let url = `${config.API_URL}/content`;
            let params = {
                contentUrl: `videos/portrait/${gloohCreativeId ? gloohCreativeId : customCreativeId}.mp4`
            };
            try{
                response = await axios.get(url, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    },
                    params  
                });
                if (response.status===200){
                    let url = response.data
                    //console.log('url', url);
                    setVideoUrl(url);
                }
            }catch(err){
                //console.log('err', err)
            }
            setLoadingUpload(false);
        }
        setOpenPreviewDialog(true);
    };

    const handleChangePromoCode = (event) => {
        setPromoCode(event.target.value);
        if(event.target.value===''){
            setShowPromoCodeError(false);
            setAlreadyUsedPromoCode(false);
        }
    }

    const handleSetPromoCode = async(reset = false) => {
        
        setShowPromoCodeError(false);
        setAlreadyUsedPromoCode(false);

        if(reset){
            setPromoCode('');
        }
        setLoadingUpload(true);

        let params = {
            kCampaign,
            promoCode: reset===true ? '' : promoCode
        }
    
        let url = `${config.API_URL}/promo-code-set`;

        let response = '';

        try {
            response = await axios.post(
                url,
                params,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    }
                }
            )
            if(response.status === 200){
                setShowPromoCodeError(false);
                setAlreadyUsedPromoCode(false);
                params={
                    kCampaign,
                    savedData: 'Y'
                }

                url = `${config.API_URL}/campaign`;

                response = await axios.get(
                    url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                        },
                        params  
                    }
                )

                if(response.status === 200){
                    localStorage.setItem('promoCodeDiscount', !response.data.PROMO_CODE_DISCOUNT ? '' : response.data.PROMO_CODE_DISCOUNT);
                    localStorage.setItem('price', response.data.TOTAL_COST);
                    localStorage.setItem('priceTva', response.data.TVA_COST);
                    localStorage.setItem('priceTtc', response.data.TTC_COST);
                    setPromoCodeDiscount(!response.data.PROMO_CODE_DISCOUNT ? '' : response.data.PROMO_CODE_DISCOUNT);
                    setPrice(response.data.TOTAL_COST);
                    setPriceTva(response.data.TVA_COST);
                    setPriceTtc(response.data.TTC_COST);

                    localStorage.setItem('originalPrice', response.data.TOTAL_COST_SAVE);
                    localStorage.setItem('originalPriceTva', response.data.TVA_COST_SAVE);
                    localStorage.setItem('originalPriceTtc', response.data.TTC_COST_SAVE);
                    setOriginalPrice(response.data.TOTAL_COST_SAVE);
                    setOriginalTvaPrice(response.data.TVA_COST_SAVE);
                    setOriginalTtcPrice(response.data.TTC_COST_SAVE);
                }
            }
        } catch (error) {
            if (!error.response){
                setShowPromoCodeError(true);
                setPrevPromoCode(promoCode);
            }else if(error.response.status === 401){ //status 401: Unauthorized
                NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                auth.signOut();
                history.push('/signin');
                return;
            }else if(error.response.status ===500){
                setShowPromoCodeError(true);
                setPrevPromoCode(promoCode);
            }else if(error.response.status === 502){ //promoCode non valido per il periodo
                setShowPromoCodeError(true);
                setPrevPromoCode(promoCode);
            }else if(error.response.status === 503){ //promoCode già utilizzato
                setAlreadyUsedPromoCode(true);
            }
        }
        setLoadingUpload(false);
    }

    const styles = makeStyles((theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            backgroundColor: '#e8e8e8',
            fontStyle:'normal'
        },
        rootBt: {
            margin: 0,
            padding: theme.spacing(2),
            backgroundColor: '#e8e8e8',
            fontStyle:'normal'
        },    
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: '#171C29',
        },
    }));
    
    const DialogContent = withStyles((theme) => ({
        root: {
          padding: 0,
          backgroundColor: '#e8e8e8',
        },
     }))(MuiDialogContent);
      
     const DialogContentBt = withStyles((theme) => ({
        root: {
          padding: 0,
          backgroundColor: '#e8e8e8',
        },
     }))(MuiDialogContent);
    
    const DialogTitle = ((props) => {
        const classes = styles()
        const { children, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
                </IconButton>
            ) : null}
            </MuiDialogTitle>
        );
    });
    
    const DialogTitleBt = ((props) => {
        const classes = styles()
        const { children, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.rootBt} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
                </IconButton>
            ) : null}
            </MuiDialogTitle>
        );
    });

    if(loading){
        return(<CircleProgress/>)
    }else{ 
        return(
            <div className="campaign-summary">
            {loadingUpload &&(
            <div><CircleProgress/></div>)}
            <ThemeProvider theme={theme}>
            {loadingConfirm &&(<div style={{height:'100%',zIndex:2000000, backgroundColor:'rgba(255, 255, 255, 60%)', width:'100%', position:'absolute', top:'0px'}}><CircleProgress/></div>)}
                <Dialog onClose={handleTogglePayModal} aria-labelledby="simple-dialog-title" open={showPayModal}>
                    {country==='1'&&(<DialogTitle style={{backgroundColor:'rgb(255, 255, 255)'}} id="simple-dialog-title" onClose={handleTogglePayModal} ><h4 className="h4"><FormattedMessage id={"campaign.summaryPay.dialogTitle"}/></h4></DialogTitle>)}
                    {(country==='2' || country==='3')&&(<DialogTitle style={{backgroundColor:'rgb(255, 255, 255)'}} id="simple-dialog-title" onClose={handleTogglePayModal} ><h4 className="h4"><FormattedMessage id={"campaign.summaryPay.be.title.confirm"}/></h4></DialogTitle>)}
                    <DialogContent>
                        {country==='1' && (<><div className="pay-dialog-content">
                            <div className={onlinePaymentDisabled ? "img-link disabled" : "img-link"} onClick={onlinePaymentDisabled ? ()=>{} : (event) => confirmCampaign(event)}>
                                <img src="assets/Malls_Card_Illustration@2x.png"/>
                                <br />
                                CB
                            </div>
                            <div className="img-link link2" onClick={payWithBankTransfer}>
                                <img src="assets/Malls_Contract_Illustration@2x.png"/>
                                <br />
                                <FormattedMessage id={"campaign.summaryPay.dialogTransfer"}/>
                            </div>
                        </div>
                        <DialogActions style={{borderTop: '1px solid #dddddd', backgroundColor:'rgb(255, 255, 255)'}}>
                        <div className="be-confirm">
                            <FormattedMessage id={'campaign.summaryPay.be.text'}></FormattedMessage>
                        </div>
                        </DialogActions></>)}
                        {(country==='2' || country==='3') && (<><div className="pay-dialog-content">
                           {/*  <FormattedMessage id={'campaign.summaryPay.be.text'}></FormattedMessage> */}
                           <div className={kCampaign==='new' ? "img-link disabled" : "img-link"} onClick={(event) => confirmCampaign(event)}>
                                <img src="assets/Malls_Card_Illustration@2x.png" alt='payByCard'/>
                                <br />
                                <FormattedMessage id={"campaign.summaryPay.card"}/>
                            </div>
                            {(country==='2')&&(<div className={kCampaign==='new' ? "img-link disabled link2" : "img-link link2"} onClick={(event)=>confirmCampaign(event,true)}>
                                <img src="assets/Bancontact_logo.png" alt='payByBancontact'/>
                                <br />
                                <FormattedMessage id={"campaign.summaryPay.bancontact"}/>
                            </div>)}
                        </div>
                    <DialogActions style={{borderTop: '1px solid #dddddd', backgroundColor:'rgb(255, 255, 255)'}}>
                    <div className="be-confirm">
                                <FormattedMessage id={'campaign.summaryPay.be.text'}></FormattedMessage>
                            </div>
                    {/* <button className="button--secondary-large" type="button" onClick={handleClosePayModal} style={{border: '1px solid #dddddd', margin: 0, marginRight: 10}}><FormattedMessage id={"campaign.summaryPay.dialog.transfer.abort"}/></button>
                    <button className="button--primary-large" type="button" onClick={confirmCampaign} style={{margin: 0}}><FormattedMessage id={"campaign.summaryPay.confirm"}/></button> */}
                    </DialogActions></>)}
                        {onlinePaymentDisabled && country==='1' &&(<p className="message--error" style={{textAlign:'center'}}><FormattedMessage id={"campaign.summaryPay.dialogWarning"}/></p>)}
                    </DialogContent>
                </Dialog>

                <Dialog onClose={handleToggleBankTransferModal} aria-labelledby="simple-dialog-title" open={showBankTransferModal} fullWidth maxWidth="sm">
                {loadingConfirm &&(<div style={{height:'100%',zIndex:20, backgroundColor:'rgba(255, 255, 255, 60%)', width:'100%', position:'absolute', transition:'background-color 0.3s'}}><CircleProgress/></div>)}    
                    <DialogTitleBt id="simple-dialog-title" onClose={handleToggleBankTransferModal} ><span className="pay-dialog-title"><FormattedMessage id={"campaign.summaryPay.dialog.transfer.title"}/></span></DialogTitleBt>
                    <DialogContentBt>
                    <div className="pay-dialog-content">
                        <div className="bank-transfer-email-wrapper">
                            <div className="bank-transfer-email">
                            
                                <div className="input-group more-margin">
                                    <label htmlFor="email">{translate('email')} *</label>
                                    <input data-cy="email" data-testid="email" type="email" name="email" autoFocus="true" maxLength={100} value={emailBankTransfer} onChange={handleChange}/>
                                    {!emailCheck && (<p className="message--error"><FormattedMessage id={"signup.errEmail"}/></p>)}
                                </div>

                                {lessThan5Days && (
                                    <div className="less-than-five"><FormattedMessage id={"campaigns.pay-close"}/></div>
                                )}
                            </div>
                        </div>
                    </div>      
                    </DialogContentBt>
                    <DialogActions style={{borderTop: '1px solid #dddddd'}}>
                        <button className="button-secondary" type="button" onClick={handleToggleBankTransferModal} style={{border: '1px solid #dddddd', margin: 0, marginRight: 10}}>Annuler</button>
                        <button className="button-classic" type="button" disabled={!emailCheck} onClick={confirmPoCampaign} style={{margin: 0}}><FormattedMessage id={"campaign.summaryPay.dialog.transfer.confirm"}/></button>
                    </DialogActions>             
                </Dialog>


                <header className="heading">
                <div className="heading-content">
                    <h1 className="h1">{campaignName}</h1>
                    {country==='1' ? (<div className="breadcrumb margin-top--05">

                        <button className={`breadcrumb--item completed ${statusCod===2 ? 'default-curs' : ''}`} type="button" onClick={()=> statusCod===2 ? {} : gotoYourCampaign()}>
                            <span className='step-number'>1</span>
                            <FormattedMessage id={"breadcrumb.step1"}/>
                        </button>

                        <span className="next-step">
                            <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                        </span>

                        <button className={`breadcrumb--item completed ${statusCod===2 ? 'default-curs' : ''}`} type="button" onClick={()=> statusCod===2 ? {} : gotoCampaignCreative}>
                            <span className='step-number'>2</span>
                            <FormattedMessage id={"breadcrumb.step2"}/>
                        </button>

                        <span className="next-step">
                            <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                        </span>

                        <button className="breadcrumb--item active default-curs" type="button">
                            <span className='step-number'>3</span>
                            <FormattedMessage id={"breadcrumb.step3"}/>
                        </button>

                        <span className="next-step">
                            <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                        </span>

                        <button className="breadcrumb--item disabled default-curs" type="button">
                            <span className='step-number'>4</span>
                            <FormattedMessage id={"breadcrumb.step4"}/>
                        </button>
                    </div>)
                    :
                    (<div className="breadcrumb margin-top--05">

                        <button className={`breadcrumb--item completed`} type="button" onClick={() => {setConfirmBackDialog(true)}}>
                            <span className='step-number'>1</span>
                            <FormattedMessage id={"breadcrumb.stepLocation"}/>
                        </button>

                        <span className="next-step">
                            <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                        </span>

                        <button className={`breadcrumb--item completed ${statusCod===2 ? 'default-curs' : ''}`} type="button" onClick={ () => statusCod===2 ? {} : gotoYourCampaign()}>
                            <span className='step-number'>2</span>
                            <FormattedMessage id={"breadcrumb.step1"}/>
                        </button>

                        <span className="next-step">
                            <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                        </span>

                        <button className={`breadcrumb--item completed ${statusCod===2 ? 'default-curs' : ''}`} type="button" onClick={() => statusCod===2 ? {} : gotoCampaignCreative}>
                            <span className='step-number'>3</span>
                            <FormattedMessage id={"breadcrumb.step2"}/>
                        </button>

                        <span className="next-step">
                            <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                        </span>

                        <button className="breadcrumb--item active default-curs" type="button">
                            <span className='step-number'>4</span>
                            <FormattedMessage id={"breadcrumb.step3"}/>
                        </button>

                        <span className="next-step">
                            <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                        </span>

                        <button className="breadcrumb--item disabled default-curs" type="button">
                            <span className='step-number'>5</span>
                            <FormattedMessage id={"breadcrumb.step4"}/>
                        </button>
                    </div>)}
                    </div>
                </header>

                <section className="section-layout">
                <div className="grid wrapper">
                    <h4 className="h4 margin">
                        <FormattedMessage id={"campaign.summary.title"}/>
                    </h4>
                    <div className="grid">
                        <div className="col-desk-16 col-mob-4">
                            <article className="card">
                                <div className="card__container">
                                    <div className="card__container--header">
                                        <h4 className='h4'><FormattedMessage id={"breadcrumb.step3"}/></h4>
                                    </div>
                                    <div className="card__container--content">
                                        <div className="layout-flex">
                                        {country==='1'
                                            ?
                                            <Store32 aria-label="Add" className="icon"/>
                                            :
                                            <Train32 aria-label="Add" className="icon" />}
                                            <h3 className="h3 margin-left--02">{locationName}</h3>
                                        </div>
                                        <div className="grid margin-top--06">  

                                            <div className="col-desk-12 col-mob-4">
                                                <div className="info-list margin-bottom--05">
                                                    <Calendar32 aria-label="Add" className="icon" />
                                                    <div>
                                                        <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.date.begin"}/></p>
                                                        <p className="margin-bottom--00">{beginDate.format('DD/MM/YYYY').toString()}</p>
                                                        <p className="margin-bottom--00">{endDate.format('DD/MM/YYYY').toString()}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-desk-12 col-mob-4">
                                                <div className="info-list margin-bottom--05">
                                                    <VideoChat32 aria-label="Add" className="icon" />
                                                    <div>
                                                        <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.details.creativity"}/></p>
                                                        {(gloohRendered || customCreativeId) ? 
                                                            <img className="slot-thumb pointer-curs" onClick={openPreview} src={`${config.CONTENT_DISTRIBUTION_URL_ENV}/thumbs/${gloohCreativeId ? gloohCreativeId : customCreativeId}.png`} alt='creativity'/>
                                                            :
                                                            <p className="margin-bottom--00"><FormattedMessage id={"campaign.summary.details.creativityStatus"}/></p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-desk-12 col-mob-4">
                                                <div className="info-list margin-bottom--05">
                                                    <Screen32 aria-label="Add" className="icon" />
                                                    <div>
                                                        <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.screens"}/></p>
                                                        <p className="margin-bottom--00">{framesSelected}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-desk-12 col-mob-4">
                                                <div className="info-list margin-bottom--05">
                                                    <CalendarHeatMap32 aria-label="Add" className="icon" />
                                                    <div>
                                                        <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.days"}/></p>
                                                        <p className="margin-bottom--00">{days}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-desk-12 col-mob-4">
                                                <div className="info-list margin-bottom--05">
                                                    <TimePlot32 aria-label="Add" className="icon" />
                                                    <div>
                                                        <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.frequence"}/></p>
                                                        <p className="margin-bottom--00">{country==='1'?<FormattedMessage id={"campaign.summary.distribution.frequence/time.fr"}/>:<FormattedMessage id={"campaign.summary.distribution.frequence/time.be"}/>}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-desk-12 col-mob-4">
                                                <div className="info-list margin-bottom--05">
                                                    <PlayOutline32 aria-label="Add" className="icon" />
                                                    <div>
                                                        <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.spot"}/></p>
                                                        <p className="margin-bottom--00">{CommonUtilities.formatPositiveIntNum(numberOfSpot)}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-desk-12 col-mob-4">
                                                <div className="info-list margin-bottom--05">
                                                    <FitToWidth32 aria-label="Add" className="icon" />
                                                    <div>
                                                        <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.details.perimeter"}/></p>
                                                        <p className="margin-bottom--00">
                                                            {country==='1' && <FormattedMessage id={`your-campaigns.${framesLevels.dLevel}`}/>}
                                                            {(country==='2' || country==='3') && framesLevels.kLevel!==0 && panelsList.length===0 && (<>&nbsp;&nbsp;•&nbsp;&nbsp;<FormattedMessage id={`your-campaigns.${framesLevels.groupLabel}`}/></>)}
                                                            {country==='1' && (giant
                                                            ?<><br />
                                                            <FormattedMessage id={"campaign.summary.details.perimeter.giants.yes"}/></>
                                                            :<><br />
                                                            <FormattedMessage id={"campaign.summary.details.perimeter.giants.no"}/></>
                                                            )}
                                                        </p> 
                                                        {(country==='2' || country==='3')&& createPanelsSelectionInfo()}
                                                        <p className="margin-bottom--00">
                                                            {daypartLabel()}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-desk-12 col-mob-4">
                                                {showAudience ? <>
                                                <div className="info-list margin-bottom--05">
                                                    <Events32 aria-label="Add" className="icon" />
                                                    <div>
                                                        <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.contacts"}/></p>
                                                        <p className="margin-bottom--00">{CommonUtilities.formatPositiveIntNum(estimatedAvgContacts)}</p>
                                                    </div>
                                                </div>
                                                </> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="col-desk-8 col-mob-4">

                            <article className="card">
                                <div className="card__container">
                                    <div className="card__container--header">
                                        <h4 className='h4'><FormattedMessage id={'your-campaigns.resume.title'}/></h4>
                                    </div>
                                    <div className="card__container--content">

                                        {country==='1'&&(<>
                                            <div className="layout-spacebetween margin-bottom--04">
                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.campaignCost"}/></p>
                                            {CommonUtilities.formatNumber(priceMedia, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}
                                        </div>

                                        <div className="layout-spacebetween margin-bottom--04">
                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.advertiseCost"}/></p>
                                            {CommonUtilities.formatNumber(priceAdv, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}
                                        </div>

                                        <div className="layout-spacebetween margin-bottom--04">
                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.creationCost"}/></p>
                                            <FormattedMessage id={"campaign.summary.distribution.creationCost.free"}/>
                                        </div></>)}

                                        <div className="layout-spacebetween margin-bottom--04">
                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.priceNet"}/></p>
                                            {/* {CommonUtilities.formatNumber(!promoCodeDiscount ? price : originalPrice, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})} */}
                                            {!promoCodeDiscount
                                            ?
                                            CommonUtilities.formatNumber(price, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})
                                            :
                                            CommonUtilities.formatNumber(originalPrice ? originalPrice : price, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}
                                        </div>

                                        {!promoCodeDiscount
                                            ?
                                            ''
                                            :
                                            <div className="layout-spacebetween margin-bottom--04">
                                                <p className="paragraph margin-bottom--00 green-txt"><FormattedMessage id={"campaign.summary.promoCode.promotion"}/></p>
                                                <span className='green-txt'>- {CommonUtilities.formatNumber(promoCodeDiscount, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}</span>
                                            </div>
                                        }

                                        <div className="layout-spacebetween margin-bottom--04">
                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.tax"}/></p>
                                        {CommonUtilities.formatNumber(priceTva, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}
                                        </div>   

                                        <span className="separator layout-spacebetween margin-bottom--04"></span>

                                        <div className="layout-spacebetween">
                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.totalPrice"}/></p>
                                            <span className="highlight">{CommonUtilities.formatNumber(priceTtc, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}</span>
                                        </div>   

                                    </div>
                                </div>
                            </article>

                            <div className="input-container">
                            {!promoCodeDiscount
                                    ? 
                                    <>
                                    <label className="label" htmlFor="promoCode"><FormattedMessage id='campaign.summary.promoCode.title'/></label>
                                    <div className="justified-content row">
                                        <input className="maxed-width input" data-cy="promoCode" data-testid="promoCode" type="text" name="promoCode" required="" maxLength={50} disabled="" value={promoCode} onChange={ (event) => {handleChangePromoCode(event)}}/>
                                        <button className="promo-button button--primary-small margin-bottom--06" type="button" onClick={handleSetPromoCode} ><FormattedMessage id="campaign.summary.promoCode.button"/></button>
                                    </div>
                                    {showPromoCodeError && (<span className="message--error"><FormattedMessage id='campaign.summary.promoCode.error.first'/>&nbsp;{prevPromoCode!=='' ? `${prevPromoCode} ` : '' }<FormattedMessage id='campaign.summary.promoCode.error.second'/></span>)}
                                    {alreadyUsedPromoCode && (<span className="message--error"><FormattedMessage id='campaign.summary.promoCode.error.used'/></span>)}
                                    </>
                                    :
                                    <div className="row justified-content">
                                        <div className="row vertical">
                                            <div>
                                                <FormattedMessage id={"campaign.summary.promoCode.saved.first"}/> {CommonUtilities.formatNumber(promoCodeDiscount, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}<FormattedMessage id={"campaign.summary.promoCode.saved.second"}/>
                                            </div>
                                            <div className='green-txt'>
                                                <FormattedMessage id={"campaign.summary.promoCode.saved.applied"}/>
                                            </div>
                                        </div>
                                        <IconButton onClick={() => handleSetPromoCode(true)} ><CloseIcon fontSize='large'/></IconButton>
                                    </div>
                                }
                            </div>

                            <label className="container-checkbox">
                            <a href={country==='1' ? "/docsFR/LaunchPAD Direct-CGV_fr.pdf" : `/docsBE/LaunchPAD Direct-CGV_${language}.pdf`} target="_blank" rel="noreferrer">
                                <span className='link-to'>
                                    <FormattedMessage id={"campaign.summary.disclaimer"}/>
                                </span>
                            </a>
                                <input data-cy="isLimited" data-testid="isLimited" type="checkbox" name="isLimited" onClick={handleClickGenCond} value={genCond} disabled={warning}/>
                                <span className="checkmark--checkbox"></span>
                            </label>
                        </div>
                    </div>
                    {warning && (
                        <div className="msg-already-book"><FormattedMessage id="notificationManager.configurationError.text"/></div>
                    )}
                    <div className="button-line--wrapper">
                        <button className="button--secondary-large" type="button" onClick={gotoMyCampaigns}><FormattedMessage id="campaigns.go-back"/></button>
                        <button className="button--primary-large" type="button" onClick={handleTogglePayModal} disabled={!genCond}><FormattedMessage id="campaigns.next-step"/></button>
                    </div>
                    </div>
                </section>
                
                <footer className="section-layout text-center">
                    <p className="paragraph"><FormattedMessage id="assistance.message"/><a href={`mailto:${translate(`assistance.email.${country}`)}`}><FormattedMessage id={`assistance.email.${country}`}/></a></p>
                    <LegalLinks country={country}/>
                </footer>

                <Dialog onClose={handleConfirmCloseBackDialog} open={confirmBackDialog}>
                <DialogTitle>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <span className='item tag' style={{backgroundColor:'#FFEAAB', color:'#EEB302', borderRadius:'50%', width:'4rem', height:'4rem'}}>
                            <WarningAmberIcon/>
                        </span>&nbsp;
                        <h4 className="h4" style={{fontWeight: '400'}}>
                            <FormattedMessage id="modal.confirm.back-summary.text"/>
                        </h4>
                    </div>
                </DialogTitle>
                <DialogActions className='pointto box-pad-minus'>
                    <button className="button--secondary-large" style={{padding:'0.6rem 1.4rem'}} onClick={gotoLocationSelection}>
                        <FormattedMessage id="modal.confirm.back.confirm"/>
                    </button>
                    <button className="button--quaternary-large" style={{padding:'0.6rem 1.4rem', color:'black'}} onClick={handleConfirmCloseBackDialog}>
                        <FormattedMessage id="modal.confirm.back.cancel"/>
                    </button>
                </DialogActions>
                </Dialog>

            <Dialog onClose={handleClosePreviewDialog} open={openPreviewDialog}>
                <DialogTitle className='.MuiDialog-container .MuiTypography-root antiTheme'>
                    <div>
                        <video width="360" height="640" controls autoplay="true">
                            <source src={videoUrl} type="video/mp4"/>
                        </video>
                    </div>
                </DialogTitle>
                <DialogActions className='pointto box-pad-minus'>
                    <button className="button--quaternary-large" style={{padding:'0.6rem 1.4rem', color:'black'}} onClick={handleClosePreviewDialog}>
                        <FormattedMessage id="modal.confirm.back.cancel"/>
                    </button>
                </DialogActions>
            </Dialog>

            </ThemeProvider>
            <form action={`${config.API_URL}/create-checkout-session?kCampaign=${kCampaign}&paymentType=C`} method="POST" name='checkout'></form>
            <form action={`${config.API_URL}/create-checkout-session?kCampaign=${kCampaign}&paymentType=B`} method="POST" name='checkoutBancontact'></form>
        </div>
        );
    }
}

export default injectIntl(CampaignSummaryPay);